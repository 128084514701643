import { BlankPage } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import features from "features";
import { IRootState } from "reducer";
import { IPreviousUsageState } from "scenes/subject/documents/PreviousUsage/ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import SpecifyPreviousUsageLayout from "components/layouts/SpecifyPreviousUsageLayout/SpecifyPreviousUsageLayout";
import SpecifyPreviousZNUsageLayout from "components/layouts/SpecifyPreviousZNUsageLayout/SpecifyPreviousZNUsageLayout";

import styles from "./previous-usage-page.module.scss";

const PreviousUsagePage = () => {
  const dispatch = useDispatch();

  const { applicationId, docId, type } = useParams();
  const {
    document,
    fetchingDocumentLoading,
    previousUsedDocuments,
    fetchingPreviousDocumentsLoading
  } = useSelector<IRootState, IPreviousUsageState>(
    (state) => state.previousUsage
  );

  const [currentUsedDocument, setCurrentUsedDocument] = useState(null);

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.previousUsage.actions.fetchDocumentRequest({
          params: { uuid: docId },
          fields: { applicationUuid: applicationId }
        })
      );
      if (docId) {
        if (type === "zn") {
          if (!document) {
            return;
          }
          dispatch(
            features.previousUsage.actions.fetchPreviousDocumentsRequest({
              params: {
                basisDocumentIds: document?.children.map((el) =>
                  Number(el.externalId)
                )
              }
            })
          );
        } else {
          dispatch(
            features.previousUsage.actions.fetchPreviousDocumentsRequest({
              params: {
                basisDocumentIds: [Number(docId)]
              }
            })
          );
        }
      }
    }

    return () => {
      dispatch(features.application.actions.clearApplication());
    };
  }, [document !== null]);

  useEffect(() => {
    if (!isEmpty(previousUsedDocuments)) {
      setCurrentUsedDocument(previousUsedDocuments[0]);
    }
  }, [applicationId, previousUsedDocuments]);

  return (
    <PreloaderWrapper
      loading={fetchingDocumentLoading || fetchingPreviousDocumentsLoading}
      withModal
    >
      {!isEmpty(previousUsedDocuments) && !isEmpty(currentUsedDocument) ? (
        type === "zn" ? (
          <SpecifyPreviousZNUsageLayout
            docType={type}
            data={document}
            docId={docId}
            previousDocuments={previousUsedDocuments}
            onDocumentChange={(el) => setCurrentUsedDocument(el)}
            documentIndex={previousUsedDocuments.indexOf(currentUsedDocument)}
            currentUsedDocument={currentUsedDocument}
          />
        ) : (
          <SpecifyPreviousUsageLayout
            docType={type}
            data={document}
            previousDocuments={previousUsedDocuments}
            onDocumentChange={(el) => setCurrentUsedDocument(el)}
            documentIndex={previousUsedDocuments.indexOf(currentUsedDocument)}
            currentUsedDocument={currentUsedDocument}
          />
        )
      ) : fetchingDocumentLoading || fetchingPreviousDocumentsLoading ? (
        <></>
      ) : (
        <div className={styles["blank-page__container"]}>
          <h1 className={styles["specify-usage__title"]}>
            Попереднє використання
          </h1>
          <BlankPage
            title="По вказаному документу підстави не знайдено жодного попереднього використання"
            className={styles["blank-page__content"]}
          />
        </div>
      )}
    </PreloaderWrapper>
  );
};

export default PreviousUsagePage;
