import { createColumnHelper } from "@tanstack/react-table";
import {
  BlackEyeIcon,
  BlankPage,
  CommonButton,
  PencilIcon,
  Status
} from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { docTypes } from "constant";
import { applicationOfficialInQueueStatuses } from "dataset/applicationStatuses";
import features from "features";
import { getDocumentViewType } from "helpers";
import { formatDate } from "helpers/date";

import EDocButton from "components/buttons/EDocButton/EDocButton";
import CommonTable from "components/tables/CommonTable/CommonTable";

import styles from "./applications-search-result.module.scss";

interface IQueueCertificatesResults {
  applications: any;
  onPageClick: (event: any) => void;
  isReadOnly: boolean;
  currentProcessingUuid?: string;
}

interface TableData {
  num: number;
  interregionalOfficeName: string;
  officialFullname: string;
  checkedPercent: number;
  remarksNumber: number;
  applicationNumber;
  productYieldPercentage;
  invoiceNumber: string;
  applicationSubmissionDate: string;
  costStandards: string;
  buyer: string;
  exporterOrganizationName: string;
  progress: number;
  percentage: string;
  rate_of_expenditure: string;
  edit: string;
  delete: string;
  actions: string;
  isProcessing: string;
  customsClearance?: boolean;
  applicationFile: any;
  certificateFile: any;
}

const QueueCertificatesResults = ({
  applications,
  currentProcessingUuid,
  isReadOnly
}: IQueueCertificatesResults) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<TableData>();

  const handleRowClick = useCallback(
    (uuid) => {
      const isProcessing = applications.find(
        (item) =>
          (item?.uuid === uuid && !currentProcessingUuid) ||
          uuid === currentProcessingUuid
      )?.isProcessing;

      isProcessing && !isReadOnly
        ? navigate(`/official/applications/${uuid}`)
        : navigate(`/official/applications/in-queue/${uuid}`);
    },
    [applications, isReadOnly, navigate, currentProcessingUuid]
  );

  const onDocumentView = useCallback(
    (e, file: { name: string; filePath: any; uuid: string }) => {
      e.stopPropagation();

      dispatch(
        features.modal.actions.showModal({
          modalType: getDocumentViewType(file.name),
          uuid: file.uuid,
          modalProps: { url: file.filePath, withDownload: true }
        })
      );
    },
    [dispatch]
  );

  const columns = [
    columnHelper.accessor("num", {
      cell: (info: any) => (
        <p className={styles["drafts-page__table-number"]}>{info.getValue()}</p>
      ),
      header: () => "№"
    }),
    columnHelper.accessor("interregionalOfficeName", {
      cell: (info: any) => info.getValue(),
      header: () => "МРУ"
    }),
    columnHelper.accessor("officialFullname", {
      cell: (info: any) => info.getValue(),
      header: () => "Ім'я чиновника"
    }),
    columnHelper.accessor("isProcessing", {
      cell: (info: any) => (
        <Status
          className="nowrap"
          {...applicationOfficialInQueueStatuses[info.getValue()]}
        />
      ),
      header: () => "Статус"
    }),
    columnHelper.accessor("checkedPercent", {
      cell: (info: any) => info.getValue(),
      header: () => "Відсоток розгляду"
    }),
    columnHelper.accessor("remarksNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Кількість зауважень"
    }),
    columnHelper.accessor("applicationNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер заявки"
    }),
    columnHelper.accessor("applicationSubmissionDate", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата заявки"
    }),
    columnHelper.accessor("applicationFile", {
      cell: (info: any) => {
        const index = info.row.index;
        const data = applications[index];

        const formatDocFile = (alias) => {
          if (data) {
            const fileData = data[alias];
            if (fileData)
              return {
                title: fileData.title,
                contentTitle: fileData.name,
                contentSubtitle: fileData.serialNumber,
                contentText: formatDate(fileData.createdAt).date,
                onClick: (e) => onDocumentView(e, fileData)
              };
          }
        };
        formatDocFile("eApplication");
        return (
          <div className={styles["actions-container"]}>
            {data?.eApplication && (
              <EDocButton
                {...formatDocFile("eApplication")}
                type={docTypes.APPLICATION}
              />
            )}
          </div>
        );
      },
      header: () => "Файл заявки"
    }),
    columnHelper.accessor("exporterOrganizationName", {
      cell: (info: any) => info.getValue(),
      header: () => "Експортер"
    }),
    columnHelper.accessor("buyer", {
      cell: (info: any) => info.getValue(),
      header: () => "Покупець"
    }),
    columnHelper.accessor("invoiceNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер інвойсу"
    }),
    columnHelper.accessor("productYieldPercentage", {
      cell: (info: any) => info.getValue(),
      header: () => "Відсоток виходу"
    }),
    columnHelper.accessor("costStandards", {
      cell: (info: any) => info.getValue(),
      header: () => "Нормативи витрат"
    }),
    columnHelper.accessor("actions", {
      cell: (info: any) => {
        return (
          <div className={styles["actions-container"]}>
            <CommonButton
              image={
                info.row.original.isProcessing && !isReadOnly ? (
                  <PencilIcon />
                ) : (
                  <BlackEyeIcon />
                )
              }
              round={true}
              outlined={true}
              onClick={() => handleRowClick(info.row.original.uuid)}
            />
          </div>
        );
      },
      header: () => "Дії"
    })
  ];

  const applicationsOptions = useMemo(() => {
    if (isEmpty(applications)) return [];

    const startIndexOfRow = 1;

    return applications.map((item, i) => ({
      ...item,
      num: startIndexOfRow + i,
      applicationSubmissionDate: `${
        formatDate(item.applicationSubmissionDate).date
      }\n${formatDate(item.applicationSubmissionDate).time}`
    }));
  }, [applications]);

  return (
    <div className={styles["results-container"]}>
      {!isEmpty(applications) ? (
        <>
          <CommonTable
            columns={columns}
            defaultData={applicationsOptions}
            tableType="ROW-GAP"
            onRowClick={handleRowClick}
            smallText
          />
        </>
      ) : (
        <BlankPage title="На ваш запит нічого не знайдено" />
      )}
    </div>
  );
};

export default QueueCertificatesResults;
