import * as Yup from "yup";

import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import {
  CommonButton,
  FormField,
  Title,
  VisualUploadMultipleFiles
} from "gov-ua-ui";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import features from "features";

import { parseFilesErrors, parseResErrors } from "helpers";
import { IRootState } from "reducer";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { ICertificateAnnulRequestState } from "../ducks";

import Certificate from "components/Certificate/Certificate";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import AddTrustedDoc from "components/certificateComponents/createCertificateComponents/AddTrustedDoc/AddTrustedDoc";
import EDocsSection from "components/certificateComponents/viewCertificateComponents/EDocsSection/EDocsSection";
import SectionDelimiter from "components/certificateComponents/viewCertificateComponents/SectionDelimiter/SectionDelimiter";
import CertificatesLayout from "components/layouts/CertificatesLayout/CertificatesLayout";

import EDocIcon from "assets/images/icons/e_doc.svg";
import EDocApplicationIcon from "assets/images/icons/e_doc_application.svg";

import styles from "./certificate-annul-request-page.module.scss";

const CertificateAnnulRequestPage = () => {
  const [isActiveEdit, setIsActiveEdit] = useState(false);

  const dispatch = useDispatch();
  const { applicationId } = useParams();

  const { fetchingApplicationLoading, application } = useSelector<
    IRootState,
    ICertificateAnnulRequestState
  >((state) => state.certificateAnnulRequest);

  const { currentSubject } = useSelector<IRootState, ISubjectsState>(
    (state) => state.subjects
  );

  const navigate = useNavigate();

  const navigationLinks = [
    {
      text: "Загальна інформація",
      to: "#primary-info",
      anchor: true
    },
    {
      text: "Підстава анулювання",
      to: "#grounds-for-annull",
      anchor: true
    },
    {
      text: "",
      to: "#e-order",
      anchor: true,
      customContent: (
        <>
          єСертифікат
          <img
            src={EDocIcon}
            alt="єСертифікат"
            className={styles["certificates-nav-btn__img"]}
          />
        </>
      ),
      customStyles: styles["certificates-nav-btn"]
    },
    {
      text: "",
      to: "#e-order",
      anchor: true,
      customContent: (
        <>
          еЗаява
          <img
            src={EDocApplicationIcon}
            alt="еЗаява"
            className={styles["certificates-nav-btn__img"]}
          />
        </>
      ),
      customStyles: styles["certificates-nav-btn"]
    }
  ];

  const onFormSubmit = (values: any, { setFieldError }) => {
    const fields = {
      annulmentBasisDescription: values.reason.toString()
    };
    // dispatch(
    //   features.modal.actions.showModal({
    //     modalType: "SIGNATURE",
    //     modalProps: {
    //       modalTitle: `Підписати та відправити заяву`,
    //       processedName: "Заявник",
    //       processedBy: currentSubject?.fullName,
    //       location: currentSubject?.organizationName,
    //       onAccept: (signedFile) => {
    //         dispatch(features.modal.actions.hideModal());
    //         dispatch(
    //           features.modal.actions.showModal({
    //             modalType: "PRELOADER",
    //             modalProps: {
    //               title: "Опрацювання запиту",
    //               loading: true
    //             }
    //           })
    //         );
    //         dispatch(
    //           features.certificateAnnulRequest.actions.submitEAnnulmentDataRequest(
    //             {
    //               params: {
    //                 applicationUuid: applicationId
    //               },
    //               fields: {
    //                 ...fields,
    //                 signedFile
    //               },
    //               onSuccess: () => {
    //                 dispatch(features.modal.actions.hideModal());
    //                 toastr.success(
    //                   "Успіх",
    //                   "Заявка на ануляцію успішно подана, чекайте на повідомлення."
    //                 );
    //                 navigate("/personal/applications/received");
    //               },
    //               onError: (errorsRes) => {
    //                 parseResErrors({
    //                   setFieldError,
    //                   errorsRes,
    //                   fields: values,
    //                   fieldsAliases: { annulmentBasisDescription: "reason" }
    //                 });
    //                 dispatch(features.modal.actions.hideModal());
    //               }
    //             }
    //           )
    //         );
    //       }
    //     }
    //   })
    // );
    //Show pdf before signing
    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title: "Створюється заява на анулювання сертифікату",
          loading: true
        }
      })
    );
    dispatch(
      features.certificateAnnulRequest.actions.fetchEAnnulmentDataRequest({
        params: { uuid: applicationId },
        fields,
        onSuccess: () => {
          dispatch(
            features.modal.actions.hideModal({
              modalType: "PRELOADER"
            })
          );
          navigate("e-annulment-confirmation");
        },
        onError: (e) => {
          dispatch(
            features.modal.actions.hideModal({
              modalType: "PRELOADER"
            })
          );
          parseResErrors({
            setFieldError,
            errorsRes: e,
            fields: values,
            fieldsAliases: { annulmentBasisDescription: "reason" }
          });
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      reason: ""
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required("Поле обов’язкове")
    }),
    enableReinitialize: true,
    onSubmit: onFormSubmit
  });

  const onDocumentView = useCallback(
    (
      file: { name: string; filePath: any; uuid: string }
      // withDownload?: boolean
    ) => {
      window.open(file.filePath, "_blank");
    
      // dispatch(
      //   features.modal.actions.showModal({
      //     modalType: getDocumentViewType(file.name),
      //     modalProps: { url: file.filePath, uuid: file.uuid, withDownload }
      //   })
      // );
    },
    [dispatch]
  );

  const alreadyUsedInApplicationFileNames = useCallback(() => {
    if (application?.files) {
      const usedFilesNames: any = Object.entries(application?.files)
        .flat()
        .filter((el) => Array.isArray(el) && el.length > 0)
        .flat()
        .map((el: any) => el?.name);

      return usedFilesNames;
    }
  }, [application]);

  const alreadyUsedDocsNames = alreadyUsedInApplicationFileNames();

  const onLoadFiles = (acceptedFiles, rejectedFiles, replaceFile) => {
    if (acceptedFiles) {
      acceptedFiles.forEach((acceptedFile) => {
        if (alreadyUsedDocsNames.includes(acceptedFile.name)) {
          return toastr.error(
            "Помилка " + acceptedFile.name,
            `Увага! Файл з назвою ${acceptedFile.name} вже наявний у заяві на отримання сертифікату, змініть будь ласка назву файлу, що додається.`
          );
        }
        dispatch(
          features.certificateAnnulRequest.actions.loadFileRequest({
            params: {
              uuid: applicationId,
              replaceUuid: replaceFile?.uuid
            },
            fields: {
              fileType: "annulment_application_documents_copy",
              file: Object.assign(acceptedFile, {
                alias: "annulmentApplicationDocumentsCopy"
              })
            }
          })
        );
      });
    }

    parseFilesErrors(rejectedFiles);
  };

  const onDeleteFile = async (file) => {
    await new Promise((resolve) => {
      dispatch(
        features.certificateAnnulRequest.actions.deleteFileRequest({
          params: {
            uuid: file.uuid,
            applicationUuid: applicationId
          },
          type: file.type,
          name: file.name,
          onSuccess: () => resolve(() => true),
          onError: () => resolve(() => true)
        })
      );

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  const getEDocs = useMemo(() => {
    const docs = [];
    if (application?.files?.eApplication.length)
      docs.push({
        docImg: EDocIcon,
        docTitle: "еЗаява",
        onClick: () => onDocumentView(application?.files?.eApplication[0])
      });
    if (application?.files?.eCertificate.length)
      docs.push({
        docImg: EDocApplicationIcon,
        docTitle: "єСертифікат",
        onClick: () => onDocumentView(application?.files?.eCertificate[0])
      });
    return docs;
  }, [application, onDocumentView]);

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.certificateAnnulRequest.actions.fetchApplicationRequest({
          params: { uuid: applicationId }
        })
      );
    }

    dispatch(
      features.modal.actions.hideModal({
        modalType: "PRELOADER"
      })
    );

    return () => {
      dispatch(features.certificateAnnulRequest?.actions.clearApplication());
    };
  }, [applicationId, dispatch]);

  return (
    <CertificatesLayout
      navLinks={navigationLinks}
      // title={`Сертифікат на анулювання ${application?.certificateNumber}`}
      title={`Заявка на анулювання сертифікату про походження лісоматеріалів та виготовлених з них пиломатеріалів в електронній формі`}
    >
      <PreloaderWrapper loading={fetchingApplicationLoading}>
        {!isEmpty(application) && (
          <>
            <Certificate data={application} id="primary-info" />
            <SectionDelimiter />

            <div id="e-order">
              <EDocsSection docs={getEDocs} />
            </div>

            {currentSubject?.organization?.role !== "DIRECTOR" && (
              <>
                <SectionDelimiter />
                <AddTrustedDoc
                  applicationUuid={applicationId}
                  isAnnul
                  onEdit={setIsActiveEdit}
                  alreadyUsedDocsNames={alreadyUsedDocsNames}
                />
              </>
            )}
            <SectionDelimiter />

            <div
              className={styles["grounds-for-cancellation"]}
              id="grounds-for-annull"
            >
              <Title
                size={24}
                className={styles["grounds-for-cancellation__title"]}
              >
                Підстава анулювання
              </Title>
              <FormikProvider value={formik}>
                <Form className={cn(styles["personal-cabinet-page"])}>
                  <FormField
                    name="reason"
                    as="textarea"
                    className={cn(
                      styles["search-container__input"],
                      "textarea-field"
                    )}
                    placeholder="Опишіть причину анулювання"
                  />
                  <h4
                    className={styles["grounds-for-cancellation__upload-title"]}
                  >
                    Копія документів (необов’язково)
                  </h4>
                  <VisualUploadMultipleFiles
                    title={"Завантажити скан-копію документів"}
                    accept={{
                      "application/pdf": [".pdf"],
                      "image/jpeg": [".jpg"]
                    }}
                    containerClassName={
                      styles["grounds-for-cancellation__upload-container"]
                    }
                    contentClassName={
                      styles["grounds-for-cancellation__upload-content"]
                    }
                    loadedFiles={
                      application?.files?.annulmentApplicationDocumentsCopy
                    }
                    onDelete={onDeleteFile}
                    onDocumentView={onDocumentView}
                    onLoad={onLoadFiles}
                  />

                  <CommonButton
                    label="Сформувати заяву"
                    type="submit"
                    disabled={
                      isActiveEdit ||
                      (currentSubject?.organization?.role !== "DIRECTOR" &&
                        !application.trustedDocData)
                    }
                  />
                </Form>
                <ScrollToFieldError />
              </FormikProvider>
            </div>
          </>
        )}
      </PreloaderWrapper>
    </CertificatesLayout>
  );
};

export default CertificateAnnulRequestPage;
