import cn from "classnames";
import { createColumnHelper } from "@tanstack/react-table";
import { Alert, CommonButton, SimpleTooltip } from "gov-ua-ui";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import features from "features";
import { formatDate } from "helpers";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import {
  IBasisDoc,
  IWoodCard
} from "scenes/subject/applications/ApplicationPage/interfaces";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import Section from "components/Section/Section";
import BasicDocsTransfer from "components/certificateComponents/viewCertificateComponents/BasicDocsTransfer/BasicDocsTransfer";
import OfficialControls from "components/certificateComponents/viewCertificateComponents/OfficialControls/OfficialControls";

import DeleteIcon from "assets/images/icons/delete.svg";
import EyeIcon from "assets/images/icons/eye.svg";
import LinkIcon from "assets/images/icons/link.svg";
import DownloadIcon from "assets/images/icons/download-white.svg";

import styles from "./basis-issuing-certificate-wood-cards.module.scss";

interface ITable extends IBasisDoc {
  amount: number;
  docs: string;
  delete?: string;
  remark?: string;
  view?: string;
}

interface BasisIssuingCertificateInterface {
  applicationUuid?: string;
  documents?: Array<IBasisDoc>;
  woodCards?: Array<IWoodCard>;
  isOfficial?: boolean;
  isReadonly?: boolean;
}

const BasisIssuingCertificate = ({
  applicationUuid,
  documents,
  woodCards,
  isOfficial,
  isReadonly,
}: BasisIssuingCertificateInterface): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { applicationId } = useParams();

  const { application, fetchingApplicationLoading } = useSelector<
    IRootState,
    IApplicationState
  >((state) => state.application);

  const [isPdDetailsModalOpen, setIsPdDetailsModalOpen] =
    useState<boolean>(false);
  const [popStateHandler, setPopStateHandler] = useState(null);

  const handleButtonClick = () => {
    navigate("documents/primary/search");
  };

  const woodCardColumnsHelper = createColumnHelper<IWoodCard>();
  const basicDocsColumnsHelper = createColumnHelper<ITable>();

  const onModalClose = () => {
    setIsPdDetailsModalOpen(false);
    window.removeEventListener("popstate", popStateHandler);
    window.history.back();
  };

  const onViewClick = useCallback(
    (info) => {
      const docUuid = info.row.original.uuid;
      const docId = info.row.original.externalId;
      const isPrimal = info.row.original.primal;
      dispatch(
        features.modal.actions.showModal({
          modalType: "BASIS_DOC_VIEW",
          modalProps: {
            docType: "transfer",
            docUuid,
            docId,
            isPrimal,
            onModalClose
          }
        })
      );
      setIsPdDetailsModalOpen(true);
    },
    [dispatch]
  );

  useEffect(() => {
    const handlePopState = () => {
      if (isPdDetailsModalOpen) {
        dispatch(features.modal.actions.hideModal());
        setIsPdDetailsModalOpen(false);
        window.history.replaceState(null, null, window.location.href);
      }
    };

    if (isPdDetailsModalOpen) {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", handlePopState);
      setPopStateHandler(() => handlePopState);
    } else {
      window.removeEventListener("popstate", handlePopState);
    }

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isPdDetailsModalOpen, dispatch, setIsPdDetailsModalOpen]);

  const woodCardsColumns = [
    woodCardColumnsHelper.accessor("series", {
      cell: (info: any) => info.getValue(),
      header: () => "Серія"
    }),
    woodCardColumnsHelper.accessor("number", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер"
    }),
    woodCardColumnsHelper.accessor("issueDate", {
      cell: (info: any) => formatDate(info.getValue()).date,
      header: () => "Дата видачі"
    }),
    woodCardColumnsHelper.accessor("regionName", {
      cell: (info: any) => info.getValue(),
      header: () => "Регіон"
    }),
    woodCardColumnsHelper.accessor("forestHouseholdName", {
      cell: (info: any) => info.getValue(),
      header: () => "Лісокористувач",
      meta: {
        isOverflowEllipses: true
      }
    }),
    woodCardColumnsHelper.accessor("takenQuantity", {
      cell: (info: any) => info.getValue(),
      header: () => "Кількість"
    }),
    ...(!isReadonly && isOfficial
      ? [
        woodCardColumnsHelper.accessor("remark", {
          cell: (info: any) => (
            <>
              <OfficialControls
                remark={info.getValue()}
                blockType={"application_basis_document"}
                isSmall
              />
            </>
          ),

          header: () => "",
          meta: {
            buttonCell: true
          }
        })
      ]
      : []),
    woodCardColumnsHelper.accessor("url", {
      cell: (info: any) => (
        <a
          href={info.getValue()}
          target="_blank"
          rel="noreferrer"
          className={styles["link-icon-button"]}
        >
          <img src={LinkIcon} alt="" />
        </a>
      ),
      header: () => "",
      meta: {
        buttonCell: true
      }
    })
  ];

  const basicDocsColumns = [
    woodCardColumnsHelper.accessor("series", {
      cell: (info: any) => info.getValue(),
      header: () => "Серія"
    }),
    basicDocsColumnsHelper.accessor("number", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер"
    }),
    basicDocsColumnsHelper.accessor("date", {
      cell: (info: any) => formatDate(info.getValue()).date,
      header: () => "Дата"
    }),
    basicDocsColumnsHelper.accessor("sourceOrganizationName", {
      cell: (info: any) => info.getValue(),
      header: () => "Лісокористувач",
      meta: {
        isOverflowEllipses: true
      }
    }),
    basicDocsColumnsHelper.accessor("takenQuantity", {
      cell: (info: any) => info.getValue(),
      header: () => "Кількість"
    }),
    ...(!isReadonly && !isOfficial
      ? [
        basicDocsColumnsHelper.accessor("delete", {
          cell: (info: any) => (
            <CommonButton
              image={DeleteIcon}
              round={true}
              outlined={true}
              className={styles["delete-icon-button"]}
              onClick={() =>
                onDeleteClick({
                  applicationUuid,
                  documentUuid: info.row.original.uuid
                })
              }
            />
          ),
          header: () => "",
          meta: {
            buttonCell: true
          }
        })
      ]
      : []),
    basicDocsColumnsHelper.accessor("view", {
      cell: (info: any) => (
        <CommonButton
          image={EyeIcon}
          round={true}
          outlined={true}
          onClick={() => onViewClick(info)}
        />
      ),
      header: () => "",
      meta: {
        buttonCell: true
      }
    })
  ];

  const onDeleteClick = useCallback(
    ({ applicationUuid, documentUuid }) => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "CONFIRM_ACTION",
          modalProps: {
            notificationText:
              "Ви впевнені, що хочете видалити документ підстави?",
            acceptLabel: "Так, я впевнений",
            onAccept: () => {
              dispatch(
                features.application.actions.deleteBasisDocumentRequest({
                  params: { applicationUuid, documentUuid },
                  onSuccess: () => {
                    dispatch(
                      features.application.actions.fetchApplicationRequest({
                        params: { uuid: applicationUuid }
                      })
                    );
                  }
                })
              );
            }
          }
        })
      );
    },
    [dispatch]
  );

  const isBasisDocuments = useMemo(() => {
    if (documents?.length) {
      return documents.filter((item) => item.primal).length;
    }

    return false;
  }, [documents]);

  const handleDownloadExcelClick = () => {
    dispatch(
      features.application.actions.downloadBasicDocumentsExcelRequest({
        params: {
          uuid: applicationId
        },
        onSuccess: (data) => (window.location.href = data?.url)
      })
    );
  };


  return (
    <PreloaderWrapper loading={fetchingApplicationLoading}>
      <Section
        title={"Підстава видачі сертифіката"}
        color="WHITE"
        className={styles["certificate-issuing"]}
      >
        {isBasisDocuments ? (
          <BasicDocsTransfer
            defaultData={documents}
            woodCardsData={woodCards}
            transferDocColumns={basicDocsColumns}
            woodCardsColumns={woodCardsColumns}
            isOfficial={isOfficial}
            isReadonly={isReadonly}
          />
        ) : (
          <Alert
            className={styles["certificate-issuing__reminder"]}
            type="warning"
            withIcon
          >
            {application?.products.length > 0
              ? "Додайте допоміжний для пошуку ЛК документ"
              : "Перед додаванням документа підстави потрібно заповнити дані про продукцію."}
          </Alert>
        )}
        <div className={cn(styles['buttons-wrapper'], { [styles['buttons-wrapper__without-add-transwer']]: isReadonly || isOfficial })}>
          {!isReadonly && !isOfficial && (
            <CommonButton
              outlined={true}
              className={styles["certificate-issuing__btn"]}
              label="Додати переміщення"
              onClick={handleButtonClick}
              disabled={application?.products.length > 0 ? false : true}
            />
          )}
          {isBasisDocuments &&
            <>
              <div
                data-tooltip-id="subject-tooltip"
                data-tooltip-content={
                  "Завантажити звіт у форматі Excel з інформацією про використану в заявці продукцію за всіма первинними документами"
                }
                className={styles["download-report-button-container"]}
              >
                <CommonButton
                  label="Завантажити звіт"
                  image={DownloadIcon}
                  onClick={handleDownloadExcelClick}
                />
              </div>
              <SimpleTooltip
                id="subject-tooltip"
                float
                place="right"
                className={styles["tooltip-hint"]}
                noArrow
                style={{
                  transform: "translateY(-120px)"
                }}
              />
            </>}
        </div>

      </Section>
    </PreloaderWrapper>
  );
};

export default BasisIssuingCertificate;
