import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, FormField, Select } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import features from "features";
import { converteFileToBase64 } from "helpers";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";

import Preloader from "components/Preloader/Preloader";
import SyncVisualMultipleUploader from "components/SyncVisualMultipleUploader/SyncVisualMultipleUploader";

import styles from "./forms.module.scss";

interface PropType {
  type: string;
}

const OtherQuestionsForm = ({ type }: PropType): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadedFiles, setLoadedFiles] = useState([]);

  const { requestTypeList } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  useEffect(() => {
    dispatch(features.dataset.actions.fetchRequestTypeListRequest());
  }, []);

  const onFormSubmit = (values) => {
    const fields = {
      _type: type,
      type: values.type.value,
      comment: values.comment
    };

    const processFiles = async () => {
      const processFile = async (el) => {
        try {
          let convertedFile = await converteFileToBase64(el);
          return {
            name: el.name,
            content: convertedFile
          };
        } catch (error) {
          toastr.error("Помилка, формат файлу не підтримується");
          return {
            name: el.name,
            content: null
          };
        }
      };

      const files = await Promise.all(loadedFiles.map(processFile));
      return files;
    };

    processFiles().then((res) =>
      dispatch(
        features.requestToSystemList.actions.fetchSendRequestsToSystem({
          params: {
            type: type,
            data: fields,
            files: res
          }
        })
      )
    );
  };

  const validationSchema = Yup.object().shape({
    type: Yup.object().shape({
      value: Yup.string().required("Виберіть тип запиту")
    }),
    comment: Yup.string().required("Введіть коментар")
  });

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      type: "",
      comment: ""
    },
    validationSchema: validationSchema,
    onSubmit: onFormSubmit
  });

  const onAutocompleteChange = useCallback((option, name) => {
    formik.setFieldValue(name, option);
  }, []);

  const handleBackClick = () => {
    navigate("/request-to-system-list");
  };

  return !isEmpty(requestTypeList) ? (
    <FormikProvider value={formik}>
      <Form className={styles["product-types-form__container"]}>
        <h4 className={styles["product-types-form__title"]}>Тип продукції</h4>
        <div className={styles["product-types-form__field-container"]}>
          <Select
            name="type"
            placeholder="Тип запиту"
            value={formik.values["type"] ?? ""}
            options={requestTypeList}
            onSelectChange={onAutocompleteChange}
            blurInputOnSelect={true}
            isSearchable={true}
            withFormik
          />
        </div>
        <div className={styles["product-types-form__field-container"]}>
          <FormField
            name="comment"
            placeholder="Коментар"
            as="textarea"
            className={"textarea-field"}
          />
        </div>
        <h4 className={styles["product-types-form__title"]}>
          Завантажити файли для уточнення запиту
        </h4>

        <div className={styles["product-types-form__field-container"]}>
          <SyncVisualMultipleUploader
            loadedFiles={loadedFiles}
            setLoadedFiles={setLoadedFiles}
          />
        </div>
        <div className={styles["controls-container"]}>
          <CommonButton label={"Назад"} outlined onClick={handleBackClick} />
          <CommonButton
            label={"Надіслати"}
            type="submit"
            disabled={!formik.values.type}
          />
        </div>
      </Form>
    </FormikProvider>
  ) : (
    <Preloader />
  );
};

export default OtherQuestionsForm;
