import { Alert, CommonButton } from "gov-ua-ui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import features from "features";
import { getRole } from "helpers";
import { IRootState } from "reducer";
import { IDashboardState } from "scenes/official/OfficialDashboardPage/ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import PersonalOfficeOfficialLayout from "components/layouts/PersonalOfficeOfficialLayout/PersonalOfficeOfficialLayout";
import StatusCard from "./StatusCard";

import ConfusedSmileTitleIcon from "assets/images/icons/confused-smile.svg";
import DocumentContentIcon from "assets/images/icons/document-content.svg";
import HappySmileContentIcon from "assets/images/icons/happy-content-smile.svg";
import HappySmileTitleIcon from "assets/images/icons/happy-smile.svg";
import OfficialContentIcon from "assets/images/icons/official-avatar.svg";
import SadSmileContentIcon from "assets/images/icons/sad-smile.svg";

import styles from "./official-dashboard-page.module.scss";

const OfficialMainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { statistics, fetchingStatisticsLoading } = useSelector<
    IRootState,
    IDashboardState
  >((state) => state.officialDashboard);

  const [userRole] = useState(getRole());

  const handleRowClick = () => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title: "Пошук відповідної заявки",
          loading: true
        }
      })
    );

    dispatch(
      features.officialDashboard.actions.startProcessingApplicationRequest({
        onSuccess: (response) => {
          if (response && response.uuid) {
            navigate(`/official/applications/${response.uuid}`);
          }
        },
        onError: () => {
          dispatch(features.modal.actions.hideModal());
          dispatch(
            features.modal.actions.showModal({
              modalType: "ERROR",
              modalProps: {
                title: "Помилка пошуку",
                description: "Відповідну заявку не знайдено, спробуйте пізніше"
              }
            })
          );
        }
      })
    );
  };

  const generateStatusProps = (val, target) => {
    return {
      titleImage: val > target ? HappySmileTitleIcon : ConfusedSmileTitleIcon,
      contentImage: val > target ? HappySmileContentIcon : SadSmileContentIcon,
      count: val,
      countClass: val > target ? "green" : "red"
    };
  };

  return (
    <PersonalOfficeOfficialLayout>
      <PreloaderWrapper loading={fetchingStatisticsLoading}>
        <div className={styles["personal-cabinet-page"]}>
          {statistics?.inProcessCount > 0 && (
            <Alert type="warning" className={styles["warning"]} withIcon>
              Ви вже маєте заяву в роботі. Завершіть її розгляд, щоб отримати
              наступну заяву.
            </Alert>
          )}
          <div className={styles["cards-container"]}>
            {statistics && (
              <>
                <StatusCard
                  title="Оброблено за сьогодні"
                  {...generateStatusProps(statistics.processedTodayCount, 4)}
                />
                <StatusCard
                  title="Оброблено за тиждень"
                  {...generateStatusProps(
                    statistics.processedDuringWeekCount,
                    20
                  )}
                />
                <StatusCard
                  title="Оброблено за місяць"
                  {...generateStatusProps(
                    statistics.processedDuringMonthCount,
                    80
                  )}
                />
                <StatusCard
                  title="Всього заявок"
                  contentImage={DocumentContentIcon}
                  count={statistics.inQueueByOfficialInterregionalOffice}
                />
                <StatusCard
                  title="Обробляється"
                  contentImage={OfficialContentIcon}
                  count={statistics.inProcessByOfficialInterregionalOffice}
                />
                <StatusCard
                  title="Всього чиновників"
                  contentImage={OfficialContentIcon}
                  count={statistics.officialsAtWork}
                />
              </>
            )}
          </div>
          {userRole !== "dalruOfficial" && (
            <CommonButton
              className={styles["start-processing-applications-button"]}
              onClick={handleRowClick}
              disabled={statistics?.inProcessCount > 0}
              label="Почати розгляд заяви"
            />
          )}
        </div>
      </PreloaderWrapper>
    </PersonalOfficeOfficialLayout>
  );
};

export default OfficialMainPage;
