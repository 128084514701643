import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { VisualUploadMultipleFiles } from "gov-ua-ui";
import { useMemo } from "react";

import { formatDate } from "helpers";
import getPreviewFiles from "helpers/previewFilesNaming";
import { IFile, ITTNDocument } from "interfaces";
import { BASIS_DOC_TYPE_NAME } from "scenes/subject/documents/interfaces/interfaces";

import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";

import styles from "./create-doc-form.module.scss";

interface CreateDocFormInterface {
  initialValues?: ITTNDocument;
  loadedFiles: IFile[];
  additionalDocuments?: any[];
  onLoadFiles?: (
    acceptedFiles: any,
    rejectedFiles: any,
    replaceFile: any
  ) => void;
  onDeleteFile?: (file: any) => Promise<void>;
  isPrimal: boolean;
  readOnly?: boolean;
  isTransfer?: boolean;
}

const TextItem = ({
  text,
  title,
  className
}: {
  title: string;
  text: string;
  className?: string;
}) => {
  return (
    <div className={cn(styles["create-doc__text-item"], className)}>
      <p>{title}</p>
      <p>{text}</p>
    </div>
  );
};

const CreateDocForm = ({
  initialValues,
  onLoadFiles,
  onDeleteFile,
  loadedFiles,
  isPrimal,
  readOnly,
  isTransfer,
  additionalDocuments
}: CreateDocFormInterface) => {
  const formik = useFormik({
    validateOnChange: true,
    initialValues: { ...initialValues },
    enableReinitialize: true,
    onSubmit: () => {}
  });
  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  const files = useMemo(
    () => getPreviewFiles(loadedFiles, additionalDocuments),
    [additionalDocuments, loadedFiles]
  );

  return (
    <FormikProvider value={formik}>
      <Form className={styles["create-doc"]}>
        <div
          className={cn(
            styles["create-doc__form-row"],
            styles["create-doc__form-row_first"]
          )}
        >
          <TextItem title="Документ" text={formik.values["docType"]} />
          <TextItem title="Серія" text={formik.values["series"]} />
          <TextItem title="Номер" text={formik.values["number"]} />
          <TextItem
            title="Дата"
            text={formatDate(formik.values["date"]).date}
          />
        </div>
        <div className={styles["create-doc__form-row"]}>
          <TextItem
            title={isPrimal ? "Лісокористувач" : "Продавець"}
            text={formik.values["forestUser"].label}
          />
          <TextItem
            title="Код за ЄДРПОУ"
            text={formik.values["forestUserEdrpo"]}
          />
        </div>
        {formik.values["forestUserLocation"] && (
          <div className={styles["create-doc__form-row"]}>
            <TextItem
              title="Місцезнаходження"
              text={formik.values["forestUserLocation"]}
            />
          </div>
        )}
        {!isTransfer && (
          <>
            <div
              className={cn(
                styles["create-doc__form-row"],
                styles["create-doc__form-row_inputs"]
              )}
            >
              <TextItem
                title="Контрагент"
                text={formik.values["counterparty"].label}
              />
              <TextItem
                title="Код за ЄДРПОУ"
                text={formik.values["counterpartyEdrpo"]}
              />
            </div>
            {formik.values["counterpartyLocation"] && (
              <div className={styles["create-doc__form-row"]}>
                <TextItem
                  title="Місцезнаходження"
                  text={formik.values["counterpartyLocation"]}
                />
              </div>
            )}
          </>
        )}
        {additionalDocuments && (
          <div className={cn(styles["create-doc__form-row-additional"])}>
            {additionalDocuments.map((item) => (
              <div>
                <VisualUploadMultipleFiles
                  title={"Завантажити скан-копію"}
                  loadedFiles={files.filter(
                    (file) => file.type === item.typeName
                  )}
                  readonly={readOnly}
                  accept={{
                    "application/pdf": [".pdf"],
                    "image/jpeg": [".jpg"]
                  }}
                  onDelete={onDeleteFile}
                  onDocumentView={onDocumentView}
                  onLoad={onLoadFiles}
                />
                <div className={styles["additional-text"]}>
                  {item.typeName && (
                    <TextItem
                      title={"Тип"}
                      text={
                        item.waybillType?.toUpperCase() ??
                        BASIS_DOC_TYPE_NAME[item.typeName]
                      }
                      className={styles["info-container"]}
                    />
                  )}
                  {item.number && (
                    <TextItem
                      title={"Номер"}
                      text={item.number}
                      className={styles["organization-container"]}
                    />
                  )}
                  {item.date && (
                    <TextItem
                      title={"Дата"}
                      text={formatDate(item.date).date}
                      className={styles["organization-container"]}
                    />
                  )}
                  {item.stateAuthorityName && (
                    <TextItem
                      title={"Компетентний орган"}
                      text={item.stateAuthorityName}
                      className={styles["organization-container"]}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {(!readOnly || (readOnly && loadedFiles.length > 0)) && (
          <VisualUploadMultipleFiles
            title="Завантажити скан-копію"
            accept={{
              "application/pdf": [".pdf"],
              "image/jpeg": [".jpg"]
            }}
            loadedFiles={files.filter((file) => !file.isAdditionalData)}
            onDelete={onDeleteFile}
            onDocumentView={onDocumentView}
            onLoad={onLoadFiles}
            containerClassName={styles["create-doc__form-file-loader"]}
            readonly={readOnly}
          />
        )}
      </Form>
      <ScrollToFieldError />
    </FormikProvider>
  );
};

export default CreateDocForm;
