import { createColumnHelper } from "@tanstack/react-table";
import { CommonButton, Pagination, Status } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";

import { docTypes } from "constant";
import features from "features";
import { formatDate, getDocumentViewType } from "helpers";
import { getStartIndexOfRowByPage } from "helpers/table-helpers";
import { IeDoc } from "interfaces/IeDoc";
import { IRootState } from "reducer";
import { IDeclinedApplicationsState } from "../ducks";

import BlankPage from "components/BlankPage/BlankPage";
import Preloader from "components/Preloader/Preloader";
import EDocButton from "components/buttons/EDocButton/EDocButton";
import PersonalLayout from "components/layouts/PersonalCabinetLayout/PersonalLayout";
import CommonTable from "components/tables/CommonTable/CommonTable";

import CopyIcon from "assets/images/icons/copy.svg";
import EyeIcon from "assets/images/icons/eye.svg";

import styles from "./declined-applications-page.module.scss";

const TABLE_ROWS_COUNT = 5;

interface TableData {
  num: number;
  uuid: string;
  declinedAt: string;
  applicationId: string;
  orderedAt: string;
  buyer: string;
  consignee: string;
  invoiceNumber: number;
  invoiceAt: string;
  productYieldPercentage: string;
  costStandards: string;
  view: string;
  copy: string;
  remark: string;
  eApplication: IeDoc;
  eDecline: IeDoc;
  remarksQuantity: number;
  number?: string;
}

const CanceledPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<TableData>();

  const { applications, fetchingApplicationsLoading } = useSelector<
    IRootState,
    IDeclinedApplicationsState
  >((state) => state.declinedApplications);

  useEffect(() => {
    dispatch(features.declinedApplications.actions.fetchDeclinedRequest());

    return () => {
      dispatch(features.declinedApplications.actions.clearDeclined());
    };
  }, []);

  const handleRowClick = useCallback((uuid) => {
    navigate(`/personal/applications/${uuid}`);
  }, []);

  const onCopyClick = useCallback(
    (e, uuid) => {
      e.stopPropagation();
      dispatch(
        features.modal.actions.showModal({
          modalType: "CONFIRM_ACTION",
          modalProps: {
            notificationText:
              'Скопіювати заявку? Зверніть увагу, що документи з розділу заявки "Підстава видачі сертифіката" не копіюються',
            onAccept: () => {
              dispatch(
                features.draftApplications.actions.copyDraftRequest({
                  params: { uuid },
                  onSuccess: () => {
                    toastr.success(
                      "Успіх",
                      "Заявка скопійована і доступна для роботи у розділі “Чернетки”"
                    );
                    dispatch(
                      features.draftApplications.actions.fetchDraftsRequest({
                        fields: {
                          page: 1,
                          limit: TABLE_ROWS_COUNT
                        }
                      })
                    );
                  },
                  onError: () => {
                    toastr.error(
                      "Помилка",
                      "Щось пішло не так. Заявку не вдалось скопіювати"
                    );
                  }
                })
              );
            }
          }
        })
      );
    },
    [TABLE_ROWS_COUNT]
  );

  const onDocumentView = useCallback(
    (e, file: { name: string; filePath: any; uuid: string }) => {
      e.stopPropagation();

      dispatch(
        features.modal.actions.showModal({
          modalType: getDocumentViewType(file.name),
          modalProps: {
            url: file.filePath,
            uuid: file.uuid,
            withDownload: true
          }
        })
      );
    },
    [dispatch]
  );

  const applicationsOptions = useMemo(() => {
    if (isEmpty(applications?.data)) return [];

    const startIndexOfRow = getStartIndexOfRowByPage({
      currentPage: applications?.meta?.currentPage,
      itemsPerPage: applications?.meta?.itemsPerPage
    });

    return applications.data.map((item, i) => ({
      num: startIndexOfRow + i,
      uuid: item.uuid,
      number: item?.number,
      declinedAt: `${formatDate(item.declinedAt).date}\n${
        formatDate(item.declinedAt).time
      }`,
      applicationId: item.applicationId,
      orderedAt: `${formatDate(item.orderedAt).date}\n${
        formatDate(item.orderedAt).time
      }`,
      buyer: item.buyer,
      consignee: item.consignee,
      invoiceNumber: item.invoiceNumber,
      invoiceAt: formatDate(item.invoiceAt).date,
      productYieldPercentage: item.productYieldPercentage
        ? `${item.productYieldPercentage}%`
        : "",
      costStandards: item.costStandards,
      remarksQuantity: item.remarksQuantity,
      view: "Переглянути",
      copy: "Копіювити",
      remark: "Зауваження",
      eApplication: {
        title: "еЗаява",
        ...item.eApplication,
        date: formatDate(item.eApplication?.createdAt).date
      },
      eDecline: {
        title: "еВідмова",
        ...item.eDecline,
        date: formatDate(item.eDecline?.createdAt).date
      }
    }));
  }, [applications]);

  const columns = [
    columnHelper.accessor("num", {
      cell: (info: any) => (
        <p className={styles["drafts-page__table-number"]}>{info.getValue()}</p>
      ),
      header: () => "№"
    }),
    columnHelper.accessor("declinedAt", {
      cell: (info) => info.getValue(),
      header: () => "Дата відмови"
    }),
    columnHelper.accessor("number", {
      cell: (info) => info.getValue(),
      header: () => "Номер заяви"
    }),
    columnHelper.accessor("orderedAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата створення заяви"
    }),
    columnHelper.accessor("buyer", {
      cell: (info: any) => info.getValue(),
      header: () => "Покупець"
    }),
    columnHelper.accessor("consignee", {
      cell: (info: any) => info.getValue(),
      header: () => "Вантажоодержувач"
    }),
    columnHelper.accessor("invoiceNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер інвойсу"
    }),
    columnHelper.accessor("invoiceAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата інвойсу"
    }),
    columnHelper.accessor("productYieldPercentage", {
      cell: (info: any) => info.getValue(),
      header: () => "Відсоток виходу продукції"
    }),
    columnHelper.accessor("costStandards", {
      cell: (info: any) => info.getValue(),
      header: () => "Норма витрат"
    }),
    columnHelper.accessor("eDecline", {
      cell: (info: any) => {
        const eDecline = info.getValue();

        return (
          <EDocButton
            title={eDecline.title}
            contentTitle={eDecline.name}
            contentSubtitle={eDecline.serialNumber}
            contentText={eDecline.date}
            type={docTypes.REFUSAL}
            onClick={(e) => onDocumentView(e, eDecline)}
          />
        );
      },
      header: () => "еВідмова"
    }),
    columnHelper.accessor("eApplication", {
      cell: (info: any) => {
        const eApplication = info.getValue();

        return (
          <EDocButton
            title={eApplication.title}
            contentTitle={eApplication.name}
            contentSubtitle={eApplication.serialNumber}
            contentText={eApplication.date}
            type={docTypes.APPLICATION}
            onClick={(e) => onDocumentView(e, eApplication)}
          />
        );
      },
      header: () => "еЗаява"
    }),
    columnHelper.accessor("view", {
      cell: (info: any) => (
        <div className={styles["personal-cabinet-page__table-align"]}>
          <CommonButton
            image={EyeIcon}
            round={true}
            outlined={true}
            className={styles["personal-cabinet-page__icon-button"]}
            onClick={() => handleRowClick(info.row.original.uuid)}
          />
        </div>
      ),
      header: () => "Переглянути"
    }),
    columnHelper.accessor("copy", {
      cell: (info: any) => (
        <div className={styles["personal-cabinet-page__table-align"]}>
          <CommonButton
            image={CopyIcon}
            round={true}
            outlined={true}
            className={styles["personal-cabinet-page__icon-button"]}
            onClick={(e) => onCopyClick(e, info.row.original.uuid)}
            disabled={info.row.original.completionPercentage === 0}
          />
        </div>
      ),
      header: () => "Копіювити в чернетку"
    }),
    columnHelper.accessor("remarksQuantity", {
      cell: (info: any) => (
        <div className={styles["personal-cabinet-page__table-align"]}>
          <CommonButton
            label={info.getValue()}
            round={true}
            outlined={true}
            onClick={() => handleRowClick(info.row.original.uuid)}
          />
        </div>
      ),
      header: () => "Зауважень"
    })
  ];

  const onPageClick = useCallback((event) => {
    const newPage = event.selected + 1;

    dispatch(
      features.declinedApplications.actions.fetchDeclinedRequest({
        fields: { page: newPage }
      })
    );
  }, []);

  return (
    <PersonalLayout>
      <div className={styles["personal-cabinet-page"]}>
        <Status
          title={"Відмовлено"}
          color={"#ffffff"}
          background={"#8b9094"}
          className={styles["personal-cabinet-page__status"]}
        />

        {fetchingApplicationsLoading ? (
          <Preloader />
        ) : (
          <>
            {!isEmpty(applications?.data) ? (
              <>
                <CommonTable
                  columns={columns}
                  defaultData={applicationsOptions}
                  tableType="ROW-GAP"
                  onRowClick={handleRowClick}
                />
                <Pagination
                  forcePage={applications.meta.currentPage - 1}
                  onPageChange={onPageClick}
                  pageRangeDisplayed={applications.meta.itemsPerPage}
                  pageCount={applications.meta.totalPages}
                  renderOnZeroPageCount={null}
                />
              </>
            ) : (
              <BlankPage title="Ще немає жодної відхиленої заявки" />
            )}
          </>
        )}
      </div>
    </PersonalLayout>
  );
};

export default CanceledPage;
