import cn from "classnames";

import styles from "./preloader.module.scss";

const Preloader = (props) => {
  const { isSmall, isBig, isChildPreloader } = props;

  return (
    <div
      className={cn(styles["container"], {
        [styles["container-child-preloader"]]: isChildPreloader,
        [styles["is-small"]]: isSmall,
        [styles["is-big"]]: isBig
      })}
    >
      <svg
        className={styles["icon"]}
        id="eE5fLaBlQ0d1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <path
          id="eE5fLaBlQ0d2"
          d="M243,102.53c-2.189789-10.033073-6.928931-19.332261-13.76-27L221,65.83c1,.55,2,1.16,3,1.81c1.517433,1.219026,2.966311,2.521013,4.34,3.9c8.66351,8.659987,15.421333,19.035152,19.84,30.46.06734.185787.12082.376309.16.57.110841.396098.258137.781076.44,1.15l3.19-1.9.32-.24c2.58-9.54,5-26.69-5.66-38.59-14.91-16.36-39.11-12.43-39.11-12.43s-2.63,24.74,5.59,38.66s29.89,13.31,29.89,13.31Z"
          opacity="0"
          fill="#45ad5b"
          fillRule="evenodd"
        />
        <path
          id="eE5fLaBlQ0d3"
          d="M190.25,57.09c-8.126675-6.272673-17.731777-10.346834-27.89-11.83l-12.58-2.11c1.145145-.252143,2.303673-.43911,3.47-.56c1.945532-.040553,3.891821.026215,5.83.2c12.206297,1.072967,24.053254,4.687004,34.78,10.61.171607.097186.33543.207516.49.33.339574.234738.701297.435695,1.08.6l1.21-3.51.09-.39c-4.15-9-13.28-23.69-29.14-25.92-21.93-3-37.94,15.61-37.94,15.61s13.88,20.65,29.12,26s31.48-9.03,31.48-9.03Z"
          opacity="0"
          fill="#45ad5b"
          fillRule="evenodd"
        />
        <path
          id="eE5fLaBlQ0d4"
          d="M121.43,52.84c-10.267032.41449-20.254358,3.46579-29,8.86l-11,6.47c.717778-.924607,1.485591-1.809262,2.3-2.65c1.46128-1.289324,2.997277-2.491408,4.6-3.6c10.036397-7.02276,21.43011-11.869772,33.45-14.23.194202-.039437.391834-.059535.59-.06.409308-.040965.814208-.11793,1.21-.23l-1.32-3.47-.18-.36c-8.94-4.2-25.41-9.61-39-1.13-18.71,11.84-19,36.36-19,36.36s23.92,6.88,39.06,1.2s18.29-27.16,18.29-27.16Z"
          opacity="0"
          fill="#45ad5b"
          fillRule="evenodd"
        />
        <path
          id="eE5fLaBlQ0d5"
          d="M66,93.82c-7.591213,6.910269-13.270958,15.66238-16.49,25.41l-4.27,12c-.039996-1.170257-.019969-2.341794.06-3.51.287533-1.927751.684846-3.837523,1.19-5.72C49.675711,110.173675,55.299414,99.144269,63,89.62c.129814-.149829.270171-.290186.42-.42.282925-.312265.540413-.646665.77-1l-3.24-1.81-.37-.15C51,88.8,34.91,95.24,30,110.47c-6.72,21.09,8.79,40.08,8.79,40.08s22.74-10.09,30.69-24.16-3.48-32.57-3.48-32.57Z"
          opacity="0"
          fill="#45ad5b"
          fillRule="evenodd"
        />
        <path
          id="eE5fLaBlQ0d6"
          d="M49.84,160.85c-1.362416,10.174795-.088372,20.52899,3.7,30.07l4.46,12c-.782927-.871557-1.520566-1.782758-2.21-2.73-1.025685-1.676831-1.957129-3.409518-2.79-5.19-5.169059-11.107163-7.961989-23.171261-8.2-35.42-.014821-.196387-.014821-.393613,0-.59.034987-.409254.034987-.820746,0-1.23l-3.65.69-.38.13C35.15,166.64,27,181.91,33,196.76c8.41,20.48,32.49,25.06,32.49,25.06s10.94-22.35,8-38.24-23.65-22.73-23.65-22.73Z"
          opacity="0.5"
          fill="#45ad5b"
          fillRule="evenodd"
        />
        <path
          id="eE5fLaBlQ0d7"
          d="M80.57,222.58c5.495213,8.672014,13.130173,15.783512,22.17,20.65l11.09,6.29c-1.161623-.158647-2.313433-.382332-3.45-.67-1.843765-.621998-3.650101-1.349875-5.41-2.18-11.083703-5.187284-20.961393-12.633018-29-21.86-.130989-.149049-.248139-.309712-.35-.48-.237339-.337-.508772-.648646-.81-.93l-2.34,2.87-.22.34c.83,9.85,4.38,26.81,18.52,34.33c19.6,10.28,41-1.7,41-1.7s-6-24.14-18.47-34.42-32.73-2.24-32.73-2.24Z"
          fill="#45ad5b"
          fillRule="evenodd"
        />
        <path
          id="eE5fLaBlQ0d8"
          d="M143.79,250.11c9.769181,3.102924,20.1718,3.643543,30.21,1.57l12.54-2.31c-.991023.623148-2.015939,1.190691-3.07,1.7-1.813293.710566-3.666431,1.314922-5.55,1.81-11.832458,3.1712-24.197988,3.828651-36.3,1.93-.197979-.029913-.392342-.080179-.58-.15-.400276-.099145-.808531-.162726-1.22-.19l.06,3.71.05.4c7,7,20.59,17.72,36.25,14.39c21.62-4.73,30.32-27.65,30.32-27.65s-20.11-14.65-36.28-14.49-26.43,19.28-26.43,19.28Z"
          opacity="0"
          fill="#45ad5b"
          fillRule="evenodd"
        />
        <path
          id="eE5fLaBlQ0d9"
          d="M209.91,230.57c9.493763-3.910333,17.823448-10.194614,24.19-18.25l8.12-9.83c-.360713,1.115195-.781324,2.21012-1.26,3.28-.932757,1.708748-1.964514,3.361562-3.09,4.95-7.028276,10.036242-16.07889,18.490748-26.57,24.82-.169681.097403-.346919.181006-.53.25-.372524.1798-.727405.394067-1.06.64l2.42,2.81.3.27c9.84.89,27.16.34,37-12.28c13.53-17.52,5.45-40.67,5.45-40.67s-24.82,1.71-37.1,12.21-7.87,31.8-7.87,31.8Z"
          opacity="0"
          fill="#45ad5b"
          fillRule="evenodd"
        />
        <path
          id="eE5fLaBlQ0d10"
          d="M248,173.09c4.764448-9.092644,7.106357-19.259284,6.8-29.52l-.1-12.76c.442838,1.08855.826727,2.200159,1.15,3.33.380899,1.909869.65466,3.83955.82,5.78c1.058673,12.202546-.443409,24.491684-4.41,36.08q-.1.27-.24.54c-.172219.373166-.309473.761494-.41,1.16l3.67.59h.4c8.11-5.64,21-17.19,20.47-33.2-.9-22.11-22-34.66-22-34.66s-17.92,17.26-20.57,33.21s14.42,29.45,14.42,29.45Z"
          opacity="0"
          fill="#45ad5b"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default Preloader;
