import cn from "classnames";
import { DatePicker, FormField, VisualUploadMultipleFiles } from "gov-ua-ui";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import features from "features";
import { parseFilesErrors } from "helpers/parseFilesErrors";
import { IFile } from "scenes/subject/applications/ApplicationPage/interfaces/IInvoice";

import styles from "./load-section-doc.module.scss";

interface LoadSectionDocInterface {
  docFilePlaceholder: string;
  docName?: string;
  docFileName?: string;
  className?: string;
  applicationUuid: string;
  docNumberPlaceholder?: string;
  docDatePlaceholder: string;
  docDateValidityPlaceholder?: string;
  selectedDate: Date;
  selectedValidityDate?: Date;
  loadedFiles: Array<IFile>;
  onDocDateChange: (date: Date, name: string) => void;
  isAnnul?: boolean;
  alreadyUsedDocsNames?: Array<string>;
}

function LoadSectionDoc({
  docFilePlaceholder,
  docName,
  docFileName,
  className,
  applicationUuid,
  docNumberPlaceholder,
  docDateValidityPlaceholder,
  docDatePlaceholder,
  selectedDate,
  selectedValidityDate,
  loadedFiles,
  onDocDateChange,
  alreadyUsedDocsNames,
  isAnnul
}: LoadSectionDocInterface) {
  const dispatch = useDispatch();

  const [maxDate] = useState(new Date());

  const onModalOpen = (file) => {
    window.open(file.filePath, "_blank");

    // dispatch(
    //   features.modal.actions.showModal({
    //     modalType: getDocumentViewType(file.name),
    //     modalProps: { url: file.filePath }
    //   })
    // );
  };

  const onLoadFiles = (acceptedFiles, rejectedFiles, replaceFile) => {
    if (acceptedFiles) {
      acceptedFiles.forEach((acceptedFile) => {
        const data = {
          params: { uuid: applicationUuid, replaceUuid: replaceFile?.uuid },
          fields: {
            fileType: docName ?? docFileName,
            file: Object.assign(acceptedFile, {
              alias: docName ?? docFileName
            })
          }
        };
        if (isAnnul) {
          if (alreadyUsedDocsNames.includes(acceptedFile.name)) {
            return toastr.error(
              "Помилка " + acceptedFile.name,
              `Увага! Файл з назвою ${acceptedFile.name} вже наявний у заяві на отримання сертифікату, змініть будь ласка назву файлу, що додається.`
            );
          }
          dispatch(
            features.certificateAnnulRequest.actions.loadFileRequest({
              ...data,
              file: Object.assign(acceptedFile, {
                alias: "annulmentTrustedDoc"
              })
            })
          );
        } else
          dispatch(features.application.actions.loadInvoiceFileRequest(data));
      });
    }
    parseFilesErrors(rejectedFiles);
  };

  const onDeleteFile = async (file) => {
    await new Promise((resolve) => {
      const data = {
        params: {
          uuid: file.uuid,
          applicationUuid
        },
        type: file.type,
        name: file.name,
        onSuccess: () => resolve(() => true),
        onError: () => resolve(() => true)
      };
      if (isAnnul)
        dispatch(
          features.certificateAnnulRequest.actions.deleteFileRequest(data)
        );
      else
        dispatch(features.application.actions.deleteInvoiceFileRequest(data));

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  return (
    <div className={cn(styles["load-section-doc"], className)}>
      <div className={styles["load-section-doc__inputs"]}>
        {docNumberPlaceholder && (
          <FormField
            placeholder={docNumberPlaceholder}
            name={docName ? `${docName}Number` : "number"}
          />
        )}
        <DatePicker
          date={selectedDate}
          placeholder={docDatePlaceholder}
          name={docName ? `${docName}Date` : "date"}
          onChange={(date) =>
            onDocDateChange(date, docName ? `${docName}Date` : "date")
          }
          withFormik
          maxDate={maxDate}
        />

        {docDateValidityPlaceholder && (
          <DatePicker
            date={selectedValidityDate}
            placeholder={docDateValidityPlaceholder}
            name={docName ? `${docName}Date` : "dateEnd"}
            onChange={(date) =>
              onDocDateChange(date, docName ? `${docName}Date` : "dateEnd")
            }
            withFormik
            {...(docDateValidityPlaceholder
              ? {
                  minDate: maxDate
                }
              : {
                  maxDate
                })}
          />
        )}
      </div>
      <VisualUploadMultipleFiles
        title={docFilePlaceholder}
        containerClassName={styles["load-section-doc__loader"]}
        accept={{
          "application/pdf": [".pdf"],
          "image/jpeg": [".jpg"]
        }}
        maxSize={5}
        loadedFiles={loadedFiles}
        onLoad={onLoadFiles}
        onDelete={onDeleteFile}
        onDocumentView={onModalOpen}
      />
    </div>
  );
}

export default LoadSectionDoc;
