import cn from "classnames";
import { Alert } from "gov-ua-ui";
import {
  MutableRefObject,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { applicationOfficialStatuses } from "dataset";
import features from "features";
import { scrollToResults } from "helpers";
import { IRootState } from "reducer";
import { IArchivalApplicationsState } from "../ducks";

import ApplicationsSearchForm from "components/ApplicationsSearchForm/ApplicationsSearchForm";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import PersonalOfficeOfficialLayout from "components/layouts/PersonalOfficeOfficialLayout/PersonalOfficeOfficialLayout";
import ArchivedCertificatesResults from "./components/ArchivedCertificatesTable/ArchivedCertificatesResults";

import styles from "./official-archival-page.module.scss";

interface ISearchFieldsContext {
  fields?: Object;
  resultsRef?: MutableRefObject<any>;
}

export const SearchFieldsContext = createContext<ISearchFieldsContext>({});

const OfficialArchivalPage = () => {
  const dispatch = useDispatch();
  const resultsRef = useRef(null);

  const { fetchingApplicationsLoading, applications } = useSelector<
    IRootState,
    IArchivalApplicationsState
  >((state) => state.officialApplicationsArchival);

  const [parsedFields, setParsedFields] = useState({});

  useEffect(() => {
    dispatch(features.officialArchival.actions.searchArchivedRequest());
  }, [dispatch]);

  const statuses = useMemo(() => {
    return Object.keys(applicationOfficialStatuses).map((key) => {
      return {
        label: applicationOfficialStatuses[key].title,
        value: key
      };
    });
  }, []);

  const onFormSubmit = (fields: any) => {
    dispatch(
      features.officialArchival.actions.searchArchivedRequest({
        fields,
        onSuccess: () => {
          setParsedFields(fields);
          scrollToResults(resultsRef.current);
        }
      })
    );
  };

  const onPageClick = useCallback(
    (event) => {
      const newPage = event.selected + 1;

      dispatch(
        features.officialArchival.actions.searchArchivedRequest({
          fields: { ...parsedFields, page: newPage },
          onSuccess: scrollToResults(resultsRef.current)
        })
      );
    },
    [dispatch, parsedFields]
  );

  return (
    <PersonalOfficeOfficialLayout>
      <PreloaderWrapper loading={fetchingApplicationsLoading}>
        <div className={cn(styles["personal-cabinet-page"])}>
          <ApplicationsSearchForm
            onFormSubmit={onFormSubmit}
            statuses={statuses}
            isCertificateNumber
          >
            <div ref={resultsRef}>
              {applications ? (
                <>
                  <ArchivedCertificatesResults
                    applications={applications}
                    onPageClick={onPageClick}
                  />
                </>
              ) : (
                <Alert type="warning" withIcon>
                  Архівних заяв немає
                </Alert>
              )}
            </div>
          </ApplicationsSearchForm>
        </div>
      </PreloaderWrapper>
    </PersonalOfficeOfficialLayout>
  );
};

export default OfficialArchivalPage;
