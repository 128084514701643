import { createColumnHelper } from "@tanstack/react-table";
import {
  BlackEyeIcon,
  BlankPage,
  CancelCertificateIcon,
  CommonButton,
  Pagination,
  Status
} from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { docTypes } from "constant";
import {
  applicationOfficialStatuses,
  customsClearance
} from "dataset/applicationStatuses";
import features from "features";
import { getDocumentViewType } from "helpers";
import { formatDate } from "helpers/date";
import { getStartIndexOfRowByPage } from "helpers/table-helpers";

import EDocButton from "components/buttons/EDocButton/EDocButton";
import CommonTable from "components/tables/CommonTable/CommonTable";

import styles from "./applications-search-result.module.scss";

interface IArchivedCertificatesResults {
  applications: any;
  onPageClick: (event: any) => void;
}

interface TableData {
  num: number;
  officialInterregionalOfficeName: string;
  officialFullName: string;
  applicationNumber;
  certificateNumber;
  invoiceNumber: string;
  createdAt: string;
  receivedAt: string;
  buyer: string;
  exporterOrganizationName: string;
  progress: number;
  percentage: string;
  rate_of_expenditure: string;
  edit: string;
  delete: string;
  actions: string;
  status: string;
  customsClearance?: boolean;
  applicationFile: any;
  certificateFile: any;
}

const ArchivedCertificatesResults = ({
  applications,
  onPageClick
}: IArchivedCertificatesResults) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<TableData>();

  const handleRowClick = useCallback((uuid) => {
    navigate(`/official/applications/archived/${uuid}`);
  }, []);

  const onDocumentView = useCallback(
    (e, file: { name: string; filePath: any; uuid: string }) => {
      e.stopPropagation();

      dispatch(
        features.modal.actions.showModal({
          modalType: getDocumentViewType(file.name),
          modalProps: {
            url: file.filePath,
            uuid: file.uuid,
            withDownload: true
          }
        })
      );
    },
    [dispatch]
  );

  const columns = [
    columnHelper.accessor("num", {
      cell: (info: any) => (
        <p className={styles["drafts-page__table-number"]}>{info.getValue()}</p>
      ),
      header: () => "№"
    }),
    columnHelper.accessor("officialInterregionalOfficeName", {
      cell: (info: any) => info.getValue(),
      header: () => "МРУ"
    }),
    columnHelper.accessor("officialFullName", {
      cell: (info: any) => info.getValue(),
      header: () => "Ім'я чиновника"
    }),
    columnHelper.accessor("status", {
      cell: (info: any) => (
        <Status {...applicationOfficialStatuses[info.getValue()]} />
      ),
      header: () => "Статус"
    }),
    columnHelper.accessor("applicationNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер заявки"
    }),
    columnHelper.accessor("createdAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата заявки"
    }),
    columnHelper.accessor("applicationFile", {
      cell: (info: any) => {
        const index = info.row.index;
        const data = applications.data[index];

        const formatDocFile = (alias) => {
          if (data) {
            const fileData = data[alias];
            if (fileData)
              return {
                title: fileData.title,
                contentTitle: fileData.name,
                contentSubtitle: fileData.serialNumber,
                contentText: formatDate(fileData.createdAt).date,
                onClick: (e) => onDocumentView(e, fileData)
              };
          }
        };
        formatDocFile("eApplication");
        return (
          <div className={styles["actions-container"]}>
            {data?.eApplication && (
              <EDocButton
                {...formatDocFile("eApplication")}
                type={docTypes.APPLICATION}
              />
            )}
          </div>
        );
      },
      header: () => "Файл заявки"
    }),
    columnHelper.accessor("exporterOrganizationName", {
      cell: (info: any) => info.getValue(),
      header: () => "Експортер"
    }),
    columnHelper.accessor("buyer", {
      cell: (info: any) => info.getValue(),
      header: () => "Покупець"
    }),
    columnHelper.accessor("invoiceNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер інвойсу"
    }),
    columnHelper.accessor("certificateNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер Сертифіката"
    }),
    columnHelper.accessor("receivedAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата сертифіката"
    }),
    columnHelper.accessor("certificateFile", {
      cell: (info: any) => {
        const index = info.row.index;
        const data = applications.data[index];

        const formatDocFile = (alias) => {
          if (data) {
            const fileData = data[alias];
            if (fileData)
              return {
                title: fileData.title,
                contentTitle: fileData.name,
                contentSubtitle: fileData.serialNumber,
                contentText: formatDate(fileData.createdAt).date,
                onClick: (e) => onDocumentView(e, fileData)
              };
          }
        };
        formatDocFile("eApplication");
        return (
          <div className={styles["actions-container"]}>
            {data?.eCertificate && (
              <EDocButton
                {...formatDocFile("eCertificate")}
                type={docTypes.EDOC}
              />
            )}
          </div>
        );
      },
      header: () => "Файл сертифіката"
    }),
    columnHelper.accessor("customsClearance", {
      cell: (info: any) => (
        <Status className="nowrap" {...customsClearance[info.getValue()]} />
      ),
      header: () => "Митне оформлення"
    }),
    columnHelper.accessor("actions", {
      cell: (info: any) => {
        const index = info.row.index;
        const data = applications.data[index];
        const isOrdered = data?.annulmentApplicationIsOrdered;

        const formatDocFile = (alias) => {
          if (data) {
            const fileData = data[alias];
            if (fileData)
              return {
                title: fileData.title,
                contentTitle: fileData.name,
                contentSubtitle: fileData.serialNumber,
                contentText: formatDate(fileData.createdAt).date,
                onClick: (e) => onDocumentView(e, fileData)
              };
          }
        };
        formatDocFile("eApplication");
        return (
          <div className={styles["actions-container"]}>
            <CommonButton
              image={<BlackEyeIcon />}
              round={true}
              outlined={true}
              onClick={() => handleRowClick(info.row.original.uuid)}
            />
            {info.row.original.status === "received" &&
              !isOrdered &&
              !data?.customsClearance && (
                <CommonButton
                  outlined={true}
                  className={styles["actions-container__annul-button"]}
                  onClick={(e) => {
                    e.stopPropagation();
                    return navigate(
                      `/official/applications/${info.row.original.uuid}/annul-request`
                    );
                  }}
                  image={<CancelCertificateIcon />}
                  label="Перевірити і анулювати"
                />
              )}
            {isOrdered && <p>Сформована заява на ануляцію</p>}
          </div>
        );
      },
      header: () => "Дії"
    })
  ];

  const applicationsOptions = useMemo(() => {
    if (isEmpty(applications?.data)) return [];

    const startIndexOfRow = getStartIndexOfRowByPage({
      currentPage: applications?.meta?.currentPage,
      itemsPerPage: applications?.meta?.itemsPerPage
    });

    return applications?.data.map((item, i) => ({
      uuid: item.uuid,
      num: startIndexOfRow + i,
      officialInterregionalOfficeName: item.officialInterregionalOfficeName,
      status: item.status,
      applicationNumber: item.applicationNumber,
      certificateNumber: item.certificateNumber,
      officialFullName: item.officialFullName,
      createdAt: `${formatDate(item.createdAt).date}\n${
        formatDate(item.createdAt).time
      }`,
      exporterOrganizationName: item.exporterOrganizationName,
      receivedAt:
        item.status !== "declined"
          ? `${formatDate(item.receivedAt).date}\n${
              formatDate(item.receivedAt).time
            }`
          : "",
      buyer: item.buyer,
      invoiceNumber: item.invoiceNumber,
      customsClearance: item.customsClearance
    }));
  }, [applications]);

  return (
    <div className={styles["results-container"]}>
      <div className={styles["results-title"]}>Результат пошуку</div>

      {!isEmpty(applications?.data) ? (
        <>
          <div className={styles["results-subtitle"]}>
            Знайдені за параметрами {applications?.meta?.totalItems}
          </div>
          <CommonTable
            columns={columns}
            defaultData={applicationsOptions}
            tableType="ROW-GAP"
            onRowClick={handleRowClick}
            smallText
          />
          <Pagination
            forcePage={applications.meta.currentPage - 1}
            onPageChange={onPageClick}
            pageRangeDisplayed={applications.meta.itemsPerPage}
            pageCount={applications.meta.totalPages}
            renderOnZeroPageCount={null}
          />
        </>
      ) : (
        <BlankPage title="На ваш запит нічого не знайдено" />
      )}
    </div>
  );
};

export default ArchivedCertificatesResults;
