import { createColumnHelper } from "@tanstack/react-table";
import { CommonButton, Pagination, Status } from "gov-ua-ui";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";

import { docTypes } from "constant";
import features from "features";
import { formatDate, getDocumentViewType } from "helpers";
import { getStartIndexOfRowByPage } from "helpers/table-helpers";
import { IeDoc } from "interfaces/IeDoc";
import { IRootState } from "reducer";
import { IReceivedApplicationsState } from "../ducks";

import BlankPage from "components/BlankPage/BlankPage";
import Preloader from "components/Preloader/Preloader";
import EDocButton from "components/buttons/EDocButton/EDocButton";
import PersonalLayout from "components/layouts/PersonalCabinetLayout/PersonalLayout";
import CommonTable from "components/tables/CommonTable/CommonTable";

import CopyIcon from "assets/images/icons/copy.svg";
import DocCrossedIcon from "assets/images/icons/doc_crossed.svg";
import EyeIcon from "assets/images/icons/eye.svg";

import styles from "./received-applications-page.module.scss";

interface TableData {
  num: number;
  uuid: string;
  certificateId: string;
  certificateCreatedAt: string;
  orderedAt: string;
  buyer: string;
  consignee: string;
  invoiceNumber: number;
  invoiceAt: string;
  progress: number;
  customsClearance: boolean;
  eApplication: IeDoc;
  eCertificate: IeDoc;
  view: string;
  copy: string;
  annul: string;
}

const TABLE_ROWS_COUNT = 5;

const ReceivedPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<TableData>();

  const { applications, fetchingApplicationsLoading } = useSelector<
    IRootState,
    IReceivedApplicationsState
  >((state) => state.receivedApplications);

  useEffect(() => {
    dispatch(features.receivedApplications.actions.fetchReceivedRequest());

    return () => {
      dispatch(features.receivedApplications.actions.clearReceived());
    };
  }, []);

  const onDocumentView = useCallback(
    (e, file: { name: string; filePath: any; uuid: string }) => {
      e.stopPropagation();

      dispatch(
        features.modal.actions.showModal({
          modalType: getDocumentViewType(file.name),
          modalProps: {
            url: file.filePath,
            uuid: file.uuid,
            withDownload: true
          }
        })
      );
    },
    [dispatch]
  );

  const applicationsOptions = useMemo(() => {
    if (isEmpty(applications?.data)) return [];

    const startIndexOfRow = getStartIndexOfRowByPage({
      currentPage: applications?.meta?.currentPage,
      itemsPerPage: applications?.meta?.itemsPerPage
    });

    return applications.data.map((item, i) => ({
      num: startIndexOfRow + i,
      uuid: item.uuid,
      certificateId: item.certificateId,
      certificateCreatedAt: `${formatDate(item.certificateCreatedAt).date}\n${
        formatDate(item.certificateCreatedAt).time
      }`,
      orderedAt: `${formatDate(item.orderedAt).date}\n${
        formatDate(item.orderedAt).time
      }`,
      buyer: item.buyer,
      consignee: item.consignee,
      invoiceNumber: item.invoiceNumber,
      invoiceAt: formatDate(item.invoiceAt).date,
      customsClearance: item.customsClearance,
      eApplication: {
        title: "еЗаява",
        ...item.eApplication,
        date: formatDate(item.eApplication?.createdAt).date
      },
      eCertificate: {
        title: "єСертифікат",
        ...item.eCertificate,
        certificateId: item.certificateId,
        date: formatDate(item.certificateCreatedAt).date
      },
      view: "Переглянути",
      copy: "Копіювити",
      annul: {
        annulmentIsOrderedByExporter: item.annulmentIsOrderedByExporter,
        annulmentIsOrderedByOfficial: item.annulmentIsOrderedByOfficial,
        customsClearance: item.customsClearance
      }
    }));
  }, [applications]);

  const handleAnnullClick = (path: string, e?: any) => {
    e && e.stopPropagation();
    navigate(path);
  };

  const handleRowClick = useCallback((uuid) => {
    navigate(`/personal/applications/${uuid}`);
  }, []);

  const onCopyClick = useCallback(
    (e, uuid) => {
      e.stopPropagation();

      dispatch(
        features.modal.actions.showModal({
          modalType: "CONFIRM_ACTION",
          modalProps: {
            notificationText:
              'Скопіювати заявку? Зверніть увагу, що документи з розділу заявки "Підстава видачі сертифіката" не копіюються',
            onAccept: () => {
              dispatch(
                features.draftApplications.actions.copyDraftRequest({
                  params: { uuid },
                  onSuccess: () => {
                    toastr.success(
                      "Успіх",
                      "Заявка скопійована і доступна для роботи у розділі “Чернетки”"
                    );
                    dispatch(
                      features.draftApplications.actions.fetchDraftsRequest({
                        fields: {
                          page: 1,
                          limit: TABLE_ROWS_COUNT
                        }
                      })
                    );
                  },
                  onError: () => {
                    toastr.error(
                      "Помилка",
                      "Щось пішло не так. Заявку не вдалось скопіювати"
                    );
                  }
                })
              );
            }
          }
        })
      );
    },
    [TABLE_ROWS_COUNT]
  );

  const columns = [
    columnHelper.accessor("num", {
      cell: (info: any) => (
        <p className={styles["drafts-page__table-number"]}>{info.getValue()}</p>
      ),
      header: () => "№"
    }),
    columnHelper.accessor("certificateId", {
      cell: (info) => info.getValue(),
      header: () => "Номер сертифіката"
    }),
    columnHelper.accessor("certificateCreatedAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата видачі"
    }),
    columnHelper.accessor("orderedAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата створення заяви"
    }),
    columnHelper.accessor("buyer", {
      cell: (info: any) => info.getValue(),
      header: () => "Покупець"
    }),
    columnHelper.accessor("consignee", {
      cell: (info: any) => info.getValue(),
      header: () => "Вантажоодержувач"
    }),
    columnHelper.accessor("invoiceNumber", {
      cell: (info: any) => info.getValue(),
      header: () => "Номер інвойсу"
    }),
    columnHelper.accessor("invoiceAt", {
      cell: (info: any) => info.getValue(),
      header: () => "Дата інвойсу"
    }),
    columnHelper.accessor("customsClearance", {
      cell: (info: any) => (
        <div className={styles["order-status"]}>
          {info.getValue() && (
            <>
              <Status
                className={styles["order-status__status"]}
                title={"Пройшло"}
                color={"#000000"}
                background={"#badaec"}
              />
              <p>{info.getValue().amount}</p>
            </>
          )}
          {!info.getValue() && (
            <>
              <Status
                className={styles["order-status__status"]}
                title={"не пройшло"}
                color={"#000000"}
                background={"#feeceb"}
              />
            </>
          )}
        </div>
      ),
      header: () => "Митне оформленя"
    }),
    columnHelper.accessor("eApplication", {
      cell: (info: any) => {
        const eApplication = info.getValue();
        return (
          <EDocButton
            title={eApplication.title}
            contentTitle={eApplication.name}
            contentSubtitle={eApplication.serialNumber}
            contentText={eApplication.date}
            type={docTypes.APPLICATION}
            onClick={(e) => onDocumentView(e, eApplication)}
          />
        );
      },
      header: () => "еЗаява"
    }),
    columnHelper.accessor("eCertificate", {
      cell: (info: any) => {
        const eCertificate = info.getValue();

        return (
          <EDocButton
            title={eCertificate.title}
            contentTitle={eCertificate.name}
            contentSubtitle={eCertificate.certificateId}
            contentText={eCertificate.date}
            type={docTypes.EDOC}
            onClick={(e) => onDocumentView(e, eCertificate)}
          />
        );
      },
      header: () => "єСертифікат"
    }),
    columnHelper.accessor("view", {
      cell: (info: any) => (
        <div className={styles["personal-cabinet-page__table-align"]}>
          <CommonButton
            image={EyeIcon}
            round={true}
            outlined={true}
            className={styles["personal-cabinet-page__icon-button"]}
            onClick={() => handleRowClick(info.row.original.uuid)}
          />
        </div>
      ),
      header: () => "Переглянути"
    }),
    columnHelper.accessor("copy", {
      cell: (info: any) => (
        <div className={styles["personal-cabinet-page__table-align"]}>
          <CommonButton
            image={CopyIcon}
            round={true}
            outlined={true}
            className={styles["personal-cabinet-page__icon-button"]}
            onClick={(e) => onCopyClick(e, info.row.original.uuid)}
            disabled={info.row.original.completionPercentage === 0}
          />
        </div>
      ),
      header: () => "Копіювити в чернетку"
    }),
    columnHelper.accessor("annul", {
      cell: (info: any) => {
        const {
          annulmentIsOrderedByExporter,
          annulmentIsOrderedByOfficial,
          customsClearance
        } = info.getValue();

        if (customsClearance) return <></>;
        if (annulmentIsOrderedByExporter) return <p>Заява обробляється</p>;

        return (
          <div className={styles["personal-cabinet-page__table-align"]}>
            <CommonButton
              outlined={true}
              className={styles["personal-cabinet-page__icon-button"]}
              onClick={(e) =>
                handleAnnullClick(
                  `/personal/applications/${info.row.original.uuid}/annul-request/`,
                  e
                )
              }
              image={DocCrossedIcon}
              label="Сформувати заяву"
              disabled={annulmentIsOrderedByOfficial}
            />
          </div>
        );
      },
      header: () => "Анулювання"
    })
  ];

  const onPageClick = useCallback((event) => {
    const newPage = event.selected + 1;

    dispatch(
      features.receivedApplications.actions.fetchReceivedRequest({
        fields: { page: newPage }
      })
    );
  }, []);

  return (
    <PersonalLayout>
      <div className={styles["personal-cabinet-page"]}>
        <Status
          title="Отримано"
          color="#ffffff"
          background="#19be6f"
          className={styles["personal-cabinet-page__status"]}
        />

        {fetchingApplicationsLoading ? (
          <Preloader />
        ) : (
          <>
            {!isEmpty(applications?.data) ? (
              <>
                <CommonTable
                  columns={columns}
                  defaultData={applicationsOptions}
                  tableType="ROW-GAP"
                  onRowClick={handleRowClick}
                />
                <Pagination
                  forcePage={applications.meta.currentPage - 1}
                  onPageChange={onPageClick}
                  pageRangeDisplayed={applications.meta.itemsPerPage}
                  pageCount={applications.meta.totalPages}
                  renderOnZeroPageCount={null}
                />
              </>
            ) : (
              <BlankPage title="Ще немає жодної отриманої заявки" />
            )}
          </>
        )}
      </div>
    </PersonalLayout>
  );
};

export default ReceivedPage;
