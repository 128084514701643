import { CommonButton, VisualUploadMultipleFiles } from "gov-ua-ui";
import { isEmpty } from "lodash";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams } from "react-router-dom";

import { OffsetContext } from "App";
import { applicationRouteStatuses } from "dataset";
import features from "features";
import {
  formatDate,
  generateNavButton,
  getDocumentViewType,
  iGenerateNavButton,
  parseFilesErrors,
  parseResErrors
} from "helpers";
import { IRootState } from "reducer";
import { IUserSettings } from "scenes/SettingsPage/ducks";
import { ISubjectsState } from "scenes/Subjects/ducks";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { IDraftApplicationState } from "../../DraftApplicationsPage/ducks";

import NavigationHeadSubtitle from "components/NavigationHeadSubtitle/NavigationHeadSubtitle";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import ResponseErrors from "components/ResponseErrors/ResponseErrors";
import Section from "components/Section/Section";
import SectionText from "components/Section/SectionText/SectionText";
import AddTrustedDoc from "components/certificateComponents/createCertificateComponents/AddTrustedDoc/AddTrustedDoc";
import BasisIssuingCertificate from "components/certificateComponents/createCertificateComponents/BasisIssuingCertificate/BasisIssuingCertificate";
import BasisIssuingCertificateWoodCards from "components/certificateComponents/createCertificateComponents/BasisIssuingCertificateWoodCards/BasisIssuingCertificateWoodCards";
import CreateInvoice from "components/certificateComponents/createCertificateComponents/CreateInvoice/CreateInvoice";
import DefineProducts from "components/certificateComponents/createCertificateComponents/DefineProducts/DefineProducts";
import SetApplicationType from "components/certificateComponents/createCertificateComponents/SetApplicationType/SetApplicationType";
import Applicant from "components/certificateComponents/viewCertificateComponents/Applicant/Applicant";
import EDocsSection from "components/certificateComponents/viewCertificateComponents/EDocsSection/EDocsSection";
import OtherDocs from "components/certificateComponents/viewCertificateComponents/OtherDocs/OtherDocs";
import SectionDelimiter from "components/certificateComponents/viewCertificateComponents/SectionDelimiter/SectionDelimiter";
import CertificatesLayout from "components/layouts/CertificatesLayout/CertificatesLayout";

import eDocIcon from "assets/images/icons/e_doc.svg";
import eDocAnnulAltIcon from "assets/images/icons/e_doc_annul_alt.svg";
import eDocApplicationIcon from "assets/images/icons/e_doc_application.svg";
import eDocDecline from "assets/images/icons/e_doc_refusal.svg";

import styles from "./application-page.module.scss";

const CreateCertificatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { applicationId } = useParams<{ applicationId?: string }>();
  const { userSettings, fetchSettingsGetLoading } = useSelector<
    IRootState,
    IUserSettings
  >((state) => state.settings);
  const { currentSubject, fetchingSubjectInfoLoading } = useSelector<
    IRootState,
    ISubjectsState
  >((state) => state.subjects);
  const {
    fetchingApplicationLoading,
    application,
    settingApplicationTypeLoading,
    settingApplicationInvoiceLoading,
    settingApplicationTrustedDocsLoading,
    applyingApplicationLoading,
    applyingApplicationError
  } = useSelector<IRootState, IApplicationState>((state) => state.application);
  const { fetchingApplicationsLoading } = useSelector<
    IRootState,
    IDraftApplicationState
  >((state) => state.draftApplications);
  const [isReadonly, setIsReadonly] = useState(null);
  const [isActiveEdit, setIsActiveEdit] = useState(false);
  const invoiceErrorRef = useRef<HTMLDivElement>(null);
  const productsErrorRef = useRef<HTMLDivElement>(null);
  const trustedDocDataRef = useRef<HTMLDivElement>(null);
  const applicationBasisDocumentsErrorRef = useRef<HTMLDivElement>(null);

  const navButton = useCallback(
    ({ to, title, icon, count }: iGenerateNavButton) => {
      return generateNavButton({
        to,
        title,
        icon,
        count,
        imgClass: styles["certificates-nav-btn__img"],
        btnClass: styles["certificates-nav-btn"],
        indicatorClass: styles["certificates-nav-btn__round-indicator"],
        btnIndicatorClass: styles["certificates-nav-btn-indicator"]
      });
    },
    []
  );

  const navigationLinks = useMemo(() => {
    const navigationLinksList = [
      {
        text: "Загальна інформація",
        anchor: true,
        to: "#applicant"
      },
      {
        text: "Підстава видачі сертифіката",
        anchor: true,
        to: "#basic-issuing"
      },
      {
        text: "Документи",
        anchor: true,
        to: "#loaded-docs"
      }
    ];

    if (application?.files?.eApplication?.length)
      navigationLinksList.push(
        navButton({
          to: "#e-docs",
          title: "еЗаява",
          icon: eDocApplicationIcon
        })
      );

    if (
      application?.files?.eCertificate?.length &&
      !!application?.files?.eCertificate[0]?.signedPath
    )
      navigationLinksList.push(
        navButton({
          to: "#e-docs",
          title: "єСертифікат",
          icon: eDocIcon
        })
      );

    if (
      application?.files?.eDecline?.length &&
      !!application?.files?.eDecline[0]?.signedPath
    )
      navigationLinksList.push(
        navButton({
          to: "#e-docs",
          title: "єВідмова",
          icon: eDocDecline
        })
      );
    if (
      application?.annulmentApplications[0]?.files?.eAnnulmentApplication
        ?.length
    )
      navigationLinksList.push(
        navButton({
          to: "#e-docs",
          title: "еЗаява на ануляцію",
          icon: eDocAnnulAltIcon
        })
      );

    if (application?.remarks?.length)
      navigationLinksList.push(
        navButton({
          title: "Зауваження",
          to: "#remarks",
          count: application?.remarks?.length
        })
      );

    return navigationLinksList;
  }, [application?.files, application?.remarks, navButton]);

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.application.actions.fetchApplicationRequest({
          params: { uuid: applicationId },
          onError: (response) => {
            if (response.statusCode === 404) {
              navigate("/404");
            }
            if (response.statusCode === 400) {
              parseResErrors({
                errorsRes: response,
                fields: { applicationUuid: "applicationUuid" },
                setFieldError(property) {
                  if (property === "applicationUuid") {
                    navigate("/404");
                  }
                }
              });
            }
          }
        })
      );
    }

    dispatch(features.dataset.actions.fetchSupportedDocumentsListRequest());
    dispatch(features.dataset.actions.fetchCurrencyListRequest());
    dispatch(features.dataset.actions.fetchDeliveryConditionListRequest());
  }, [applicationId, dispatch, navigate]);
  useEffect(() => {
    return () => {
      dispatch(features.application.actions.clearApplication());
      dispatch(features.dataset.actions.clearSupportedDocumentsList());
      dispatch(features.dataset.actions.clearCurrencyList());
      dispatch(features.dataset.actions.clearDeliveryConditionList());
    };
  }, []);

  useEffect(() => {
    if (application) {
      setIsReadonly(application.status !== "draft");
    }
  }, [application]);

  useEffect(() => {
    if (isReadonly === false && application) {
      let timer: any;

      const copyAndDeleteDraft = () => {
        dispatch(
          features.draftApplications.actions.copyDraftRequest({
            params: { uuid: application.uuid },
            onSuccess: ({ uuid }) => {
              dispatch(
                features.draftApplications.actions.deleteDraftRequest({
                  params: { uuid: application.uuid },
                  onSuccess: () => {
                    navigate(`/personal/applications/${uuid}`);
                  }
                })
              );
            },
            onError: () => {
              toastr.error(
                "Помилка",
                "Щось пішло не так. Заявку не вдалось продовжити"
              );
            }
          })
        );
      };

      const extendTime = () => {
        if (application.basisDocuments.length > 0) {
          dispatch(
            features.application.actions.deleteAllBasisDocumentsRequest({
              params: {
                applicationUuid: application.uuid
              },
              onSuccess: () => {
                copyAndDeleteDraft();
              }
            })
          );
        } else {
          copyAndDeleteDraft();
        }
      };
      const showModal = () => {
        dispatch(
          features.modal.actions.showModal({
            modalType: "CONFIRM_ACTION",
            modalProps: {
              title: "Час закінчився",
              notificationText:
                "Ви можете продовжити з видаленними документами підстави або видалити цю чернетку",
              acceptLabel: "Продовжити",
              declineLabel: "Видалити",
              withoutCloseButton: true,
              onClose: () => {
                dispatch(
                  features.draftApplications.actions.deleteDraftRequest({
                    params: { uuid: application.uuid },
                    onSuccess: () => navigate("/personal/applications/drafts")
                  })
                );
              },
              onAccept: () => {
                extendTime();
              }
            }
          })
        );
      };
      const { beKeptUntil } = application;

      if (beKeptUntil) {
        const time = beKeptUntil - Date.now();
        if (time > 0) timer = setTimeout(showModal, time);
        else extendTime();

        return () => {
          if (time) clearTimeout(timer);
          dispatch(features.modal.actions.hideModal());
        };
      }
    }
  }, [application, isReadonly]);

  const onDocumentView = useCallback(
    (
      file: { name: string; filePath: any; uuid: string },
      withDownload?: boolean
    ) => {
      dispatch(
        features.modal.actions.showModal({
          modalType: getDocumentViewType(file.name),
          modalProps: { url: file.filePath, uuid: file.uuid, withDownload }
        })
      );
    },
    [dispatch]
  );

  const onLoadFiles = (acceptedFiles, rejectedFiles, replaceFile) => {
    if (acceptedFiles) {
      acceptedFiles.forEach((acceptedFile) => {
        dispatch(
          features.application.actions.loadInvoiceFileRequest({
            params: { uuid: application.uuid, replaceUuid: replaceFile?.uuid },
            fields: {
              fileType: "additional",
              file: Object.assign(acceptedFile, {
                alias: "additional"
              })
            }
          })
        );
      });
    }

    parseFilesErrors(rejectedFiles);
  };

  const onDeleteFile = async (file) => {
    await new Promise((resolve) => {
      dispatch(
        features.application.actions.deleteInvoiceFileRequest({
          params: {
            uuid: file.uuid,
            applicationUuid: application.uuid
          },
          type: file.type,
          name: file.name,
          onSuccess: () => resolve(() => true),
          onError: () => resolve(() => true)
        })
      );

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  const onApplyClick = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title: "Створюється єЗаява",
          loading: true
        }
      })
    );
    dispatch(
      features.application.actions.fetchEApplicationDataRequest({
        params: { uuid: applicationId },
        onSuccess: () => {
          dispatch(features.modal.actions.hideModal());
          navigate("e-application-confirmation");
        },
        onError: () => {
          dispatch(features.modal.actions.hideModal());
          toastr.error("Помилка", "Перевірте вашу заявку.");
        }
      })
    );
  }, [applicationId, dispatch, navigate]);

  const getEDocs = useMemo(() => {
    const docs = [];
    if (application?.files?.eApplication?.length)
      docs.push({
        docImg: eDocIcon,
        docTitle: "еЗаява",
        onClick: () => onDocumentView(application?.files?.eApplication[0], true)
      });
    if (
      application?.files?.eCertificate?.length &&
      !!application?.files?.eCertificate[0]?.signedPath
    )
      docs.push({
        docImg: eDocApplicationIcon,
        docTitle: "єСертифікат",
        onClick: () => onDocumentView(application?.files?.eCertificate[0], true)
      });

    if (application?.annulmentApplications.length) {
      application?.annulmentApplications.forEach((item) => {
        if (item.files?.eAnnulmentApplication.length)
          docs.push({
            docImg: eDocAnnulAltIcon,
            docTitle: "еЗаява на ануляцію",
            docAdditionalInfoTitle: "Причина заяви:",
            docAdditionalInfoValue: item.annulmentBasisDescription,
            onClick: () =>
              onDocumentView(item.files?.eAnnulmentApplication[0], true)
          });
        if (item.files?.eAnnulmentDecline.length)
          docs.push({
            docImg: eDocIcon,
            docTitle: "Відмова на Ануляцію",
            docAdditionalInfoTitle: "Причина відмови:",
            docAdditionalInfoValue: item.declineDescription,
            onClick: () =>
              onDocumentView(item.files?.eAnnulmentDecline[0], true)
          });
      });
    }
    if (
      application?.files?.eDecline?.length &&
      !!application?.files?.eDecline[0]?.signedPath
    )
      docs.push({
        docImg: eDocDecline,
        docTitle: "єВідмова",
        onClick: () => onDocumentView(application?.files?.eDecline[0], true)
      });
    return docs;
  }, [application?.annulmentApplications, application?.files, onDocumentView]);

  const onBack = useCallback(
    () =>
      navigate(
        `/personal/applications/${
          applicationRouteStatuses[application?.status] ?? "drafts"
        }`
      ),
    [application?.status, navigate]
  );

  const { scrollOffset } = useContext(OffsetContext);

  useEffect(() => {
    const scrollPosition = () => {
      if (applyingApplicationError.invoice) {
        return invoiceErrorRef.current.offsetTop + scrollOffset;
      }
      if (applyingApplicationError.products) {
        return productsErrorRef.current.offsetTop + scrollOffset;
      }
      if (applyingApplicationError.applicationBasisDocuments) {
        return (
          applicationBasisDocumentsErrorRef.current.offsetTop + scrollOffset
        );
      }
      if (applyingApplicationError.trustedDocData) {
        return trustedDocDataRef.current.offsetTop + scrollOffset;
      }
    };

    window.scrollTo({
      top: scrollPosition(),
      behavior: "smooth"
    });
  }, [applyingApplicationError, scrollOffset]);

  // TODO
  // useEffect(() => {
  //   if (application && window.location?.hash) {
  //     const element: HTMLElement = document.querySelector(
  //       window.location?.hash
  //     );
  //     if (element) {
  //       window.scrollTo({
  //         top: element.offsetTop + scrollOffset,
  //         behavior: "smooth"
  //       });
  //     }
  //   }
  //   // if (!fetchingApplicationLoading && application) {
  //   //   console.log("asdas");

  //   //   setTimeout(() => {
  //   //     const link: HTMLElement = document.querySelector(
  //   //       `[href='${window.location.hash}']`
  //   //     );
  //   //     link?.click();
  //   //   }, 300);
  //   // }
  // }, [application, scrollOffset]);

  useEffect(() => {
    if (!isEmpty(currentSubject)) {
      const fields = {
        uuid: currentSubject.uuid,
        bUuid: currentSubject.organization.edrpou,
        orgUuid: currentSubject.organization.uuid,
        userDrfo: currentSubject.drfo
      };
      dispatch(features.settings.actions.getUserSettingsRequest({ fields }));
    }
  }, [currentSubject, dispatch]);

  const errors = useMemo(
    () => [
      ...(applyingApplicationError.unknown ?? []),
      ...(applyingApplicationError.files ?? [])
    ],
    [applyingApplicationError]
  );

  const pageTitle = useMemo(() => {
    return "Заявка на отримання сертифікату про походження лісоматеріалів та виготовлених з них пиломатеріалів в електронній формі";
  }, []);

  return (
    <CertificatesLayout
      navLinks={navigationLinks}
      title={pageTitle}
      applicationStatus={application?.status}
      navTabsClassName={
        application?.status === "declined" ? styles["declined-page-tabs"] : null
      }
      subtitle={
        <NavigationHeadSubtitle
          number={application?.number}
          submissionDate={application?.submissionDate ?? application?.createdAt}
          status={application?.status}
        />
      }
    >
      <PreloaderWrapper
        loading={
          settingApplicationTypeLoading ||
          settingApplicationInvoiceLoading ||
          settingApplicationTrustedDocsLoading ||
          applyingApplicationLoading ||
          fetchingApplicationLoading ||
          fetchingSubjectInfoLoading ||
          fetchSettingsGetLoading ||
          fetchingApplicationsLoading
        }
        withModal
      >
        {!isEmpty(application) && (
          <div className={styles["create-certificate"]} id="applicant">
            {currentSubject && !isEmpty(userSettings) && (
              <Applicant
                name={currentSubject.organizationName}
                organizationEdrpou={currentSubject.organization?.edrpou}
                organizationDrfo={currentSubject.organization?.drfo}
                organizationTel={
                  currentSubject.organization.data.contacts.tel.length > 0
                    ? currentSubject.organization.data.contacts.tel
                    : undefined
                }
                organizationEmail={
                  currentSubject.organization.data.contacts.email
                }
                organizationAddress={
                  currentSubject.organization.data?.address?.address
                }
                fio={currentSubject.fullName}
                drfo={currentSubject.drfo}
                serial_pass={currentSubject.passportSeries}
                idcard={currentSubject.passportNumber}
                tel={userSettings.phone}
                email={userSettings.email}
              />
            )}

            <SectionDelimiter />

            {application?.exporterIsForestuser ? (
              <>
                <SetApplicationType
                  applicationUuid={application.uuid}
                  isReadOnly={isReadonly}
                />

                <SectionDelimiter />
              </>
            ) : (
              <></>
            )}

            {applyingApplicationError.invoice && (
              <div className={styles["error-wrapper"]} ref={invoiceErrorRef}>
                <ResponseErrors
                  errors={applyingApplicationError.invoice}
                  withIcon={false}
                />
              </div>
            )}
            <CreateInvoice
              applicationUuid={application.uuid}
              isReadonly={isReadonly}
              onEdit={setIsActiveEdit}
            />

            <SectionDelimiter />

            {applyingApplicationError.products && (
              <div className={styles["error-wrapper"]} ref={productsErrorRef}>
                <ResponseErrors
                  errors={applyingApplicationError.products}
                  withIcon={false}
                />
              </div>
            )}
            <DefineProducts isReadonly={isReadonly} onEdit={setIsActiveEdit} />

            <SectionDelimiter />

            {applyingApplicationError.applicationBasisDocuments && (
              <div
                className={styles["error-wrapper"]}
                ref={applicationBasisDocumentsErrorRef}
              >
                <ResponseErrors
                  errors={applyingApplicationError.applicationBasisDocuments}
                  withIcon={false}
                />
              </div>
            )}
            <div id="basic-issuing">
              {application?.useWoodCard ? (
                <BasisIssuingCertificateWoodCards
                  documents={application?.basisDocuments}
                  woodCards={application?.woodCards}
                  applicationUuid={application?.uuid}
                  isReadonly={isReadonly}
                />
              ) : (
                <BasisIssuingCertificate
                  documents={application?.basisDocuments}
                  applicationUuid={application?.uuid}
                  isReadonly={isReadonly}
                />
              )}
            </div>
            {applyingApplicationError.trustedDocData && (
              <div className={styles["error-wrapper"]} ref={trustedDocDataRef}>
                <ResponseErrors
                  errors={applyingApplicationError.trustedDocData}
                  withIcon={false}
                />
              </div>
            )}
            {((currentSubject?.organization?.role !== "DIRECTOR" &&
              application.status === "draft") ||
              (application.status !== "draft" &&
                !!application.trustedDocData)) && (
              <AddTrustedDoc
                applicationUuid={application?.uuid}
                isReadonly={isReadonly}
                onEdit={setIsActiveEdit}
              />
            )}

            <div id="loaded-docs">
              <OtherDocs
                docs={[
                  <VisualUploadMultipleFiles
                    title="Завантажити скан-копію"
                    accept={{
                      "application/pdf": [".pdf"],
                      "image/jpeg": [".jpg"]
                    }}
                    maxSize={5}
                    loadedFiles={application.files.additional}
                    onLoad={onLoadFiles}
                    onDelete={onDeleteFile}
                    onDocumentView={onDocumentView}
                    readonly={isReadonly}
                  />
                ]}
              />
            </div>
            {(application?.files?.eCertificate?.length > 0 ||
              application?.files?.eApplication?.length > 0) && (
              <div id="e-docs">
                <EDocsSection docs={getEDocs} />
              </div>
            )}

            {application?.remarks?.length > 0 && (
              <Section
                title="Зауваження"
                className={styles["concern-section"]}
                titleClassName={styles["concern-section__title"]}
                id="remarks"
              >
                {application?.remarks.map((item, i) => (
                  <div className={styles["concern-section__concern"]} key={i}>
                    {!(
                      application?.remarks[i - 1]?.blockType === item.blockType
                    ) && <h3>{item.blockType}</h3>}
                    <SectionText title="Тип зауваження">
                      {item.errorType}
                    </SectionText>
                    <SectionText title="Опис зауваження">
                      {item.description}
                    </SectionText>
                    {item.basisDocumentDetails && (
                      <SectionText title="Документ">
                        {`
                        ${item.basisDocumentDetails.typeName}
                        ${item.basisDocumentDetails.series}
                        ${item.basisDocumentDetails.number}
                        ${formatDate(item.basisDocumentDetails.date).date}
                        ${
                          item.basisDocumentDetails?.chainNumber
                            ? `Номер ланцюга ${item.basisDocumentDetails?.chainNumber?.toString()}`
                            : ""
                        }
                        `}
                      </SectionText>
                    )}
                  </div>
                ))}
              </Section>
            )}
            {errors.length > 0 && (
              <div className={styles["error-wrapper"]}>
                <ResponseErrors errors={errors} withIcon={false} />
              </div>
            )}
            {!isReadonly ? (
              <CommonButton
                disabled={
                  application.completionPercentage < 100 ||
                  isActiveEdit ||
                  (currentSubject?.organization?.role !== "DIRECTOR" &&
                    !application.trustedDocData)
                }
                className={styles["create-certificate__create-btn"]}
                label="Подати заяву на видачу сертифіката"
                onClick={onApplyClick}
              />
            ) : (
              <CommonButton
                className={styles["create-certificate__create-btn"]}
                label="Назад до кабінету"
                outlined={true}
                onClick={onBack}
              />
            )}
          </div>
        )}
      </PreloaderWrapper>
    </CertificatesLayout>
  );
};

export default CreateCertificatePage;
