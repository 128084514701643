import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton } from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import features from "features";
import {
  calculateTotals,
  formatDate,
  generateColumnData,
  getChainNumber,
  parseResErrors,
  productResParser
} from "helpers";
import { testDigitsAfterComma } from "helpers/table-helpers";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";

import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import EditableTable from "components/tables/EditableTable/EditableTable";

import EyeIcon from "assets/images/icons/eye.svg";

import styles from "../specify-current-zn-usage-layout.module.scss";

const NUMBER_OF_DECIMALS = 5;

const ZSN = ({
  data,
  submitCount,
  parentId,
  chain,
  applicationId,
  index,
  onUpdateTotals,
  isGrouped
  // byUnion
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const onRowsSelected = useCallback((items) => {
    setSelectedRows(items);
  }, []);

  const handleFormSubmit = (values) => {
    if (!selectedRows.length) return;

    const products = getSelectedProducts.map((item) => {
      return {
        productUuid: item.uuid,
        takenQuantity: parseFloat(item.decommissioned)
      };
    });

    dispatch(
      features.productUsage.actions.joinDocumentRequest({
        params: {
          applicationUuid: applicationId,
          docId: data.uuid,
          isPartOfMultiple: true
        },
        fields: {
          primal: !parentId,
          parentId: chain === "transition" ? parentId : null,
          products,
          chainNumber: getChainNumber(application?.basisDocuments)
        },
        onSuccess: () => {
          dispatch(
            features.productUsage.actions.updateIsJoiningDocumentsLoaded(
              data.uuid
            )
          );
        },
        onError: (e) => {
          parseResErrors({
            setFieldError: formik.setFieldError,
            errorsRes: e,
            fields: values,
          });
        }
      })
    );
  };

  const isSelectedRow = (index) => {
    return selectedRows.indexOf(index) !== -1;
  };

  useEffect(() => {
    if (data && data?.products?.length) {
      const products = productResParser(data?.products);
      formik.setFieldValue("products", products);
    }
  }, [data]);

  const tableRow = useMemo(() => {
    return {
      id: "",
      production: "",
      clarification: "",
      breed: [""],
      grade: [""],
      diameter: "",
      diametersGroup: "",
      thickness: "",
      width: "",
      length: "",
      original: "",
      available: "",
      decommissioned: "",
      unit: "",
      price: "",
      cost: "",
      uktzed: "",
      accounting: ""
    };
  }, []);

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      original: 0,
      available: 0,
      decommissioned: 0,
      totalSum: 0,
      totalSumPDV: 0,
      original_summary: 0,
      available_summary: 0,
      decommissioned_summary: 0,
      amount: 0,
      amountPDV: 0,
      products: [tableRow]
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      products: Yup.array().of(
        Yup.object().shape({
          decommissioned: Yup.number()
            .transform((_, value) => {
              if (typeof value === "number") return value;
              return parseFloat(value.replace(/,/, "."));
            })
            .typeError("Значення має бути числовим")
            .nullable()
            .when(["id", "available"], ([id, available], schema) => {
              return isSelectedRow(id)
                ? schema
                    .transform((_, value) => {
                      if (typeof value === "number") return value;
                      return parseFloat(value.replace(/,/, "."));
                    })
                    .typeError("Значення має бути числовим")
                    .positive("Значення має бути більше 0")
                    .test(
                      "is-decimal",
                      "Максимальна кількість значень після коми " +
                        NUMBER_OF_DECIMALS,
                      (val: any) => {
                        if (val !== undefined) {
                          return testDigitsAfterComma(val, NUMBER_OF_DECIMALS);
                        }
                        return true;
                      }
                    )
                    .required("Заповніть значення")
                    .max(
                      available,
                      "Значенния Списується не повинно бути більше ніж доступно"
                    )
                : schema.notRequired();
            })
        })
      )
    }),
    onSubmit: handleFormSubmit
  });

  const columns = useMemo(() => {
    const columnsData = generateColumnData({ formik });

    return columnsData;
  }, [formik]);

  const getSelectedProducts = useMemo(() => {
    return selectedRows.length > 0
      ? selectedRows.map((index) => formik.values.products[index])
      : [];
  }, [formik.values.products, selectedRows]);

  const [totalsOfSelected, setTotalsOfSelected] = useState({
    amount: 0,
    amountPDV: 0,
    decommissioned: 0
  });

  useEffect(() => {
    if (getSelectedProducts.length > 0) {
      const unitList = [
        ...new Set(getSelectedProducts.map((item) => item.unit))
      ];
      const measurementUnitName = unitList.length === 1 ? unitList[0] : null;

      const { amount, amountPDV, decommissioned } =
        calculateTotals(getSelectedProducts);
      onUpdateTotals({
        amount: parseFloat(amount),
        amountPDV: parseFloat(amountPDV),
        decommissioned: parseFloat(decommissioned),
        index,
        measurementUnitName
      });
      setTotalsOfSelected({
        amount,
        amountPDV,
        decommissioned
      });
    } else {
      setTotalsOfSelected({ amount: 0, amountPDV: 0, decommissioned: 0 });
      onUpdateTotals({
        amount: 0,
        amountPDV: 0,
        decommissioned: 0,
        index,
        measurementUnitName: null
      });
    }
  }, [getSelectedProducts]);

  useEffect(() => {
    if (submitCount > 0) formik.submitForm();
  }, [submitCount]);

  const handlePreviousUsageClick = () => {
    const isPrimal = !parentId ? "primal" : "transition";
    window.open(
      `/personal/applications/${applicationId}/documents/${
        chain || isPrimal
      }/zn/${data.uuid}/previous-usage`,
      "_blank"
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form className={styles["specify-usage__table-form"]}>
        <h2 className={styles["specify-usage__table-form-title"]}>
          {data.typeName}, {data.freightCarNumber}, {data.series} {data.number},{" "}
          {formatDate(data.date).date}
        </h2>
        <div className={styles["specify-usage__table-controls"]}>
          <CommonButton
            className={styles["specify-usage__table-controls-button"]}
            label="Попереднє використання"
            outlined={true}
            image={EyeIcon}
            onClick={handlePreviousUsageClick}
          />
          {/* {!byUnion && (
            <div className={styles["specify-usage__table-controls-text"]}>
              <p>Стандарт:</p>
              {data?.products[0].productStandardName && (
                <p>{data.products[0].productStandardName}</p>
              )}
            </div>
          )} */}
          <div className={styles["specify-usage__table-controls-text"]}>
            <p>Геометричні розміри:</p>
            {data.products[0]?.geometricDimension && (
              <p>{data.products[0].geometricDimension}</p>
            )}
          </div>
        </div>

        <EditableTable
          formik={formik}
          defaultData={formik.values["products"]}
          columns={columns}
          footerTitle="Всього"
          removeControls={true}
          groupData={isGrouped}
          onRowsSelected={onRowsSelected}
          footer={[
            {
              name: "original",
              label: "Оригінал",
              value: data?.quantityOrigin
            },
            {
              name: "available",
              label: "Доступно",
              value: data?.quantityAvailable
            },
            {
              name: "decommissioned",
              label: "Списано",
              value: totalsOfSelected.decommissioned
            },
            {
              name: "amount",
              label: "Сума",
              value: totalsOfSelected.amount
            },
            {
              name: "amountPDV",
              label: "Сума з ПДВ",
              value: totalsOfSelected.amountPDV
            }
          ]}
          withPDV
          readonlyNotSelected
        />
      </Form>
      <ScrollToFieldError />
    </FormikProvider>
  );
};

export default ZSN;
