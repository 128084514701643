import cn from "classnames";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import features from "features";
import { FormField, Select } from "gov-ua-ui";

import styles from "./editable-table.module.scss";

const UKTZED = ({ index, productTypeId, woodUuid }) => {
  const formik = useFormikContext();
  const dispatch = useDispatch();

  const [uktzedList, setUktzedList] = useState([]);
  const handleSelectChange = (option) => {
    formik.setFieldValue(`products.${index}.uktzed`, option.value);
  };

  useEffect(() => {
    if ((productTypeId || productTypeId === 0) && woodUuid)
      dispatch(
        features.dataset.actions.fetchUktzedRequest({
          fields: {
            productId: productTypeId,
            woodSpecieUuid: woodUuid
          },
          onSuccess: (response) => {
            if (response) {
              setUktzedList(
                response.map((item) => {
                  return { label: item, value: item };
                })
              );
              formik.setFieldValue(`products.${index}.uktzed`, response[0]);
            } else {
              setUktzedList([]);
              formik.setFieldValue(`products.${index}.uktzed`, "");
            }
          }
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, productTypeId, woodUuid]);

  return uktzedList.length <= 1 ? (
    <FormField
      name={`products.${index}.uktzed`}
      readonly
      noBorders
      className={cn(styles["table-input"], styles["table-input_has-value"])}
      errClassName={styles["table-input__error-message"]}
      widthByValue
      minWidth="40px"
      maxWidth="100px"
      textAlign="center"
    ></FormField>
  ) : (
    <Select
      name={`products.${index}.uktzed`}
      withFormik
      value={{
        value: formik.values["products"][index]["uktzed"],
        label: formik.values["products"][index]["uktzed"]
      }}
      options={uktzedList}
      blurInputOnSelect={true}
      className={cn("table__input", {
        "table__input--has-value":
          formik.values["products"][index]["uktzed"].length
      })}
      styles={{
        menu: (provided: any) => {
          return {
            ...provided,
            minWidth: "max-content"
          };
        },
        menuList: (provided: any) => {
          return {
            ...provided,
            fontSize: "10px"
          };
        }
      }}
      onSelectChange={handleSelectChange}
      menuPortalTarget={document.body}
    />
  );
};

export default UKTZED;
