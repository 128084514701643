import { Form, FormikProvider, useFormik } from "formik";
import { CommonButton, Notification } from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import features from "features";
import {
  calculateTotals,
  formatDate,
  formatNumber,
  getChainNumber,
  parseFilesErrors,
  parseResErrors
} from "helpers";
import { IRootState } from "reducer";
import { ISubjectDocs } from "../../ducks";
import { BASIS_DOC_TYPE } from "../../interfaces/interfaces";

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import CreateDocForm from "components/CreateDocForm/CreateDocForm";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import ApplicationLayout from "components/layouts/ApplicationLayout/ApplicationLayout";
import CreateZSN from "components/railDocsComponents/CreateZSN/CreateZSN";
import FormControls from "components/railDocsComponents/FormControls/FormControls";
import Summary from "components/railDocsComponents/Summary/Summary";
import ZSNDocView from "components/railDocsComponents/ZSNDocView/ZSNDocView";

import DodPDFIcon from "assets/images/icons/doc_preview.svg";
import { getSearchTitle } from "helpers/bread-crumbs";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";

import styles from "./zn-doc-page.module.scss";

const _ZNDocPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const parentId = parseInt(searchParams.get("parent"));

  const [summaryList, setSummaryList] = useState([]);
  const [isEditingActive, setIsEditingActive] = useState(false);

  const { applicationId, documentId } = useParams();
  const { document } = useSelector<IRootState, ISubjectDocs>(
    (state) => state.subjectDocs
  );
  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.application.actions.fetchApplicationRequest({
          params: { uuid: applicationId }
        })
      );

      if (documentId) {
        dispatch(
          features.subjectDocs.actions.fetchDocRequest({
            params: {
              uuid: documentId
            },
            fields: {
              validateForApplication: applicationId,
              validateForParentId: parentId.toString()
            },
            onError: (errorsRes) => {
              onBack();
              parseResErrors({ errorsRes });
            }
          })
        );
      }
    }

    return () => {
      dispatch(features.application.actions.clearApplication());
    };
  }, []);

  const handleUpdateSummary = useCallback(({ uuid, values }) => {
    setSummaryList((prevSummaryList) => {
      const replaceIndex = prevSummaryList.findIndex(
        (item) => item.uuid === uuid
      );

      if (replaceIndex > -1) {
        const updatedList = prevSummaryList.map((item) =>
          item.uuid === uuid ? { ...item, values } : item
        );
        return updatedList;
      } else {
        return [
          ...prevSummaryList,
          {
            uuid,
            values
          }
        ];
      }
    });
  }, []);

  const onZSNedit = useCallback((val) => {
    setIsEditingActive(val);
  }, []);

  const handleFormSubmit = () => {
    if (
      !document.files.find(
        (file) => file.type === BASIS_DOC_TYPE.ZN_SCANCOPY
      ) ||
      document.files.find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }
    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Ви впевненні, що внесли повну інформацію по документу у відповідності до паперового примірника? Документ буде збережено у єдиній базі документів і подальше його редагування буде неможливе! Документ буде доступний тільки у режимі використання!",
          acceptLabel: "Все вірно, продовжити",
          onAccept: () =>
            document?.byUnion
              ? navigate(`success?parent=${parentId}`)
              : updateDocument()
        }
      })
    );
  };

  const updateDocument = () => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "PRELOADER",
        modalProps: {
          title: "Опрацювання запиту",
          loading: true
        }
      })
    );
    dispatch(
      features.subjectDocs.actions.updateDocRequest({
        params: {
          basisDocumentUuid: documentId
        },
        fields: {
          ...document,
          validateForChainNumber: getChainNumber(application?.basisDocuments)
        },
        onSuccess: () => {
          dispatch(features.modal.actions.hideModal());

          navigate(`success?parent=${parentId}`);
        },
        onError: (errorsRes) => {
          dispatch(features.modal.actions.hideModal());

          parseResErrors({
            errorsRes
          });
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      quantity: 0,
      amount: "0.00",
      amountPDV: "0.00"
    },
    onSubmit: handleFormSubmit
  });

  const backPath = useMemo(() => {
    const path = parentId
      ? `transition/search?parent=${parentId}`
      : "primary/search";
    return `/personal/applications/${applicationId}/documents/${path}`;
  }, [applicationId, parentId]);

  const searchTitle = useMemo(() => {
    return getSearchTitle(!parentId);
  }, [parentId]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Заявка на видачу сертифіката",
        to: `/personal/applications/${applicationId}`
      },
      {
        text: searchTitle,
        to: backPath
      },
      {
        text: `Створити документ ${document?.typeName}`,
        to: "/"
      }
    ],
    [applicationId, backPath, document, searchTitle]
  );

  const isDisabledSaveActions = useMemo(() => {
    return (
      document?.children?.at(-1)?.products?.length === 0 || isEditingActive
    );
  }, [document?.children, isEditingActive]);

  const onBack = useCallback(() => navigate(backPath), [backPath, navigate]);

  const onDeleteFile = async (file) => {
    await new Promise((resolve) => {
      dispatch(
        features.subjectDocs.actions.deleteDocFileRequest({
          params: {
            documentUuid: documentId,
            fileUuid: file.uuid
          },
          type: file.type,
          name: file.name,
          onSuccess: () => resolve(() => true),
          onError: () => resolve(() => true)
        })
      );

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  const handleLoadFiles =
    (docType) => (acceptedFiles, rejectedFiles, replaceFile) => {
      if (acceptedFiles)
        acceptedFiles.forEach((file) => {
          dispatch(
            features.subjectDocs.actions.uploadDocFileRequest({
              params: {
                basisDocumentUuid: documentId,
                replaceUuid: replaceFile?.uuid
              },
              fields: {
                fileType: docType,
                file: Object.assign(file, {
                  alias: docType
                })
              }
            })
          );
        });

      parseFilesErrors(rejectedFiles);
    };

  const addEmptyZSN = useCallback(() => {
    const { sourceOrganizationExternalId, recipientOrganizationExternalId } =
      document;
    dispatch(
      features.subjectDocs.actions.createDocRequest({
        fields: {
          documentTypeAlias: "zsn",
          sourceOrganizationExternalId,
          recipientOrganizationExternalId,
          validateForChainNumber: getChainNumber(application?.basisDocuments)
        },
        onSuccess: () => setIsEditingActive(true)
      })
    );
  }, [dispatch, document]);

  useEffect(() => {
    if (document?.children?.length === 0) {
      addEmptyZSN();
    }
  }, [addEmptyZSN, document]);

  useEffect(() => {
    let totalQuantity = 0;
    let totalAmount = 0;
    let totalAmountPDV = 0;

    if (document?.byUnion) {
      document?.children?.forEach((element) => {
        const { amount, amountPDV } = calculateTotals(
          element.products.map((item) => {
            return { ...item, amount: item.totalPrice };
          })
        );
        totalQuantity = totalQuantity + element.quantityAvailable;
        totalAmount = totalAmount + parseFloat(amount);
        totalAmountPDV = totalAmountPDV + parseFloat(amountPDV);
      });
    } else
      summaryList?.forEach((element) => {
        totalQuantity = totalQuantity + parseFloat(element.values.quantity);
        totalAmount = totalAmount + parseFloat(element.values.amount);
        totalAmountPDV = totalAmountPDV + parseFloat(element.values.amountPDV);
      });

    formik.setFieldValue("quantity", formatNumber(totalQuantity, 5));
    formik.setFieldValue("amount", formatNumber(totalAmount, 2, true));
    formik.setFieldValue("amountPDV", formatNumber(totalAmountPDV, 2, true));
  }, [document, summaryList]);

  useEffect(
    () => () => {
      formik.resetForm();
    },
    []
  );

  return (
    <ApplicationLayout className={styles["create-rail-waybill"]}>
      <Breadcrumbs elements={breadcrumbs} />
      <h1 className={styles["create-rail-waybill__title"]}>
        Створити документ
      </h1>
      {document && (
        <CreateDocForm
          initialValues={{
            docType: document.typeName,
            series: document.series,
            number: document.number,
            date: document.date,
            forestUser: {
              value: document.sourceOrganizationExternalId,
              label: document.sourceOrganizationName
            },
            counterparty: {
              value: document.recipientOrganizationExternalId,
              label: document.recipientOrganizationName
            },
            forestUserEdrpo: document.sourceOrganizationEdrpou,
            forestUserLocation: document.sourceOrganizationCountry,
            counterpartyEdrpo: document.recipientOrganizationEdrpou,
            counterpartyLocation: document.recipientOrganizationCountry
          }}
          loadedFiles={document?.files?.filter(
            (item) => item.type === BASIS_DOC_TYPE.ZN_SCANCOPY
          )}
          onDeleteFile={onDeleteFile}
          onLoadFiles={handleLoadFiles(BASIS_DOC_TYPE.ZN_SCANCOPY)}
          isPrimal={!parentId}
        />
      )}
      <div className={styles["create-rail-waybill__table-form"]}>
        <h2 className={styles["create-rail-waybill__table-form-title"]}>
          Заповнити продукцію
        </h2>
        <Notification
          text="Заповніть всі поля, після завершення створення документу, ви зможете вибрати кількість використання з цього документу "
          className={styles["create-rail-waybill__table-form-reminder"]}
        />
        {document?.children?.map((zsn, index) => {
          return document?.byUnion ? (
            <ZSNDocView
              image={DodPDFIcon}
              type={zsn.typeName}
              wagon={zsn.freightCarNumber}
              serial={zsn.series}
              number={zsn.number}
              date={formatDate(zsn.date).date}
              customFields={[
                {
                  title: "Кількість оригінал",
                  text: zsn?.quantity ?? zsn?.quantityOrigin
                },
                {
                  title: "Кількість доступно",
                  text: zsn.quantityAvailable
                }
              ]}
            />
          ) : (
            <CreateZSN
              key={`ZSN${index}`}
              title={`Залізнична специфікація накладна`}
              onUpdateSummary={handleUpdateSummary}
              zsn={zsn}
              isEditingActive={isEditingActive}
              onRequestEdit={onZSNedit}
            />
          );
        })}
        {!document?.byUnion && (
          <CommonButton
            label="Додати ЗСН"
            disabled={isDisabledSaveActions}
            onClick={addEmptyZSN}
            className={styles["create-rail-waybill__table-button"]}
          />
        )}
        <FormikProvider value={formik}>
          <Form className={styles["create-zsn__form"]}>
            <Summary
              title="Всього по ЗН"
              elements={[
                {
                  title: "Кількість",
                  name: "quantity"
                },
                {
                  title: "Сума",
                  name: "amount"
                },
                {
                  title: "Сума з ПДВ",
                  name: "amountPDV"
                }
              ]}
            />
            <FormControls
              onAcceptDisabled={isDisabledSaveActions}
              acceptLabel="Зберегти"
              onDecline={onBack}
              className={styles["create-rail-waybill__table-form-controls"]}
            />
          </Form>
          <ScrollToFieldError />
        </FormikProvider>
      </div>
    </ApplicationLayout>
  );
};

export default _ZNDocPage;
