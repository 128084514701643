import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import {
  DatePicker,
  FormField,
  Notification,
  Select,
  VisualUploadMultipleFiles
} from "gov-ua-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import features from "features";
import {
  parseResErrors,
  productValidationSchemaReq,
  productsReqParser,
  tableRowReq
} from "helpers";
import { getSearchTitle } from "helpers/bread-crumbs";
import { parseFilesErrors } from "helpers/parseFilesErrors";
import { IFile } from "interfaces";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";
import { ISubjectDocs } from "../../ducks";
import { BASIS_DOC_TYPE } from "../../interfaces/interfaces";

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import CreateDocForm from "components/CreateDocForm/CreateDocForm";
import EditableProductsTable from "components/certificateComponents/createCertificateComponents/DefineProducts/EditableProductsTable";
import ApplicationLayout from "components/layouts/ApplicationLayout/ApplicationLayout";
import FormControls from "components/railDocsComponents/FormControls/FormControls";

import styles from "./md-doc-page.module.scss";

const WAYBILL_TYPE_OPTIONS = [
  {
    value: "CMR",
    label: "CMR"
  },
  {
    value: "ЗН",
    label: "ЗН"
  }
];

interface CustomsDeclarationPageInterface {}

const _CustomsDeclarationPage = ({}: CustomsDeclarationPageInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentId = parseInt(searchParams.get("parent"));

  const { applicationId, documentId } = useParams();
  const { document } = useSelector<IRootState, ISubjectDocs>(
    (state) => state.subjectDocs
  );
  const { sizeList, standardList } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.application.actions.fetchApplicationRequest({
          params: { uuid: applicationId }
        })
      );

      if (documentId) {
        dispatch(
          features.subjectDocs.actions.fetchDocRequest({
            params: {
              uuid: documentId
            },
            fields: {
              validateForApplication: applicationId,
              validateForParentId: parentId.toString()
            },
            onError: (errorsRes) => {
              onBack();
              parseResErrors({ errorsRes });
            }
          })
        );
      }
    }

    return () => {
      dispatch(features.application.actions.clearApplication());
    };
  }, []);

  const validateFiles = useCallback((files: IFile[]) => {
    return (
      !files.find((file) => file.type === BASIS_DOC_TYPE.MD_SCANCOPY) ||
      !files.find((file) => file.type === BASIS_DOC_TYPE.MD_CMR_OR_ZN) ||
      !files.find((file) => file.type === BASIS_DOC_TYPE.MD_SPECIFICATION)
    );
  }, []);

  const handleFormSubmit = (values, { setFieldError }) => {
    if (
      validateFiles(document.files) ||
      document.files.find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }

    const {
      standard,
      size,
      waybillDate,
      waybillNumber,
      waybillType,
      specificationDate,
      specificationNumber,
      recipientOrganizationExternalId
    } = values;
    const geometricDimensionUuid = size.value;

    const products = productsReqParser(values.products, {
      productStandardUuid: standard.value,
      geometricDimensionUuid
    });

    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Ви впевненні, що внесли повну інформацію по документу у відповідності до паперового примірника? Документ буде збережено у єдиній базі документів і подальше його редагування буде неможливе! Документ буде доступний тільки у режимі використання!",
          acceptLabel: "Все вірно, продовжити",
          onAccept: () =>
            dispatch(
              features.subjectDocs.actions.updateDocRequest({
                params: {
                  basisDocumentType: "customs_declaration",
                  basisDocumentUuid: documentId
                },
                fields: {
                  recipientOrganizationExternalId: parseInt(
                    recipientOrganizationExternalId
                  ),
                  products,
                  waybillDate,
                  waybillNumber,
                  waybillType: waybillType.value,
                  specificationDate,
                  specificationNumber
                },
                onSuccess: () => {
                  navigate(`success?parent=${parentId}`);
                },
                onError: (e) => {
                  parseResErrors({
                    setFieldError: setFieldError,
                    errorsRes: e,
                    fields: values,
                    fieldsAliases: {
                      quantityMeasurementUnitUuid: "unit",
                      productExternalId: "productType",
                      woodSpecieUuids: "wood",
                      woodGradeUuids: "sort",
                      accountingUuid: "accounting"
                    }
                  });
                }
              })
            )
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      recipientOrganizationExternalId:
        document?.recipientOrganizationExternalId,
      standard: {
        value: "",
        label: ""
      },
      size: {
        value: "",
        label: ""
      },
      waybillDate: "",
      waybillNumber: "",
      waybillType: {
        value: "",
        label: ""
      },
      specificationDate: "",
      specificationNumber: "",
      products: [tableRowReq],
      count: "",
      amount: "",
      amountPDV: ""
    },
    validationSchema: Yup.object().shape({
      waybillNumber: Yup.string().required("Введіть номер договору"),
      waybillDate: Yup.date().required("Введіть дату договору"),
      waybillType: Yup.object().shape({
        value: Yup.string().required("Виберіть вантажоодержувача")
      }),
      specificationNumber: Yup.string().required("Введіть номер договору"),
      specificationDate: Yup.date().required("Введіть дату договору"),
      products: productValidationSchemaReq(Yup)
    }),
    enableReinitialize: true,
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    if (
      standardList &&
      standardList.length &&
      !formik.values["standard"].value
    ) {
      formik.setFieldValue("standard", standardList[0]);
    }
    if (sizeList && sizeList.length && !formik.values["size"].value) {
      formik.setFieldValue("size", sizeList[0]);
    }
  }, [formik.values["standard"].value, standardList, sizeList]);

  const handleSelectChange = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const handleWaybillTypeSelectChange = (values: any) => {
    formik.setFieldValue("waybillType", values);
  };

  const handleDateChange = useCallback(
    (paramName: string, date: Date) => {
      formik.setFieldValue(paramName, date);
    },
    [formik]
  );

  const handleLoadFiles =
    (docType) => (acceptedFiles, rejectedFiles, replaceFile) => {
      if (acceptedFiles)
        acceptedFiles.forEach((file) => {
          dispatch(
            features.subjectDocs.actions.uploadDocFileRequest({
              params: {
                basisDocumentUuid: documentId,
                replaceUuid: replaceFile?.uuid
              },
              fields: {
                fileType: docType,
                file: Object.assign(file, {
                  alias: docType
                })
              }
            })
          );
        });

      parseFilesErrors(rejectedFiles);
    };

  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  const onDeleteFile = async (file) => {
    await new Promise((resolve) => {
      dispatch(
        features.subjectDocs.actions.deleteDocFileRequest({
          params: {
            documentUuid: documentId,
            fileUuid: file.uuid
          },
          type: file.type,
          name: file.name,
          onSuccess: () => resolve(() => true),
          onError: () => resolve(() => true)
        })
      );

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  const backPath = useMemo(() => {
    const path = parentId
      ? `transition/search?parent=${parentId}`
      : "primary/search";
    return `/personal/applications/${applicationId}/documents/${path}`;
  }, [applicationId, parentId]);

  const onBack = useCallback(() => navigate(backPath), [backPath, navigate]);

  const searchTitle = useMemo(() => {
    return getSearchTitle(!parentId);
  }, [parentId]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Заявка на видачу сертифіката",
        to: `/personal/applications/${applicationId}`
      },
      {
        text: searchTitle,
        to: backPath
      },
      {
        text: `Створити документ ${document?.typeName}`,
        to: "/"
      }
    ],
    [applicationId, backPath, document, searchTitle]
  );

  useEffect(() => () => dispatch(features.subjectDocs.actions.clearDoc()), []);

  return (
    <ApplicationLayout className={styles["customs-declaration"]}>
      <Breadcrumbs elements={breadcrumbs} />
      <h1 className={styles["customs-declaration__title"]}>
        Створити документ
      </h1>
      {document && (
        <CreateDocForm
          initialValues={{
            docType: document.typeName,
            series: document.series,
            number: document.number,
            date: document.date,
            forestUser: {
              value: document.sourceOrganizationExternalId,
              label: document.sourceOrganizationName
            },
            counterparty: {
              value: document.recipientOrganizationExternalId,
              label: document.recipientOrganizationName
            },
            forestUserEdrpo: document.sourceOrganizationEdrpou,
            forestUserLocation: document.sourceOrganizationCountry,
            counterpartyEdrpo: document.recipientOrganizationEdrpou,
            counterpartyLocation: document.recipientOrganizationCountry
          }}
          loadedFiles={document.files.filter(
            (item) => item.type === BASIS_DOC_TYPE.MD_SCANCOPY
          )}
          onDeleteFile={onDeleteFile}
          onLoadFiles={handleLoadFiles(BASIS_DOC_TYPE.MD_SCANCOPY)}
          isPrimal={!parentId}
        />
      )}
      <FormikProvider value={formik}>
        <Form className={styles["customs-declaration__form"]}>
          <h2 className={styles["customs-declaration__form-title"]}>
            Завантажити CMR або ЗН
          </h2>
          <div className={styles["customs-declaration__subform"]}>
            <div className={styles["customs-declaration__subform-column"]}>
              <Select
                value={formik.values["waybillType"]}
                name="waybillType"
                onSelectChange={handleWaybillTypeSelectChange}
                placeholder="Тип"
                options={WAYBILL_TYPE_OPTIONS}
                withFormik
              />
              <DatePicker
                date={formik.values["waybillDate"]}
                onChange={(date) => handleDateChange("waybillDate", date)}
                name="waybillDate"
                placeholder="Дата"
                maxDate={new Date(document?.date)}
                withFormik
              />
            </div>
            <div
              className={cn(
                styles["customs-declaration__subform-column"],
                styles["customs-declaration__subform-column_one-element"]
              )}
            >
              <FormField name="waybillNumber" placeholder="Номер" />
            </div>
            <div
              className={cn(
                styles["customs-declaration__subform-column"],
                styles["customs-declaration__subform-column_loader"]
              )}
            >
              <VisualUploadMultipleFiles
                title="Завантажити скан-копію"
                accept={{
                  "application/pdf": [".pdf"],
                  "image/jpeg": [".jpg"]
                }}
                onDelete={onDeleteFile}
                onDocumentView={onDocumentView}
                onLoad={handleLoadFiles(BASIS_DOC_TYPE.MD_CMR_OR_ZN)}
                loadedFiles={document?.files?.filter(
                  (item) => item.type === BASIS_DOC_TYPE.MD_CMR_OR_ZN
                )}
              />
            </div>
          </div>
          <h2 className={styles["customs-declaration__form-title"]}>
            Завантажити специфікацію
          </h2>
          <div className={styles["customs-declaration__subform"]}>
            <div className={styles["customs-declaration__subform-column"]}>
              <DatePicker
                date={formik.values["specificationDate"]}
                onChange={(date) => handleDateChange("specificationDate", date)}
                name="specificationDate"
                placeholder="Дата"
                withFormik
                maxDate={new Date(document?.date)}
              />
              <FormField name="specificationNumber" placeholder="Номер" />
            </div>
            <div
              className={cn(
                styles["customs-declaration__subform-column"],
                styles["customs-declaration__subform-column_loader"]
              )}
            >
              <VisualUploadMultipleFiles
                title="Завантажити скан-копію"
                accept={{
                  "application/pdf": [".pdf"],
                  "image/jpeg": [".jpg"]
                }}
                onDelete={onDeleteFile}
                onDocumentView={onDocumentView}
                onLoad={handleLoadFiles(BASIS_DOC_TYPE.MD_SPECIFICATION)}
                loadedFiles={document?.files?.filter(
                  (item) => item.type === BASIS_DOC_TYPE.MD_SPECIFICATION
                )}
              />
            </div>
          </div>
          <div className={styles["customs-declaration__table-form"]}>
            <h2 className={styles["customs-declaration__form-title"]}>
              Заповнити продукцію
            </h2>
            <Notification
              text="Заповніть усі поля у відповідності до паперового документа. Після збереження документа ви зможете вибрати необхідну кількість використання до єСертифіката."
              className={styles["customs-declaration__table-form-reminder"]}
            />
            <div className={styles["customs-declaration__table-form-selects"]}>
              <Select
                name="size"
                value={formik.values["size"]}
                options={sizeList}
                placeholder="Геометричні розміри"
                onSelectChange={handleSelectChange}
              />
              <Select
                name="standard"
                value={formik.values["standard"]}
                options={standardList}
                placeholder="Стандарт"
                onSelectChange={handleSelectChange}
              />
            </div>
            <EditableProductsTable
              formik={formik}
              tableRow={tableRowReq}
              enabledColumns={[
                ...Object.keys(tableRowReq),
                "accounting",
                "actions"
              ]}
              withPDV
            />
            <FormControls
              acceptLabel="Зберегти"
              className={styles["customs-declaration__table-form-controls"]}
            />
          </div>
        </Form>
      </FormikProvider>
    </ApplicationLayout>
  );
};

export default _CustomsDeclarationPage;
