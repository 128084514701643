import {
  Cell,
  Header,
  HeaderGroup,
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import cn from "classnames";
import { useMemo } from "react";

import { calculateSum, formatNumber } from "helpers";


import styles from "./basic-docs-transfer.module.scss";

interface BasicDocsInterface {
  defaultData: any;
  woodCardsData: any;
  transferDocColumns: any;
  woodCardsColumns: any;
  isOfficial?: boolean;
  isReadonly?: boolean;
}

const BasicDocs = ({
  defaultData,
  woodCardsData,
  transferDocColumns,
  woodCardsColumns,

}: BasicDocsInterface): JSX.Element => {

  const tableWoodCards = useReactTable({
    data: woodCardsData,
    columns: woodCardsColumns,
    getCoreRowModel: getCoreRowModel()
  });
  const tableTransfer = useReactTable({
    data: defaultData,
    columns: transferDocColumns,
    getCoreRowModel: getCoreRowModel()
  });

  const totalWoodCardsQuantity = useMemo(() => {
    const total = calculateSum(woodCardsData.map((item) => item.takenQuantity));
    return formatNumber(total, 5);
  }, [woodCardsData]);
  // const totalTransferQuantity = useMemo(() => {
  //   const total = calculateSum(defaultData.map((item) => item.takenQuantity));
  //   return formatNumber(total, 5);
  // }, [defaultData]);

  return (
    <div className={styles["basic-docs-section"]}>
      <div className={styles["table-container"]}>
        <p>Лісорубні квитки</p>

        <div className={styles["table"]}>
          <table className={styles["table__table"]}>
            <thead>
              {tableWoodCards
                .getHeaderGroups()
                .map((headerGroup: HeaderGroup<any>) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header: Header<any, unknown>) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </th>
                    ))}
                  </tr>
                ))}
            </thead>
            <tbody>
              {tableWoodCards.getRowModel().rows.map((row: Row<any>) => (
                <tr key={row.id}>
                  {row
                    .getVisibleCells()
                    .map((cell: Cell<any, unknown>, index: number) => (
                      <td
                        key={cell.id}
                        className={cn({
                          [styles["table__doc-cell"]]:
                            tableWoodCards.options.columns[index].meta?.docCell,
                          [styles["table__button-cell"]]:
                            tableWoodCards.options.columns[index].meta
                              ?.buttonCell
                        })}
                      >
                        {tableWoodCards.options.columns[index].meta
                          ?.isOverflowEllipses ? (
                          <div className={styles["table__overflow-ellipses"]}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        ) : (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        )}
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* {defaultData.length > 0 && (
          <div className={styles["table-totals"]}>
            <p className={styles["table-totals__desc"]}>Всього</p>
            <p className={styles["table-totals__title"]}>Кількість</p>
            <p className={styles["table-totals__value"]}>
              {totalWoodCardsQuantity}
            </p>
          </div>
        )} */}
      </div>
      <div className={styles["table-container"]}>
        <p>Переміщення</p>
        <div className={styles["table"]}>
          <table className={styles["table__table"]}>
            <thead>
              {tableTransfer
                .getHeaderGroups()
                .map((headerGroup: HeaderGroup<any>) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header: Header<any, unknown>) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </th>
                    ))}
                  </tr>
                ))}
            </thead>
            <tbody>
              {tableTransfer.getRowModel().rows.map((row: Row<any>) => (
                <tr key={row.id}>
                  {row
                    .getVisibleCells()
                    .map((cell: Cell<any, unknown>, index: number) => (
                      <td
                        key={cell.id}
                        className={cn({
                          [styles["table__doc-cell"]]:
                            tableTransfer.options.columns[index].meta?.docCell,
                          [styles["table__button-cell"]]:
                            tableTransfer.options.columns[index].meta
                              ?.buttonCell
                        })}
                      >
                        {tableTransfer.options.columns[index].meta
                          ?.isOverflowEllipses ? (
                          <div className={styles["table__overflow-ellipses"]}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        ) : (
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        )}
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {defaultData.length > 0 && (
          <div
            className={styles["basic-docs-section-footer"]}
          >
            <div className={styles["table-totals"]}>
              <p className={styles["table-totals__desc"]}>
                Всього за первинними документами
              </p>
              <p className={styles["table-totals__title"]}>Кількість</p>
              <p className={styles["table-totals__value"]}>
                {totalWoodCardsQuantity}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicDocs;
