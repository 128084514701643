import cn from "classnames";
import {
  BlackEyeIcon,
  CommonButton,
  VisualUploadMultipleFiles
} from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PrimaryDocumentEnum, PrimaryDocumentTypes } from "constant";
import features from "features";
import { formatDate, getChainNumber, parseResErrors } from "helpers";
import getPreviewFiles from "helpers/previewFilesNaming";
import { IFile } from "interfaces";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import {
  BASIS_DOC_TYPE_NAME,
  BASIS_DOC_TYPE_UPLOAD_NAME,
  IDocChildren
} from "scenes/subject/documents/interfaces/interfaces";

import DocumentView from "components/DocumentView/DocumentView";
import ZSNDocView from "components/railDocsComponents/ZSNDocView/ZSNDocView";

import DodPDFIcon from "assets/images/icons/doc_preview.svg";
import PencilIcon from "assets/images/icons/pencil.svg";

import styles from "./document-for-use.module.scss";

interface sellerInterface {
  sellerName?: string;
  sellerExternalId?: string;
  sellerEdrpou?: string;
}
interface shopperInterface {
  shopperName?: string;
  shopperExternalId?: string;
  shopperEdrpou?: string;
}

interface DocumentForUseInterface {
  uuid?: string;
  documentId?: string;
  docImg?: string;
  loadedFiles?: Array<IFile>;
  additionalDocuments?: Array<any>;
  type?: string;
  typeName?: string;
  series?: string;
  number?: string;
  date?: string;
  seller?: sellerInterface;
  shopper?: shopperInterface;
  shopperExternalId?: string;
  initialCount?: number;
  availableCount?: number;
  wasteQuantity?: number;
  wasteQuantityAvailable?: number;
  wasteQuantityPercent?: number;
  isPrimal: boolean;
  zsnList?: Array<IDocChildren>;
  isCreatedMultiDoc?: boolean;
  isHiddenControls?: boolean;
  allowUpload?: boolean;
  onLoadFiles?: (
    acceptedFiles: any,
    rejectedFiles: any,
    replaceFile: any,
    docType?: string
  ) => void;
  onDeleteFile?: (file: any) => Promise<void>;
  className?: string;
  zsnComponentsList?: JSX.Element;
}

const DocumentForUse = ({
  uuid,
  documentId,
  docImg,
  loadedFiles,
  type,
  typeName,
  series,
  number,
  date,
  seller,
  shopper,
  wasteQuantity,
  wasteQuantityAvailable,
  wasteQuantityPercent,
  initialCount,
  availableCount,
  isPrimal,
  zsnList,
  isCreatedMultiDoc,
  additionalDocuments,
  isHiddenControls,
  allowUpload,
  onLoadFiles,
  onDeleteFile,
  className,
  zsnComponentsList
}: DocumentForUseInterface): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [resSellerEdrpou, setResSellerEdrpou] = useState(null);
  const [resShopperEdrpou, setResShopperEdrpou] = useState(null);

  const { applicationId, chain } = useParams<{
    applicationId?: string;
    chain?: string;
  }>();
  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const toProductUsagePage = useCallback(
    (isPrevious?: boolean) => {
      const parentId = parseInt(searchParams.get("parent"));
      const isEmpty = (() => {
        const isWasteSupported =
          type === PrimaryDocumentEnum.ttn_wood ||
          type === PrimaryDocumentEnum.ttn ||
          type === PrimaryDocumentEnum.transfer;
        const isEmptyUsage =
          isWasteSupported &&
          wasteQuantityAvailable <= 0 &&
          availableCount <= 0 &&
          wasteQuantity > 0;

        if (isEmptyUsage) return true;
        if (availableCount <= 0 && !isWasteSupported) return true;
      })();

      if (!isPrevious) {
        if (isEmpty) {
          return dispatch(
            features.modal.actions.showModal({
              modalType: "ERROR",
              modalProps: {
                title: "Помилка",
                description: "Неможливо використати, кількість для списання 0"
              }
            })
          );
        }
        if (!seller) {
          return dispatch(
            features.modal.actions.showModal({
              modalType: "ERROR",
              modalProps: {
                title: "Помилка",
                description: `Неможливо використати ${
                  type !== PrimaryDocumentEnum.zn
                    ? isPrimal
                      ? "Лісокористувач"
                      : "Продавець"
                    : "Лісокористувач \\ Продавець"
                }  відсутній в довіднику. Зверніться до адміністратора.`
              }
            })
          );
        }
        if (!shopper && typeName !== PrimaryDocumentTypes.transfer) {
          return dispatch(
            features.modal.actions.showModal({
              modalType: "ERROR",
              modalProps: {
                title: "Помилка",
                description: `Неможливо використати ${
                  typeName !== PrimaryDocumentTypes.zn
                    ? "Контрагент"
                    : "Контрагент \\ Покупець"
                } відсутній в довіднику. Зверніться до адміністратора.`
              }
            })
          );
        }
        if (!series && typeName === PrimaryDocumentTypes.ttn_wood) {
          return dispatch(
            features.modal.actions.showModal({
              modalType: "ERROR",
              modalProps: {
                title: "Помилка",
                description: `Неможливо використати серія не вказана. Зверніться до адміністратора.`
              }
            })
          );
        }
        if (!number) {
          return dispatch(
            features.modal.actions.showModal({
              modalType: "ERROR",
              modalProps: {
                title: "Помилка",
                description: `Неможливо використати номер не вказаний. Зверніться до адміністратора.`
              }
            })
          );
        }
      }

      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: "Опрацювання запиту",
            loading: true
          }
        })
      );

      dispatch(
        features.subjectDocs.actions.fetchDocRequest({
          params: {
            uuid: documentId ?? uuid,
            children: isCreatedMultiDoc ? null : zsnList
          },
          fields: {
            validateForApplication: applicationId,
            validateForParentId: parentId.toString(),
            validateForChainNumber: getChainNumber(application?.basisDocuments)
          },
          onSuccess: (res) => {
            const isPrimal = !parentId ? "primal" : "transition";
            const docIdByType =
              !isCreatedMultiDoc && zsnList ? res.uuid : documentId ?? uuid;
            let url = `/personal/applications/${applicationId}/documents/${
              chain || isPrimal
            }/${type}/${docIdByType}/${
              isPrevious ? "previous-usage" : "usage"
            }`;
            if (parentId) {
              url += `?parent=${parentId}`;
            }
            isPrevious ? window.open(url, "_blank") : navigate(url);
            dispatch(features.modal.actions.hideModal());
          },
          onError: (errorsRes) => {
            dispatch(features.modal.actions.hideModal());
            parseResErrors({ errorsRes });
          }
        })
      );
    },
    [
      searchParams,
      dispatch,
      documentId,
      uuid,
      isCreatedMultiDoc,
      zsnList,
      applicationId,
      application?.basisDocuments,
      availableCount,
      seller,
      shopper,
      typeName,
      series,
      number,
      type,
      isPrimal,
      chain,
      navigate
    ]
  );
  const onDocumentView = (file) => {
    window.open(file.filePath, "_blank");
  };

  const files = useMemo(
    () => getPreviewFiles(loadedFiles, additionalDocuments),
    [additionalDocuments, loadedFiles]
  );

  useEffect(() => {
    if (!seller.sellerEdrpou)
      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: seller.sellerExternalId
          },
          onSuccess: (res) => {
            setResSellerEdrpou(res.edrpou);
          }
        })
      );
  }, []);

  useEffect(() => {
    if (!shopper.shopperEdrpou)
      dispatch(
        features.application.actions.fetchSelectedOrganizationsListRequest({
          params: {
            externalId: shopper.shopperExternalId
          },
          onSuccess: (res) => {
            setResShopperEdrpou(res.edrpou);
          }
        })
      );
  }, []);

  return (
    <div className={cn(styles["document-for-use"], className)}>
      <section className={styles["document-for-use__description-container"]}>
        {loadedFiles?.length > 0 || allowUpload ? (
          <VisualUploadMultipleFiles
            loadedFiles={files.filter((file) => !file.isAdditionalData)}
            containerClassName={styles["document-for-use__description-files"]}
            title={"Завантажити скан-копію"}
            readonly={!allowUpload}
            accept={{
              "application/pdf": [".pdf"],
              "image/jpeg": [".jpg"]
            }}
            onDocumentView={onDocumentView}
            onDelete={onDeleteFile}
            onLoad={onLoadFiles}
          />
        ) : (
          <DocumentView
            docImg={docImg}
            className={styles["document-for-use__description-image"]}
          />
        )}
        <div className={styles["document-for-use__description-text-container"]}>
          <div className={styles["document-for-use__description-text-row"]}>
            {typeName && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  Тип
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  {typeName}
                </h4>
              </div>
            )}
            {series && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  Серія
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  {series}
                </h4>
              </div>
            )}
            {number && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  Номер
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  {number}
                </h4>
              </div>
            )}
            {date && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  Дата
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  {date}
                </h4>
              </div>
            )}
          </div>
          <div className={styles["document-for-use__description-text-row"]}>
            {seller?.sellerName && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  {type !== PrimaryDocumentTypes.zn
                    ? isPrimal
                      ? "Лісокористувач"
                      : "Продавець"
                    : "Лісокористувач \\ Продавець"}
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  <br />
                  {seller.sellerName}
                </h4>
              </div>
            )}
            {typeName !== PrimaryDocumentTypes.transfer && shopper.shopperName && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  {typeName !== PrimaryDocumentTypes.zn
                    ? "Контрагент"
                    : "Контрагент \\ Покупець"}
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  <br />
                  {shopper.shopperName}
                </h4>
              </div>
            )}
            {initialCount >= 0 && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  Основна продукція
                  <br />
                  Кількість оригінал
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  {initialCount}
                </h4>
              </div>
            )}
            {availableCount >= 0 && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  <br />
                  Кількість доступно
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  {availableCount}
                </h4>
              </div>
            )}
          </div>

          <div className={styles["document-for-use__description-text-row"]}>
            {(seller.sellerEdrpou ?? resSellerEdrpou) && (
              <div
                className={styles["document-for-use__description-text-item"]}
              >
                <h4 className={styles["document-for-use__description-label"]}>
                  Код за ЄДРПОУ
                </h4>
                <h4 className={styles["document-for-use__description-text"]}>
                  <br />
                  {seller.sellerEdrpou ?? resSellerEdrpou}
                </h4>
              </div>
            )}
            {(shopper.shopperEdrpou ?? resShopperEdrpou) &&
              typeName !== PrimaryDocumentTypes.transfer && (
                <div
                  className={styles["document-for-use__description-text-item"]}
                >
                  <h4 className={styles["document-for-use__description-label"]}>
                    Код за ЄДРПОУ
                  </h4>
                  <h4 className={styles["document-for-use__description-text"]}>
                    <br />
                    {shopper.shopperEdrpou ?? resShopperEdrpou}
                  </h4>
                </div>
              )}
            {(type === PrimaryDocumentEnum.ttn_wood ||
              type === PrimaryDocumentEnum.ttn ||
              type === PrimaryDocumentEnum.transfer) && (
              <>
                {wasteQuantity > 0 && (
                  <div
                    className={
                      styles["document-for-use__description-text-item"]
                    }
                  >
                    <h4
                      className={styles["document-for-use__description-label"]}
                    >
                      Зворотні відходи
                      <br />
                      Кількість оригінал
                    </h4>
                    <h4
                      className={styles["document-for-use__description-text"]}
                    >
                      {wasteQuantity} ({wasteQuantityPercent}%)
                    </h4>
                  </div>
                )}
                {wasteQuantityAvailable >= 0 && wasteQuantity > 0 && (
                  <div
                    className={
                      styles["document-for-use__description-text-item"]
                    }
                  >
                    <h4
                      className={styles["document-for-use__description-label"]}
                    >
                      <br />
                      Кількість доступно
                    </h4>
                    <h4
                      className={styles["document-for-use__description-text"]}
                    >
                      {wasteQuantityAvailable}
                    </h4>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
      {additionalDocuments && (
        <section className={styles["additional"]}>
          {additionalDocuments.map((item, index) => {
            const additionalFiles = files.filter(
              (file) => file.type === item.typeName
            );
            return (
              <div
                className={styles["organization-container"]}
                key={"organization-container" + index}
              >
                {additionalFiles.length > 0 || allowUpload ? (
                  <VisualUploadMultipleFiles
                    title={BASIS_DOC_TYPE_UPLOAD_NAME[item.typeName]}
                    loadedFiles={files.filter(
                      (file) => file.type === item.typeName
                    )}
                    readonly={!allowUpload}
                    accept={{
                      "application/pdf": [".pdf"],
                      "image/jpeg": [".jpg"]
                    }}
                    onDelete={onDeleteFile}
                    onDocumentView={onDocumentView}
                    onLoad={(...args) => onLoadFiles(...args, item.typeName)}
                  />
                ) : (
                  <DocumentView
                    docImg={docImg}
                    className={styles["document-for-use__description-image"]}
                  />
                )}
                <div className={styles["additional-text"]}>
                  {item.typeName && (
                    <div
                      className={
                        styles["document-for-use__description-text-item"]
                      }
                    >
                      <h4
                        className={
                          styles["document-for-use__description-label"]
                        }
                      >
                        Тип
                      </h4>
                      <h4
                        className={styles["document-for-use__description-text"]}
                      >
                        {item.waybillType?.toUpperCase() ??
                          BASIS_DOC_TYPE_NAME[item.typeName]}
                      </h4>
                    </div>
                  )}
                  {item.number && (
                    <div
                      className={
                        styles["document-for-use__description-text-item"]
                      }
                    >
                      <h4
                        className={
                          styles["document-for-use__description-label"]
                        }
                      >
                        Номер
                      </h4>
                      <h4
                        className={styles["document-for-use__description-text"]}
                      >
                        {item.number}
                      </h4>
                    </div>
                  )}
                  {item.date && (
                    <div
                      className={
                        styles["document-for-use__description-text-item"]
                      }
                    >
                      <h4
                        className={
                          styles["document-for-use__description-label"]
                        }
                      >
                        Дата
                      </h4>
                      <h4
                        className={styles["document-for-use__description-text"]}
                      >
                        {formatDate(item.date).date}
                      </h4>
                    </div>
                  )}
                  {item.stateAuthorityName && (
                    <div
                      className={
                        styles["document-for-use__description-text-item"]
                      }
                    >
                      <h4
                        className={
                          styles["document-for-use__description-label"]
                        }
                      >
                        Компетентний орган
                      </h4>
                      <h4
                        className={styles["document-for-use__description-text"]}
                      >
                        {item.stateAuthorityName}
                      </h4>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </section>
      )}
      {type === "zn" && (
        <section className={styles["document-for-use__railray-bill-container"]}>
          {zsnComponentsList ??
            zsnList?.map((item, index) => (
              <ZSNDocView
                key={index}
                image={DodPDFIcon}
                files={item.files}
                type={item.typeName}
                wagon={item.freightCarNumber}
                serial={item.series}
                number={item.number}
                date={formatDate(item.date).date}
                selectable
                isEOD={!(item?.files?.length > 0)}
                customFields={[
                  {
                    title: "Кількість оригінал",
                    text: item?.quantity ?? item?.quantityOrigin
                  },
                  {
                    title: "Кількість доступно",
                    text: item.quantityAvailable
                  }
                ]}
              />
            ))}
        </section>
      )}
      {!isHiddenControls && (
        <section className={styles["document-for-use__controls-container"]}>
          <CommonButton
            label="Попереднє використання"
            outlined
            image={<BlackEyeIcon />}
            onClick={() => toProductUsagePage(true)}
          />
          <CommonButton
            label="Використати"
            image={PencilIcon}
            onClick={() => toProductUsagePage()}
          />
        </section>
      )}
    </div>
  );
};

export default DocumentForUse;
