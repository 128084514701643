import cn from "classnames";
import { CommonButton, LongArrowIcon } from "gov-ua-ui";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getDocTypeAlias } from "constant";
import features from "features";
import { formatDate, generateColumnData, productResParser } from "helpers";
import { IRootState } from "reducer";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";

import DocumentForUse from "components/certificateComponents/viewCertificateComponents/DocumentForUse/DocumentForUse";
import ApplicationLayout from "components/layouts/ApplicationLayout/ApplicationLayout";
import ZSNDocView from "components/railDocsComponents/ZSNDocView/ZSNDocView";
import CommonTable from "components/tables/CommonTable/CommonTable";

import DodPDFIcon from "assets/images/icons/doc_preview.svg";
import DocIcon from "assets/images/icons/e_doc.svg";

import styles from "./specify-previous-zn-usage-layout.module.scss";

interface SpecifyPreviousZNUsageLayoutInterface {
  data?: any;
  docId?: string;
  docType?: string;
  previousDocuments?: Array<any>;
  onDocumentChange?: (document: any) => void;
  documentIndex?: number;
  currentUsedDocument: any;
}

const SpecifyPreviousZNUsageLayout = ({
  data,
  previousDocuments,
  onDocumentChange,
  documentIndex,
  currentUsedDocument,
  docType
}: SpecifyPreviousZNUsageLayoutInterface) => {
  const dispatch = useDispatch();

  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );

  const columns = useMemo(() => {
    const columnsData = generateColumnData({ isPrevious: true });

    return columnsData;
  }, [application, docType]);

  const footerData = (data?: any) =>
    [
      {
        name: "usedInCurrentCertificate",
        label: "Використано в сертифікаті",
        value: data
          ? data?.totalQuantityInCertificate
          : currentUsedDocument?.totalQuantityInCertificate
      },
      {
        name: "usedForAllCertificates",
        label: "Загалом використано по всіх сертифікатах",
        value: data
          ? data?.totalQuantityInAllCertificate
          : currentUsedDocument?.totalQuantityInAllCertificate
      },
      {
        name: "totalVolumeInDocument",
        label: "Загальний обсяг в документі",
        value: data
          ? data?.totalQuantityInBasisDocument
          : currentUsedDocument?.totalQuantityInAllCertificate
      }
    ] as Array<{
      name: string;
      label: string;
      value?: string | number;
    }>;

  useEffect(
    () => () => dispatch(features.previousUsage.actions.clearDocumentData()),
    []
  );

  const previousTitle = useMemo(() => {
    let title = `Документ-підстава до сертифікату/заявки: ${currentUsedDocument.status} `;

    title = title + `${currentUsedDocument.number} `;

    title =
      title +
      `${
        currentUsedDocument.date
          ? ` від ${formatDate(currentUsedDocument.date).date}`
          : ""
      }`;

    return title;
  }, [currentUsedDocument]);

  return (
    <ApplicationLayout className={styles["specify-usage"]}>
      <h1 className={styles["specify-usage__title"]}>Попереднє використання</h1>
      <div className={styles["certificates-navigations-controls-container"]}>
        <CommonButton
          round
          outlined
          image={<LongArrowIcon />}
          large={false}
          className={cn(
            styles["navigation-button"],
            styles["navigation-button__first"]
          )}
          onClick={() => onDocumentChange(previousDocuments[0])}
        />
        <CommonButton
          round
          outlined
          image={<LongArrowIcon />}
          large={false}
          className={cn(
            styles["navigation-button"],
            styles["navigation-button__previous"]
          )}
          onClick={() =>
            documentIndex !== 0 &&
            onDocumentChange(previousDocuments[documentIndex - 1])
          }
        />
        <CommonButton
          round
          outlined
          image={<LongArrowIcon />}
          large={false}
          className={cn(
            styles["navigation-button"],
            styles["navigation-button__next"]
          )}
          onClick={() =>
            documentIndex !== previousDocuments.length - 1 &&
            onDocumentChange(previousDocuments[documentIndex + 1])
          }
        />
        <CommonButton
          round
          outlined
          image={<LongArrowIcon />}
          large={false}
          className={cn(
            styles["navigation-button"],
            styles["navigation-button__last"]
          )}
          onClick={() =>
            onDocumentChange(previousDocuments[previousDocuments.length - 1])
          }
        />
      </div>
      <p className={styles["certificates-numbering"]}>
        Запис <span>{documentIndex + 1}</span> з{" "}
        <span>{previousDocuments.length}</span>
      </p>
      <h4 className={styles["dp-for-certificate"]}>{previousTitle}</h4>
      {/* TODO delete */}
      {/* {data && (
        <TransitionDocSearchResult
          loadedFiles={data?.files}
          image={DodPDFIcon}
          type={data.typeName}
          number={data.number}
          date={formatDate(data.date).date}
          sellerTitle="Лісокористувач-продавець"
          sellerText={data.sourceOrganizationName}
          availableCountTitle="Кількість доступно"
          availableCountText={data.quantityAvailable}
          className={styles["specify-usage__doc"]}
          zsnList={data.children.map((item, index) => (
            <ZSNDocView
              key={index}
              image={DodPDFIcon}
              type={item.typeName}
              wagon={item.freightCarNumber}
              serial={item.series}
              number={item.number}
              date={formatDate(item.date).date}
              files={item.files}
              selectable
              customFields={[
                {
                  title: "Кількість оригінал",
                  text: item.quantityOrigin
                },
                {
                  title: "Кількість доступно",
                  text: item.quantityAvailable
                }
              ]}
            />
          ))}
        />
      )} */}
      {data && (
        <DocumentForUse
          className={styles["specify-usage__doc"]}
          loadedFiles={data.files}
          additionalDocuments={data.additionalDocuments}
          docImg={DocIcon}
          type={getDocTypeAlias(data.typeName)}
          typeName={data.typeName}
          series={data.series}
          number={data.number}
          date={formatDate(data.date).date}
          seller={{
            sellerName: data.sourceOrganizationName,
            sellerExternalId: data.sourceOrganizationExternalId,
            sellerEdrpou: data.sourceOrganizationEdrpou
          }}
          shopper={{
            shopperName: data.recipientOrganizationName,
            shopperExternalId: data.recipientOrganizationExternalId,
            shopperEdrpou: data.recipientOrganizationEdrpou
          }}
          isPrimal={false}
          documentId={data.externalId}
          uuid={data.uuid}
          initialCount={data.quantityOrigin}
          availableCount={data.quantityAvailable}
          wasteQuantity={data.wasteQuantity}
          wasteQuantityAvailable={data.wasteQuantityAvailable}
          wasteQuantityPercent={data.wasteQuantityPercent}
          zsnComponentsList={data.children.map((item, index) => (
            <ZSNDocView
              key={index}
              image={DodPDFIcon}
              type={item.typeName}
              wagon={item.freightCarNumber}
              serial={item.series}
              number={item.number}
              date={formatDate(item.date).date}
              isEOD={data.byUnion}
              files={item.files}
              selectable
              customFields={[
                {
                  title: "Кількість оригінал",
                  text: item.quantityOrigin
                },
                {
                  title: "Кількість доступно",
                  text: item.quantityAvailable
                }
              ]}
            />
          ))}
          isHiddenControls
        />
      )}
      <div>
        {currentUsedDocument?.children ? (
          currentUsedDocument?.children.map((el) => {
            return (
              <div className={styles["specify-usage__table-form"]}>
                <CommonTable
                  tableType="SIMPLE"
                  defaultData={productResParser(
                    el?.usedProducts,
                    docType,
                    true
                  )}
                  columns={columns}
                  footerTitle="Всього"
                  footer={footerData(el)}
                />
              </div>
            );
          })
        ) : (
          <div className={styles["specify-usage__table-form"]}>
            <CommonTable
              tableType="SIMPLE"
              defaultData={productResParser(
                currentUsedDocument?.usedProducts,
                docType,
                true
              )}
              columns={columns}
              footerTitle="Всього"
              footer={footerData(currentUsedDocument)}
            />
          </div>
        )}
        <div
          className={cn(
            styles["total-zn-used__container"],
            styles["table-footer"]
          )}
        >
          <h5 className={styles["table-footer__title"]}>Всього по ЗН</h5>
          <div className={styles["table-footer__info"]}>
            {footerData(currentUsedDocument).map((element) => {
              return (
                <div
                  className={styles["table-footer__info-element"]}
                  key={element.name}
                >
                  <p>{element.label}</p>
                  <p>{element.value}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default SpecifyPreviousZNUsageLayout;
