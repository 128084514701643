import { Form, FormikProvider, useFormik } from "formik";
import { Checkbox, CommonButton, Notification, Select } from "gov-ua-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import features from "features";
import {
  getSearchTitle,
  parseResErrors,
  productValidationSchemaReq,
  productsReqParser,
  tableRowReq
} from "helpers";
import { parseFilesErrors } from "helpers/parseFilesErrors";
import { toastr } from "react-redux-toastr";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";
import { ISubjectDocs } from "../../ducks";
import { BASIS_DOC_TYPE } from "../../interfaces/interfaces";

import AddDocumentForm from "components/AddDocumentForm/AddDocumentForm";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import CreateDocForm from "components/CreateDocForm/CreateDocForm";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";
import EditableProductsTable from "components/certificateComponents/createCertificateComponents/DefineProducts/EditableProductsTable";
import ApplicationLayout from "components/layouts/ApplicationLayout/ApplicationLayout";

import styles from "./ttn-doc-page.module.scss";

interface FormValues {
  warrantNumber: string;
  warrantDate: string;
  warrantStateAuthority: {
    value: string;
    label: string;
  };
  certificateNumber: string;
  certificateDate: string;
  certificateStateAuthority: {
    value: string;
    label: string;
  };
  isAddWarrant: boolean;
  standard: {
    value: string;
    label: string;
  };
  size: {
    value: string;
    label: string;
  };
  products: any[];
  count: string;
  amount: string;
  amountPDV: string;
  recipientOrganizationExternalId: string;
}

interface CreateDocumentPageInterface {}

const _CreateDocumentPage = ({}: CreateDocumentPageInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentId = parseInt(searchParams.get("parent"));

  const { applicationId, documentId } = useParams();

  const { document } = useSelector<IRootState, ISubjectDocs>(
    (state) => state.subjectDocs
  );

  const { sizeList, standardList } = useSelector<IRootState, IDatasetState>(
    (state) => state.dataset
  );

  const formInitialValues: FormValues = {
    recipientOrganizationExternalId: document?.recipientOrganizationExternalId,
    warrantNumber: "",
    warrantDate: "",
    warrantStateAuthority: {
      value: "",
      label: ""
    },
    certificateNumber: "",
    certificateDate: "",
    certificateStateAuthority: {
      value: "",
      label: ""
    },
    isAddWarrant: false,
    standard: {
      value: "",
      label: ""
    },
    size: {
      value: "",
      label: ""
    },
    products: [tableRowReq],
    count: "",
    amount: "",
    amountPDV: ""
  };

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.application.actions.fetchApplicationRequest({
          params: { uuid: applicationId }
        })
      );
    }

    if (documentId) {
      dispatch(
        features.subjectDocs.actions.fetchDocRequest({
          params: {
            uuid: documentId
          },
          fields: {
            validateForApplication: applicationId,
            validateForParentId: parentId.toString()
          },
          onError: (errorsRes) => {
            onBack();
            parseResErrors({ errorsRes });
          }
        })
      );
    }

    return () => {
      dispatch(features.application.actions.clearApplication());
    };
  }, []);

  const handleFormSubmit = (values, { setFieldError }) => {
    if (
      !document.files.find(
        (file) => file.type === BASIS_DOC_TYPE.TTN_SCANCOPY
      ) ||
      document.files.find(
        (file) => file.progress !== 100 && file.progress !== undefined
      )
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }
    if (
      formik.values["isAddWarrant"] &&
      (!document.files.find(
        (file) => file.type === BASIS_DOC_TYPE.TTN_CERTIFICATE
      ) ||
        !document.files.find(
          (file) => file.type === BASIS_DOC_TYPE.TTN_WARRANT
        ))
    ) {
      toastr.error("Помилка", "Завантажте всі файли");
      return;
    }
    const {
      warrantNumber,
      warrantDate,
      warrantStateAuthority,
      certificateNumber,
      certificateDate,
      certificateStateAuthority,
      standard,
      isAddWarrant,
      size,
      recipientOrganizationExternalId
    } = values;
    const geometricDimensionUuid = size.value;

    const products = productsReqParser(values.products, {
      productStandardUuid: standard.value,
      geometricDimensionUuid
    });

    const warrantFields = {
      warrantNumber,
      warrantDate: warrantDate,
      warrantStateAuthority: warrantStateAuthority.value?.toString(),
      certificateNumber,
      certificateDate: certificateDate,
      certificateStateAuthority: certificateStateAuthority.value?.toString()
    };

    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Ви впевненні, що внесли повну інформацію по документу у відповідності до паперового примірника? Документ буде збережено у єдиній базі документів і подальше його редагування буде неможливе! Документ буде доступний тільки у режимі використання!",
          acceptLabel: "Все вірно, продовжити",
          onAccept: () =>
            dispatch(
              features.subjectDocs.actions.updateDocRequest({
                params: {
                  basisDocumentType: "ttn",
                  basisDocumentUuid: documentId
                },
                fields: {
                  recipientOrganizationExternalId,
                  products,
                  ...(isAddWarrant && warrantFields)
                },
                onSuccess: () => {
                  navigate(`success?parent=${parentId}`);
                },
                onError: (e) => {
                  parseResErrors({
                    setFieldError: setFieldError,
                    errorsRes: e,
                    fields: values,
                    fieldsAliases: {
                      quantityMeasurementUnitUuid: "unit",
                      productExternalId: "productType",
                      woodSpecieUuids: "wood",
                      woodGradeUuids: "sort",
                      accountingUuid: "accounting"
                    }
                  });
                }
              })
            )
        }
      })
    );
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: formInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      warrantNumber: Yup.string().when(
        "isAddWarrant",
        ([isAddWarrant], schema) => {
          return isAddWarrant
            ? schema.required("Заповніть значення")
            : schema.notRequired();
        }
      ),
      warrantDate: Yup.string().when(
        "isAddWarrant",
        ([isAddWarrant], schema) => {
          return isAddWarrant
            ? schema.required("Заповніть значення")
            : schema.notRequired();
        }
      ),
      warrantStateAuthority: Yup.object().shape({
        value: Yup.string().when("isAddWarrant", ([], schema, context) => {
          let isAddWarrantField = false;
          if (context["from"]) {
            isAddWarrantField = context["from"][1].value.isAddWarrant;
          }

          return isAddWarrantField
            ? schema.required("Заповніть значення")
            : schema.notRequired();
        })
      }),
      certificateNumber: Yup.string().when(
        "isAddWarrant",
        ([isAddWarrant], schema) => {
          return isAddWarrant
            ? schema.required("Заповніть значення")
            : schema.notRequired();
        }
      ),
      certificateDate: Yup.string().when(
        "isAddWarrant",
        ([isAddWarrant], schema) => {
          return isAddWarrant
            ? schema.required("Заповніть значення")
            : schema.notRequired();
        }
      ),
      certificateStateAuthority: Yup.object().shape({
        value: Yup.string().when("isAddWarrant", ([], schema, context) => {
          let isAddWarrantField = false;
          if (context["from"]) {
            isAddWarrantField = context["from"][1].value.isAddWarrant;
          }

          return isAddWarrantField
            ? schema.required("Заповніть значення")
            : schema.notRequired();
        })
      }),
      products: productValidationSchemaReq(Yup)
    }),
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    if (
      standardList &&
      standardList.length &&
      !formik.values["standard"].value
    ) {
      formik.setFieldValue("standard", standardList[0]);
    }
    if (sizeList && sizeList.length && !formik.values["size"].value) {
      formik.setFieldValue("size", sizeList[0]);
    }
  }, [formik.values["standard"].value, standardList, sizeList]);

  const handleSelectChange = (value, name) => {
    if (
      name === "standard" &&
      value.value !== formik.values["standard"].value
    ) {
      const isNeedConfirm = formik.values["products"].find((product) => {
        if (
          product["productType"]?.value &&
          product["productType"]?.label?.length
        )
          return true;
        if (product["wood"].length) return true;
        if (product["sort"].length) return true;
        if (product["uktzed"].length) return true;

        return false;
      });
      if (isNeedConfirm) handleChangeStandardClick(value);
      else formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const handleLoadFiles =
    (docType) => (acceptedFiles, rejectedFiles, replaceFile) => {
      if (acceptedFiles)
        acceptedFiles.forEach((file) => {
          dispatch(
            features.subjectDocs.actions.uploadDocFileRequest({
              params: {
                basisDocumentUuid: documentId,
                replaceUuid: replaceFile?.uuid
              },
              fields: {
                fileType: docType,
                file: Object.assign(file, {
                  alias: docType
                })
              }
            })
          );
        });

      parseFilesErrors(rejectedFiles);
    };

  const onDeleteFile = async (file) => {
    await new Promise((resolve) => {
      dispatch(
        features.subjectDocs.actions.deleteDocFileRequest({
          params: {
            documentUuid: documentId,
            fileUuid: file.uuid
          },
          type: file.type,
          name: file.name,
          onSuccess: () => resolve(() => true),
          onError: () => resolve(() => true)
        })
      );

      if (file.cancel) {
        file.cancel();
      }
    });
  };

  const handleChangeStandardClick = (value) => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          notificationText:
            "Якщо ви змінете стандарт продукції, всі заповнені поля в стовпчиках продукція, порода, сорт та УКТЗЕД видаляться. Ці поля потрібно буде заповнити повторно. Все одно змінити?",
          acceptLabel: "Так, продовжити",
          onAccept: () => {
            const updatedProducts = formik.values["products"].map((product) => {
              return {
                ...product,
                productType: { label: "", value: "" },
                wood: [],
                sort: [],
                uktzed: ""
              };
            });
            formik.setFieldValue("products", updatedProducts);
            formik.setFieldValue("standard", value);
          }
        }
      })
    );
  };

  const backPath = useMemo(() => {
    const path = parentId
      ? `transition/search?parent=${parentId}`
      : "primary/search";
    return `/personal/applications/${applicationId}/documents/${path}`;
  }, [applicationId, parentId]);

  const onBack = useCallback(() => navigate(backPath), [backPath, navigate]);

  const searchTitle = useMemo(() => {
    return getSearchTitle(!parentId);
  }, [parentId]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Заявка на видачу сертифіката",
        to: `/personal/applications/${applicationId}`
      },
      {
        text: searchTitle,
        to: backPath
      },
      {
        text: `Створити документ ${document?.typeName}`,
        to: "/"
      }
    ],
    [applicationId, backPath, document, searchTitle]
  );

  useEffect(() => () => dispatch(features.subjectDocs.actions.clearDoc()), []);

  return (
    <ApplicationLayout className={styles["create-document-page"]}>
      <Breadcrumbs elements={breadcrumbs} />
      <h1 className={styles["create-document-page__title"]}>
        Створити документ
      </h1>
      {document && (
        <CreateDocForm
          initialValues={{
            docType: document.typeName,
            series: document.series,
            number: document.number,
            date: document.date,
            forestUser: {
              value: document.sourceOrganizationExternalId,
              label: document.sourceOrganizationName
            },
            counterparty: {
              value: document.recipientOrganizationExternalId,
              label: document.recipientOrganizationName
            },
            forestUserEdrpo: document.sourceOrganizationEdrpou,
            forestUserLocation: document.sourceOrganizationCountry,
            counterpartyEdrpo: document.recipientOrganizationEdrpou,
            counterpartyLocation: document.recipientOrganizationCountry
          }}
          loadedFiles={document.files.filter(
            (item) => item.type === BASIS_DOC_TYPE.TTN_SCANCOPY
          )}
          onDeleteFile={onDeleteFile}
          onLoadFiles={handleLoadFiles(BASIS_DOC_TYPE.TTN_SCANCOPY)}
          isPrimal={!parentId}
        />
      )}

      <FormikProvider value={formik}>
        <Form className={styles["create-document-page__table-form"]}>
          {!parentId && (
            <div className={styles["create-document-page__checkbox-wrapper"]}>
              <Checkbox
                name={"isAddWarrant"}
                title={
                  "Додати ордер на видалення зелених насаджень та акт обстеження зелених насаджень, для лісоматеріалів, які заготовлені у населених пунктах шляхом видалення дерев та кущів"
                }
                checked={formik.values["isAddWarrant"]}
              />
            </div>
          )}

          {formik.values["isAddWarrant"] && (
            <>
              <div className={styles["add-document-form-wrapper"]}>
                <AddDocumentForm
                  formik={formik}
                  fieldsPrefix="warrant"
                  title="Додати ордер видалення зелених насаджень"
                  docNumberLabel="Номер ордеру"
                  docDateLabel="Дата ордеру"
                  stateAuthorityLabel="Компетентний орган, що видав ордер"
                  uploadTitle="Завантажити Ордер"
                  onLoadFiles={handleLoadFiles(BASIS_DOC_TYPE.TTN_WARRANT)}
                  loadedFiles={document.files.filter(
                    (item) => item.type === "ttn_removal_greenery_warrant"
                  )}
                  onDeleteFile={onDeleteFile}
                  searchParams="type=forestuser"
                  maxDate={new Date(document?.date)}
                />

                <AddDocumentForm
                  formik={formik}
                  fieldsPrefix="certificate"
                  title="Додати акт видалення зелених насаджень"
                  docNumberLabel="Номер акту"
                  docDateLabel="Дата акту"
                  stateAuthorityLabel="Компетентний орган, що видав акт"
                  uploadTitle="Завантажити Акт"
                  onLoadFiles={handleLoadFiles(BASIS_DOC_TYPE.TTN_CERTIFICATE)}
                  loadedFiles={document.files.filter(
                    (item) =>
                      item.type === "ttn_green_spaces_inspection_certificate"
                  )}
                  onDeleteFile={onDeleteFile}
                  searchParams="type=forestuser"
                  maxDate={new Date(document?.date)}
                />
              </div>
            </>
          )}

          <h2 className={styles["create-document-page__table-form-title"]}>
            Заповнити продукцію
          </h2>

          <Notification
            text="Заповніть усі поля у відповідності до паперового документа. Після збереження документа ви зможете вибрати необхідну кількість використання до єСертифіката."
            className={styles["create-document-page__table-form-reminder"]}
          />

          <div className={styles["create-document-page__table-form-selects"]}>
            <Select
              name="size"
              value={formik.values["size"]}
              options={sizeList}
              placeholder="Геометричні розміри"
              onSelectChange={handleSelectChange}
            />
            <Select
              name="standard"
              value={formik.values["standard"]}
              options={standardList}
              placeholder="Стандарт"
              onSelectChange={handleSelectChange}
              blurInputOnSelect={true}
            />
          </div>

          <div className={styles["create-document-page__table-form-table"]}>
            <EditableProductsTable
              formik={formik}
              tableRow={tableRowReq}
              enabledColumns={[
                ...Object.keys(tableRowReq),
                "accounting",
                "actions"
              ]}
              withPDV
            />
          </div>

          <div className={styles["create-document-page__table-form-controls"]}>
            <CommonButton label="Назад" outlined={true} onClick={onBack} />
            <CommonButton label="Зберегти" type="submit" />
          </div>
        </Form>
        <ScrollToFieldError />
      </FormikProvider>
    </ApplicationLayout>
  );
};

export default _CreateDocumentPage;
