import { createColumnHelper } from "@tanstack/react-table";
import Checkbox from "components/inputs/Checkbox/Checkbox";
import { sum, uniq } from "lodash";
import {
  addNumbers,
  formatDate,
  formatNumber,
  getSelectHandlerByField
} from ".";
import { PDV_PERCENT } from "../constant";

export interface TableData {
  id: string;
  production: string;
  woodCard?: Object;
  clarification: string;
  breed: string;
  grade: string;
  diameter: number;
  diametersGroup: number;
  decommissioned_group: string;
  thickness: number;
  width: number;
  length: number;
  original: number;
  available: number;
  decommissioned: number;
  amount?: number;
  unit: string;
  price: number;
  cost: number;
  uktzed: string;
  accounting: string;
  totalAvailable: string;
  totalDecommissioned: string;
  totalSum: string;
  totalSumPDV: string;
  multiGroupe: string;
  quantity: string;
}

export const calculateTotals = (products, pdvPercent = PDV_PERCENT) => {
  let amount = 0;
  let amountPDV = 0;
  let quantity = 0;
  let available = 0;
  let decommissioned = 0;
  let original = 0;

  products.forEach((item) => {
    if (item.amount) amount = addNumbers(amount, formatNumber(item.amount));
    if (item.count) quantity = addNumbers(quantity, formatNumber(item.count));
    if (item.available)
      available = addNumbers(available, formatNumber(item.available));
    if (item.decommissioned)
      decommissioned = addNumbers(
        decommissioned,
        formatNumber(item.decommissioned)
      );
    if (item.original)
      original = addNumbers(original, formatNumber(item.original));
  }, []);

  amountPDV = amount * pdvPercent;

  return {
    amount: formatNumber(amount, 2, true),
    quantity: formatNumber(quantity, 5),
    amountPDV: formatNumber(amountPDV, 2, true),
    available: formatNumber(available, 5),
    decommissioned: formatNumber(decommissioned, 5),
    original: formatNumber(original, 5)
  };
};

interface productsReqParserPropsInterface {
  geometricDimensionUuid: string;
  productStandardUuid: string;
  isWaste?: boolean;
}
export const productsReqParser = (
  products,
  {
    geometricDimensionUuid,
    productStandardUuid,
    isWaste
  }: productsReqParserPropsInterface
) => {
  return products.map((item) => {
    const product = {
      productExternalId: item.productType.value,
      productName: item.productType.label,
      note: item.clarification || null,
      woodSpecieUuids: item.wood.map((item) => ({ uuid: item.value })),
      woodGradeUuids: item.sort.map((item) => ({ uuid: item.value })),
      diameter: item.diameter ? item.diameter.toString() : null,
      diameterGroup: item.diameterGroup ? item.diameterGroup.toString() : null,
      height: item.thickness ? item.thickness.toString() : null,
      width: item.width ? item.width.toString() : null,
      length: item.length ? item.length.toString() : null,
      quantity: formatNumber(item.count) || formatNumber(item.quantity),
      quantityMeasurementUnitUuid: item.unit.value,
      price: formatNumber(item.price),
      totalPrice: formatNumber(item.amount),
      uktzed: item.uktzed,
      accountingUuid:
        item?.accounting?.value?.length > 0 ? item.accounting.value : null,
      productStandardUuid,
      geometricDimensionUuid,
      isWaste
    };

    return product;
  });
};

export const productResParser = (products, docType?, isRead?) => {
  return products.map((item, i) => {
    const productRow = {
      id: i,
      uuid: item.uuid,
      production: item.productName,
      ...(docType === "transfer" ? { woodCard: item.woodCard ?? null } : {}),
      clarification: item.note || "",
      breed: item.woodSpecie,
      grade: item.woodGrade,
      diameter: item.diameter !== "0" ? item.diameter : "",
      diametersGroup: item.diameterGroup ?? "",
      thickness:
        item.depth && item.depth !== "0"
          ? item.depth
          : item.height !== "0" && item.height
          ? item.height
          : "",
      width: item.width !== "0" && item.width ? item.width : "",
      length: item.length !== "0" ? item.length : "",
      original: item.quantityOrigin,
      available: item.quantityAvailable,
      decommissioned: isRead
        ? item.documentUsedQuantity !== 0
          ? item.documentUsedQuantity
          : ""
        : 0,
      unit: item.quantityMeasurementUnitName,
      price: item.price,
      cost: item.totalPrice,
      uktzed: item.uktzed,
      accounting: item.accounting,
      amount: 0,
      quantity: item.quantity
    };
    return {
      ...productRow,
      multiGroupe:
        productRow.production +
        productRow.clarification +
        productRow.breed +
        productRow.grade +
        productRow.diametersGroup +
        productRow.width +
        productRow.thickness +
        productRow.length
    };
  });
};

export const tableRowReq = {
  select: "",
  productType: {
    value: "",
    label: ""
  },
  clarification: "",
  wood: [],
  sort: [],
  diameter: "",
  diameterGroup: "",
  thickness: "",
  width: "",
  length: "",
  count: "",
  unit: {
    value: "",
    label: ""
  },
  price: "",
  amount: "",
  accounting: {
    value: "",
    label: ""
  },
  uktzed: ""
};

export const productValidationSchemaReq = (
  Yup,
  checkStandard?,
  checkPrice = true
) => {
  const validateProductType = () =>
    Yup.object().shape({
      value: Yup.string().required("Виберіть продукцію")
    });
  const validateWood = () =>
    Yup.array()
      .min(1, "Виберіть породу")
      .of(
        Yup.object().shape({
          value: Yup.string().required("Виберіть породу")
        })
      );

  return Yup.array().of(
    Yup.object().shape({
      ...(checkStandard
        ? {
            standard: Yup.object().shape({
              value: Yup.string().required("Виберіть стандарт")
            })
          }
        : {}),
      productType: Yup.object().shape({
        value: Yup.string().required("Виберіть продукцію")
      }),
      clarification: Yup.string().max(70, "Максимальна кількість 70 символів"),
      wood: Yup.array()
        .min(1, "Виберіть породу")
        .of(
          Yup.object().shape({
            value: Yup.string().required("Виберіть породу")
          })
        ),
      count: Yup.number()
        .transform((_, value) => {
          if (typeof value === "number") return value;
          return parseFloat(value.replace(/,/, "."));
        })
        .typeError("Значення має бути числовим")
        .positive("Значення має бути більше 0")
        .required("Введіть кількість"),
      unit: Yup.object().shape({
        value: Yup.string().required("Виберіть од. вимір.")
      }),
      uktzed: Yup.string()
        .test(
          "productType-valid",
          "Виберіть продукцію для УКТЗЕД",
          function () {
            const validationResult = validateProductType().isValidSync(
              this.parent.productType
            );
            return validationResult;
          }
        )
        .test("wood-valid", "Виберіть породу для УКТЗЕД", function () {
          const validationResult = validateWood().isValidSync(this.parent.wood);
          return validationResult;
        })
        .test(
          "uktzd-valid",
          "Код УКТЗЕД відсутній у довіднику",
          function (val) {
            const isOptionsList = this.parent.uktzedList?.length > 1;

            if (isOptionsList) return true;
            return val?.length > 0;
          }
        )
        .test("uktzd-valid", "Виберіть код УКТЗЕД", function (val) {
          const isOptionsList = this.parent.uktzedList?.length > 1;

          if (isOptionsList) return isOptionsList && val?.length > 0;
          return true;
        }),
      ...(checkPrice
        ? {
            price: Yup.number()
              .transform((_, value) => {
                if (typeof value === "number") return value;
                return parseFloat(value.replace(/,/, "."));
              })
              .typeError("Значення має бути числовим")
              .positive("Значення має бути більше 0")
              .required("Введіть ціну")
          }
        : {})
    })
  );
};

export const aggregationFnSumFloats = (accessor, val) => {
  return formatNumber(
    sum(val.map((item) => parseFloat(item.original[accessor]))),
    accessor === "amount" || accessor === "price" ? 2 : 5,
    accessor === "amount"
  );
};

export const aggregationFnUniq = (accessor, val) => {
  return uniq(val.map((item) => item.original[accessor])).join(", ");
};
interface GenerateColumnDataParams {
  formik?: any;
  docType?: string;
  isRead?: boolean;
  isPrevious?: boolean;
  isWaste?: boolean;
  isDisabledSelect?: boolean;
}

export const generateColumnData = ({
  formik,
  docType,
  isRead,
  isPrevious,
  isWaste,
  isDisabledSelect
}: GenerateColumnDataParams) => {
  const columnHelper = createColumnHelper<TableData>();
  const columnsData = [
    ...(!isPrevious
      ? [
          columnHelper.accessor("id", {
            header: ({ table }) => {
              const fieldNames = ["available"];
              const { isAllAvailableRowsSelected, toggleAvailableRows } =
                getSelectHandlerByField({
                  data: table.getPreSelectedRowModel().rows,
                  fieldNames
                });

              return (
                <div>
                  <Checkbox
                    readOnly
                    checked={isAllAvailableRowsSelected}
                    disabled={isDisabledSelect}
                    onChange={
                      isDisabledSelect
                        ? null
                        : (e) => {
                            const isSelected = e.target.checked;
                            toggleAvailableRows();
                            const updatedProducts = formik.values[
                              isWaste ? "wasteProducts" : "products"
                            ].map((item) => {
                              const decommissionedVal = isSelected
                                ? item.decommissioned > 0
                                  ? item.decommissioned
                                  : item.available
                                : 0;
                              const amountVal = decommissionedVal * item.price;
                              return {
                                ...item,
                                decommissioned: decommissionedVal,
                                amount:
                                  amountVal > 0
                                    ? formatNumber(amountVal, 2, true)
                                    : 0
                              };
                            });
                            formik.setFieldValue(
                              isWaste ? "wasteProducts" : "products",
                              updatedProducts
                            );
                          }
                    }
                  />
                </div>
              );
            },
            cell: ({ row }) => {
              const data = row.original;
              const isDisabled = data.available <= 0;
              const fieldNames = ["available"];

              if (row.getIsGrouped()) {
                const { isAllAvailableRowsSelected, toggleAvailableRows } =
                  getSelectHandlerByField({
                    data: row.getLeafRows(),
                    fieldNames
                  });
                return (
                  <div>
                    <Checkbox
                      readOnly
                      checked={isAllAvailableRowsSelected}
                      disabled={isDisabledSelect}
                      onChange={
                        isDisabledSelect
                          ? null
                          : (e) => {
                              const isSelected = e.target.checked;
                              toggleAvailableRows();

                              const updatedProducts = formik.values[
                                isWaste ? "wasteProducts" : "products"
                              ].map((item) => {
                                const currentRow = row
                                  .getLeafRows()
                                  .find(
                                    (row) => row.original.id === item.id
                                  )?.original;
                                if (!currentRow) return item;

                                const decommissionedVal = isSelected
                                  ? item.available
                                  : 0;
                                const amountVal =
                                  decommissionedVal * item.price;
                                return {
                                  ...item,
                                  decommissioned: decommissionedVal,
                                  amount:
                                    amountVal > 0
                                      ? formatNumber(amountVal, 2, true)
                                      : 0
                                };
                              });
                              formik.setFieldValue(
                                isWaste ? "wasteProducts" : "products",
                                updatedProducts
                              );
                            }
                      }
                    />
                  </div>
                );
              }

              return (
                <div>
                  <Checkbox
                    checked={row.getIsSelected()}
                    readOnly
                    disabled={isDisabled || isDisabledSelect}
                    onChange={
                      isDisabled || isDisabledSelect
                        ? null
                        : (e) => {
                            const isSelected = e.target.checked;
                            row.getToggleSelectedHandler()(e);

                            if (!row.getIsGrouped()) {
                              const decommissionedVal = isSelected
                                ? data.available
                                : 0;
                              formik.setFieldValue(
                                `${isWaste ? "wasteProducts" : "products"}.${
                                  row.id
                                }.decommissioned`,
                                decommissionedVal
                              );
                              const amountVal = decommissionedVal * data.price;
                              formik.setFieldValue(
                                `${isWaste ? "wasteProducts" : "products"}.${
                                  row.id
                                }.amount`,
                                amountVal > 0
                                  ? formatNumber(amountVal, 2, true)
                                  : 0,
                              );
                            }
                          }
                    }
                  />
                </div>
              );
            }
          })
        ]
      : []),
    columnHelper.accessor("production", {
      cell: (info: any) => <p>{info.getValue()}</p>,
      header: () => "Продукція",
      aggregationFn: "unique"
    }),

    columnHelper.accessor("clarification", {
      cell: (info: any) => <p>{info.getValue()}</p>,
      header: () => "Уточ.",
      aggregationFn: aggregationFnUniq
    }),
    columnHelper.accessor("breed", {
      cell: (info: any) => info.getValue(),
      header: () => "Порода",
      aggregationFn: aggregationFnUniq
    }),
    columnHelper.accessor("grade", {
      cell: (info: any) => info.getValue(),
      header: () => "Сорт",
      aggregationFn: aggregationFnUniq
    }),
    columnHelper.accessor("diameter", {
      cell: (info: any) => info.getValue(),
      header: () => "Діаметр",
      aggregationFn: aggregationFnUniq
    }),
    columnHelper.accessor("diametersGroup", {
      cell: (info: any) => info.getValue(),
      header: () => "Група діаметрів",
      aggregationFn: aggregationFnUniq
    }),
    columnHelper.accessor("thickness", {
      cell: (info: any) => info.getValue(),
      header: () => "Товщина",
      aggregationFn: aggregationFnUniq
    }),
    columnHelper.accessor("width", {
      cell: (info: any) => info.getValue(),
      header: () => "Ширина",
      aggregationFn: aggregationFnUniq
    }),
    columnHelper.accessor("length", {
      cell: (info: any) => info.getValue(),
      header: () => "Довжина",
      aggregationFn: aggregationFnUniq
    }),
    ...(!isPrevious
      ? [
          columnHelper.accessor("original", {
            cell: (info: any) => info.getValue(),
            header: () => "Оригінал",
            aggregationFn: aggregationFnSumFloats
          })
        ]
      : []),
    ...(!isRead && !isPrevious
      ? [
          columnHelper.accessor("available", {
            cell: (info: any) => info.getValue(),
            header: () => "Доступно",
            aggregationFn: aggregationFnSumFloats
          })
        ]
      : []),

    ...(!isPrevious
      ? [
          columnHelper.accessor("decommissioned", {
            header: isRead ? "Списано" : "Списується",
            meta: {
              fieldType: "INPUT",
              fieldValueType: "number",
              useDefaultColumn: true
            },
            aggregationFn: (accessor, val) => {
              const itemsInGroupe = val.filter(
                (item) => item.original[isRead ? accessor : "available"] > 0
              );
              const isSelectedGroupe = !!!itemsInGroupe.find(
                (item) => item.getIsSelected() === false
              );

              let groupeSum = 0;
              let groupeMaxValue = 0;

              itemsInGroupe.forEach((item) => {
                const decommissioned = parseFloat(
                  item.original[accessor].toString()
                );
                const available = parseFloat(
                  item.original["available"].toString()
                );

                const decommissionedChecked =
                  decommissioned > available && !isRead
                    ? available
                    : decommissioned;

                groupeSum = groupeSum + decommissionedChecked;
                groupeMaxValue = groupeMaxValue + available;
              });

              if (isRead) return groupeSum > 0 ? groupeSum : "";

              return {
                isSelectedGroupe,
                itemsInGroupe,
                groupeSum: formatNumber(groupeSum, 5),
                groupeMaxValue: formatNumber(groupeMaxValue, 5)
              };
            }
          })
        ]
      : []),
    ...(!isPrevious
      ? [
          columnHelper.accessor("unit", {
            cell: (info: any) => info.getValue(),
            header: () => "Од. вим.",
            aggregationFn: aggregationFnUniq
          })
        ]
      : []),
    ...(isPrevious
      ? [
          columnHelper.accessor("quantity", {
            cell: (info: any) => info.getValue(),
            header: () => "Кількість",
            aggregationFn: aggregationFnUniq
          })
        ]
      : []),
    columnHelper.accessor("uktzed", {
      cell: (info: any) => info.getValue(),
      header: () => "УКТЗЕД",
      aggregationFn: aggregationFnUniq
    }),
    ...(!isPrevious
      ? [
          columnHelper.accessor("accounting", {
            cell: (info: any) => info.getValue(),
            header: () => "Облік",
            aggregationFn: undefined
          })
        ]
      : []),
    ...(!isPrevious
      ? [
          columnHelper.accessor("multiGroupe", {
            cell: (info: any) => <p>{info.getValue()}</p>,
            header: () => "мульті",
            meta: {
              groupByThisColumn: true
            },
            aggregationFn: aggregationFnUniq,
            enableGrouping: true
          })
        ]
      : [])
  ];
  if (!isPrevious) {
    if (docType === "transfer")
      columnsData.push(
        columnHelper.accessor("woodCard", {
          cell: (info: any) => {
            const woodCard = info.getValue();
            if (woodCard) {
              const { series, number, issueDate } = info.getValue() ?? "";
              return (
                <p>
                  {series} {number} {formatDate(issueDate).date}
                </p>
              );
            } else return <p></p>;
          },
          header: () => "Лісорубний квиток",
          aggregationFn: aggregationFnUniq
        })
      );
    else {
      const index = columnsData.findIndex(
        (item) => item["accessorKey"] === "uktzed"
      );
      columnsData.splice(
        index,
        0,
        ...[
          columnHelper.accessor("price", {
            cell: (info: any) => info.getValue(),
            header: () => "Ціна",
            aggregationFn: undefined
          }),
          ...(!isRead
            ? [
                columnHelper.accessor("amount", {
                  cell: (info: any) => info.getValue(),
                  header: () => "Вартість",
                  aggregationFn: undefined
                })
              ]
            : [])
        ]
      );
    }
  }
  return columnsData;
};
