import cn from "classnames";
import { Alert, BlankPage } from "gov-ua-ui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { docTypes } from "constant";
import { applicationOfficialInQueueStatuses } from "dataset";
import features from "features";
import {
  formatDate,
  getDocumentViewType,
  getRole,
  scrollToResults
} from "helpers";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";
import { IProgressedApplicationsState } from "scenes/official/OfficialInProgressPage/ducks";

import ApplicationsSearchForm from "components/ApplicationsSearchForm/ApplicationsSearchForm";
import CircleProgressBar from "components/CircleProgressBar/CircleProgressBar";
import Preloader from "components/Preloader/Preloader";
import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import EDocButton from "components/buttons/EDocButton/EDocButton";
import PersonalOfficeOfficialLayout from "components/layouts/PersonalOfficeOfficialLayout/PersonalOfficeOfficialLayout";
import QueueCertificatesResults from "./components/QueueCertificatesResults/QueueCertificatesResults";

import styles from "./official-in-progress-page.module.scss";

const OfficialInProgressPage = () => {
  const dispatch = useDispatch();

  const resultsRef = useRef(null);

  const {
    application,
    fetchingInProcessApplicationsLoading,
    applications,
    fetchingInQueueApplicationsLoading
  } = useSelector<IRootState, IProgressedApplicationsState>(
    (state) => state.officialProgressed
  );

  const {
    fetchingInterregionalOfficeExternalListLoading,
    interregionalOfficeExternalList
  } = useSelector<IRootState, IDatasetState>((state) => state.dataset);

  const [userRole] = useState(getRole());
  const [parsedFields, setParsedFields] = useState({});

  useEffect(() => {
    dispatch(features.officialProgressed.actions.fetchProgressedRequest());
  }, [dispatch]);

  useEffect(() => {
    if (userRole) {
      if (userRole === "headOfficial" || userRole === "dalruOfficial")
        dispatch(features.officialProgressed.actions.searchInQueueRequest());
      if (userRole !== "headOfficial")
        dispatch(
          features.dataset.actions.fetchInterregionalOfficeExternalListRequest()
        );
    }
  }, [dispatch, userRole]);

  const statuses = useMemo(() => {
    return Object.keys(applicationOfficialInQueueStatuses).map((key) => {
      return {
        label: applicationOfficialInQueueStatuses[key].title,
        value: key
      };
    });
  }, []);

  const applicationTypes = useMemo(() => {
    return [
      {
        label: "Про анулювання",
        value: "annulment"
      },
      {
        label: "На видачу сертифіката",
        value: "certificate"
      }
    ];
  }, []);

  const applicationCertificateTitle = useMemo(() => {
    if (application?.type !== "annulment")
      return `Заява на отримання сертифіката №${application?.number}`;
    else {
      return `Заява №${application?.number} на анулювання сертифіката №${application?.certificateNumber}`;
    }
  }, [application]);

  const onFormSubmit = useCallback(
    (fields: any) => {
      dispatch(
        features.officialProgressed.actions.searchInQueueRequest({
          fields,
          onSuccess: () => {
            setParsedFields(fields);
            scrollToResults(resultsRef.current);
          }
        })
      );
    },
    [dispatch]
  );

  const onDocumentView = useCallback(
    (e, file) => {
      e.preventDefault();

      dispatch(
        features.modal.actions.showModal({
          modalType: getDocumentViewType(file.name),
          modalProps: { url: file.filePath }
        })
      );
    },
    [dispatch]
  );

  const onPageClick = useCallback(
    (event) => {
      const newPage = event.selected + 1;

      dispatch(
        features.officialProgressed.actions.searchInQueueRequest({
          fields: { ...parsedFields, page: newPage },
          onSuccess: scrollToResults(resultsRef.current)
        })
      );
    },
    [dispatch, parsedFields]
  );

  return (
    <PersonalOfficeOfficialLayout>
      <div className={cn(styles["personal-cabinet-page"])}>
        {(userRole === "official" || userRole === "headOfficial") && (
          <>
            <h2 className={styles["personal-cabinet-page__title"]}>
              В процесі
            </h2>
            {fetchingInProcessApplicationsLoading ? (
              <Preloader />
            ) : (
              <>
                {application ? (
                  <Link
                    to={`/official/applications/${application.uuid}`}
                    className={styles["container-border"]}
                  >
                    <div className={styles["information-block"]}>
                      <div
                        className={styles["information-block__order-number"]}
                      >
                        {applicationCertificateTitle}
                      </div>
                      <div className={styles["main-information-wrapper"]}>
                        <div className={styles["main-information"]}>
                          <div className={styles["main-information__row"]}>
                            {application.type === "annulment" && (
                              <div
                                className={
                                  styles["main-information__data-wrapper"]
                                }
                              >
                                <h4
                                  className={styles["main-information__title"]}
                                >
                                  Експортер
                                </h4>
                                <h4
                                  className={styles["main-information__value"]}
                                >
                                  {application.exporterOrganizationName}
                                </h4>
                              </div>
                            )}
                            <div
                              className={
                                styles["main-information__data-wrapper"]
                              }
                            >
                              <h4 className={styles["main-information__title"]}>
                                Покупець
                              </h4>
                              <div
                                className={styles["main-information__value"]}
                              >
                                {application.buyer}
                              </div>
                            </div>
                            {application.type !== "annulment" && (
                              <div
                                className={
                                  styles["main-information__data-wrapper"]
                                }
                              >
                                <h4
                                  className={styles["main-information__title"]}
                                >
                                  Вантажоодержувач
                                </h4>
                                <h4
                                  className={styles["main-information__value"]}
                                >
                                  {application.consignee}
                                </h4>
                              </div>
                            )}
                            <div
                              className={
                                styles["main-information__data-wrapper"]
                              }
                            >
                              <h4 className={styles["main-information__title"]}>
                                {application.type !== "annulment"
                                  ? "Дата заявки"
                                  : "Дата заявки на анулювання"}
                              </h4>
                              <h4 className={styles["main-information__value"]}>
                                {formatDate(application.submissionDate).date}
                              </h4>
                            </div>
                            {application.type !== "annulment" && (
                              <>
                                <div
                                  className={
                                    styles["main-information__data-wrapper"]
                                  }
                                >
                                  <h4
                                    className={
                                      styles["main-information__title"]
                                    }
                                  >
                                    Експортер
                                  </h4>
                                  <h4
                                    className={
                                      styles["main-information__value"]
                                    }
                                  >
                                    {application.exporterOrganizationName}
                                  </h4>
                                </div>
                                <div
                                  className={
                                    styles["main-information__data-wrapper"]
                                  }
                                >
                                  <h4
                                    className={
                                      styles["main-information__title"]
                                    }
                                  >
                                    Перевірено
                                  </h4>
                                  <h4
                                    className={
                                      styles["main-information__value"]
                                    }
                                  >
                                    <CircleProgressBar
                                      progress={application.checkedPercent}
                                    />
                                  </h4>
                                </div>
                                <div
                                  className={
                                    styles["main-information__data-wrapper"]
                                  }
                                >
                                  <h4
                                    className={
                                      styles["main-information__title"]
                                    }
                                  >
                                    Вихід
                                  </h4>
                                  <div className={styles["rate-container"]}>
                                    <h4
                                      className={styles["rate-container__rate"]}
                                    >
                                      Відсоток виходу продукції{" "}
                                      {application?.productionOutput !== null
                                        ? application?.productionOutput + "%"
                                        : "-"}
                                    </h4>
                                    <h4
                                      className={
                                        styles["rate-container__costs"]
                                      }
                                    >
                                      Нормативи витрат{" "}
                                      {application?.costStandards ?? "-"}
                                    </h4>
                                  </div>
                                </div>
                              </>
                            )}

                            {application?.remarksNumber != null && (
                              <div
                                className={
                                  styles["main-information__data-wrapper"]
                                }
                              >
                                <h4
                                  className={styles["main-information__title"]}
                                >
                                  Зроблено зауважень
                                </h4>
                                <h4
                                  className={styles["main-information__remark"]}
                                >
                                  {application.remarksNumber}
                                </h4>
                              </div>
                            )}
                            <div
                              className={
                                styles["main-information__data-wrapper"]
                              }
                            >
                              <h4 className={styles["main-information__title"]}>
                                Заява
                              </h4>
                              <h4 className={styles["main-information__value"]}>
                                {application?.type !== "annulment" ? (
                                  <EDocButton
                                    type={docTypes.APPLICATION}
                                    title={application?.eApplication.title}
                                    contentTitle={
                                      application?.eApplication.name
                                    }
                                    contentSubtitle={
                                      application?.eApplication.serialNumber
                                    }
                                    contentText={
                                      formatDate(application?.eApplication.date)
                                        .date
                                    }
                                    onClick={(e) =>
                                      onDocumentView(
                                        e,
                                        application?.eApplication
                                      )
                                    }
                                  />
                                ) : (
                                  <EDocButton
                                    type={docTypes.ANNUL_ALT}
                                    title={
                                      application?.eAnnulmentApplication.title
                                    }
                                    contentTitle={
                                      application?.eAnnulmentApplication.name
                                    }
                                    contentSubtitle={
                                      application?.eAnnulmentApplication
                                        .serialNumber
                                    }
                                    contentText={
                                      formatDate(
                                        application?.eAnnulmentApplication.date
                                      ).date
                                    }
                                    onClick={(e) =>
                                      onDocumentView(
                                        e,
                                        application?.eAnnulmentApplication
                                      )
                                    }
                                  />
                                )}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <BlankPage title="Немає жодної заявки у процесі розгляду" />
                )}
              </>
            )}
          </>
        )}

        {userRole !== "official" && (
          <div className={styles["queue"]}>
            <PreloaderWrapper
              loading={
                fetchingInQueueApplicationsLoading ||
                fetchingInterregionalOfficeExternalListLoading
              }
            >
              <h2 className={styles["personal-cabinet-page__title"]}>
                Черга заявок
              </h2>

              <ApplicationsSearchForm
                onFormSubmit={onFormSubmit}
                statuses={statuses}
                applicationTypes={applicationTypes}
                interregionalOfficeExternalList={
                  userRole !== "headOfficial"
                    ? interregionalOfficeExternalList
                    : undefined
                }
              >
                <div ref={resultsRef}>
                  {applications ? (
                    <>
                      <QueueCertificatesResults
                        applications={applications}
                        onPageClick={onPageClick}
                        isReadOnly={userRole === "dalruOfficial"}
                        currentProcessingUuid={application?.uuid}
                      />
                    </>
                  ) : (
                    <Alert type="warning" withIcon>
                      В черзі заяв немає
                    </Alert>
                  )}
                </div>
              </ApplicationsSearchForm>
            </PreloaderWrapper>
          </div>
        )}
      </div>
    </PersonalOfficeOfficialLayout>
  );
};

export default OfficialInProgressPage;
